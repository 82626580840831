import React, { useState, useEffect } from 'react';
import { Search, X } from 'lucide-react';
import useDebounce from '../../hooks/useDebounce';

const CompanySearchBar = ({ onSelectCompany }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const debouncedSearch = useDebounce(searchTerm, 300);

  useEffect(() => {
    const searchCompanies = async () => {
      if (debouncedSearch.length < 3) {
        setResults([]);
        return;
      }

      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/search?query=${encodeURIComponent(debouncedSearch)}`);
        if (!response.ok) throw new Error('Search failed');
        const data = await response.json();
        setResults(data);
      } catch (error) {
        console.error('Search error:', error);
        setResults([]);
      } finally {
        setLoading(false);
      }
    };

    searchCompanies();
    setSelectedIndex(-1); // Reset selection when search term changes
  }, [debouncedSearch]);

  const handleClear = () => {
    setSearchTerm('');
    setResults([]);
    setSelectedIndex(-1);
  };

  const handleSelect = (company) => {
    onSelectCompany(company);
    handleClear();
  };

  const handleKeyDown = (e) => {
    if (results.length === 0) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex(prev => 
          prev < results.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex(prev => prev > 0 ? prev - 1 : -1);
        break;
      case 'Enter':
        e.preventDefault();
        if (selectedIndex >= 0) {
          handleSelect(results[selectedIndex]);
        }
        break;
      case 'Escape':
        handleClear();
        break;
      default:
        break;
    }
  };

  return (
    <div className="relative w-full max-w-2xl">
      <div className="relative">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Add companies..."
          className="w-full pl-10 pr-10 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white"
        />
        <div className="absolute left-3 top-1/2 -translate-y-1/2">
          {loading ? (
            <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-blue-500" />
          ) : (
            <Search className="w-5 h-5 text-gray-400" />
          )}
        </div>
        {searchTerm && (
          <button
            type="button"
            onClick={handleClear}
            className="absolute right-3 top-1/2 -translate-y-1/2 p-1 hover:bg-gray-100 rounded-full"
          >
            <X className="w-4 h-4 text-gray-400" />
          </button>
        )}
      </div>

      {/* Search Results Dropdown */}
      {results.length > 0 && (
        <div className="absolute w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg z-50 max-h-60 overflow-y-auto">
          {results.map((company, index) => (
            <button
              key={company.ID}
              onClick={() => handleSelect(company)}
              onMouseEnter={() => setSelectedIndex(index)}
              className={`w-full px-4 py-2 text-left hover:bg-gray-50 flex items-center space-x-2 ${
                index === selectedIndex ? 'bg-gray-50' : ''
              }`}
            >
              {company.flag_url && (
                <img src={company.flag_url} alt="" className="w-4 h-4" />
              )}
              <div>
                <div className="text-sm font-medium text-gray-900">{company.longNameEn}</div>
                <div className="text-xs text-gray-500">{company.stockCodes}</div>
              </div>
            </button>
          ))}
        </div>
      )}

      {/* No Results Message */}
      {searchTerm.length >= 3 && !loading && results.length === 0 && (
        <div className="absolute w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg z-50 p-4 text-center text-gray-500">
          No companies found
        </div>
      )}
    </div>
  );
};

export default CompanySearchBar;