import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import OnboardingWizard from '../components/onboarding/OnboardingWizard';
import OnboardingResults from '../components/onboarding/OnboardingResults';
import { useAuth } from '../contexts/AuthContext';
import useWatchlists from '../hooks/useWatchlists';

const OnboardingView = () => {
    const [preferences, setPreferences] = useState(null);
    const { getIdToken, updateOnboardingStatus } = useAuth();
    const navigate = useNavigate();
    const { createWatchlist } = useWatchlists();

    const handleOnboardingComplete = async (userPreferences) => {
        try {
            // Extract fields from the request body
            const { industries, exchanges, role } = userPreferences;
            
            // Save preferences
            setPreferences(userPreferences);
            
            // Send preferences to backend without updating onboarding status yet
            await fetch(`${process.env.REACT_APP_API_URL}/users/onboarding`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await getIdToken()}`
                },
                body: JSON.stringify({
                    industries,
                    exchanges,
                    role
                })
            });
        } catch (error) {
            console.error('Error saving onboarding preferences:', error);
            toast.error('Failed to save preferences. Please try again.');
        }
    };

    const handleCreateWatchlists = async (watchlists) => {
        try {
            // Create watchlists sequentially for better error handling
            for (const watchlist of watchlists) {
                await createWatchlist({
                    name: watchlist.name,
                    description: watchlist.description,
                    stocks: watchlist.stocks
                });
            }

            // Only update onboarding status and navigate after watchlists are created
            await updateOnboardingStatus();
            
            // Show success message
            toast.success('Watchlists created successfully!');
            
            // Navigate to main app after watchlists are created
            navigate('/', { replace: true });
        } catch (error) {
            console.error('Error creating watchlists:', error);
            toast.error('Failed to create watchlists. Please try again.');
            throw error; // Re-throw to trigger loading state cleanup in OnboardingResults
        }
    };

    const handleSkip = async () => {
        try {
            // Update onboarding status and navigate only when user explicitly skips
            await updateOnboardingStatus();
            
            // Navigate to main app
            navigate('/', { replace: true });
        } catch (error) {
            console.error('Error skipping watchlist creation:', error);
            toast.error('Failed to complete onboarding. Please try again.');
        }
    };

    return (
        <div className="min-h-screen bg-gray-50 py-8">
            {!preferences ? (
                <OnboardingWizard onComplete={handleOnboardingComplete} />
            ) : (
                <OnboardingResults 
                    preferences={preferences}
                    onCreateWatchlists={handleCreateWatchlists}
                    onSkip={handleSkip}
                />
            )}
        </div>
    );
};

export default OnboardingView;