import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  ChevronDown, 
  ChevronRight,
  Plus,
  Search,
  Settings,
  X
} from 'lucide-react';
import useWatchlists from '../hooks/useWatchlists';
import { useUnread } from '../contexts/UnreadContext';
import { useTrending } from '../hooks/useTrending';
import { useKeywordAlerts } from '../hooks/useKeywordAlerts';
import { useAuth } from '../contexts/AuthContext';

const UnreadBadge = ({ count }) => {
  if (!count || count <= 0) return null;
  
  return (
    <span className="ml-2 px-1.5 py-0.5 text-xs font-medium bg-red-500 text-white rounded-full min-w-[1.25rem] text-center">
      {count > 99 ? '99+' : count}
    </span>
  );
};

const FeedCategory = ({ title, items = [], isOpen, onToggle, loading = false, onItemClick }) => {
  const { watchlistUnreadCounts } = useUnread();
  
  const totalUnread = items.reduce((sum, item) => {
    if (item.id.startsWith('watchlist-')) {
      const watchlistId = item.id.replace('watchlist-', '');
      return sum + (watchlistUnreadCounts[watchlistId] || 0);
    }
    return sum + (item.unread_count || 0);
  }, 0);

  return (
    <div className="mb-2">
      <button
        onClick={onToggle}
        className="w-full flex items-center px-3 py-2 hover:bg-light-bg-secondary rounded-lg group transition-colors duration-200"
      >
        {isOpen ? (
          <ChevronDown className="w-4 h-4 text-light-text-tertiary transition-transform duration-200" />
        ) : (
          <ChevronRight className="w-4 h-4 text-light-text-tertiary transition-transform duration-200" />
        )}
        <span className="ml-2 text-sm font-medium text-light-text">{title}</span>
        <span className="ml-auto text-xs text-light-text-tertiary">
          {loading ? '...' : items.length}
        </span>
        {totalUnread > 0 && <UnreadBadge count={totalUnread} />}
      </button>
      {isOpen && (
        <div className="ml-4 mt-1 space-y-1 animate-fade-in">
          {loading ? (
            <div className="flex items-center px-3 py-2 text-sm text-light-text-secondary">
              Loading...
            </div>
          ) : items.length === 0 ? (
            <div className="flex items-center px-3 py-2 text-sm text-light-text-secondary">
              No items to display
            </div>
          ) : (
            items.map((item) => {
              let unreadCount = item.unread_count;
              if (item.id.startsWith('watchlist-')) {
                const watchlistId = item.id.replace('watchlist-', '');
                unreadCount = watchlistUnreadCounts[watchlistId] || 0;
              }
              
              return (
                <Link
                  key={item.id}
                  to={item.to || `/feeds/${item.id.replace('watchlist-', '')}`}
                  onClick={(e) => {
                    if (item.onClick) {
                      item.onClick(e);
                    }
                    if (onItemClick) {
                      onItemClick(item);
                    }
                  }}
                  className="flex items-center justify-between px-3 py-2 text-sm text-light-text-secondary hover:bg-light-bg-secondary 
                          rounded-lg transition-colors duration-200 group"
                >
                  <div className="flex items-center min-w-0">
                    {item.icon && <item.icon className="w-4 h-4 mr-2 text-light-text-tertiary group-hover:text-secondary-DEFAULT transition-colors duration-200" />}
                    <span className="truncate group-hover:text-secondary-DEFAULT transition-colors duration-200">
                      {item.name}
                    </span>
                  </div>
                  <div className="flex items-center ml-2">
                    {item.customLabel && (
                      <span className="text-xs text-light-text-tertiary">
                        {item.customLabel}
                      </span>
                    )}
                    {unreadCount > 0 && <UnreadBadge count={unreadCount} />}
                  </div>
                </Link>
              );
            })
          )}
        </div>
      )}
    </div>
  );
};

const FeedsPanel = ({ isVisible, onClose }) => {
  const [expandedCategories, setExpandedCategories] = useState({
    keywords: true,
    watchlists: true,
    trending: true,
    trendingWatchlists: true
  });
  
  const { watchlists, lastUpdate } = useWatchlists();
  const { keywordAlerts, setKeywordAlerts } = useKeywordAlerts();
  const { trendingCompanies, trendingWatchlists, loading } = useTrending();
  const location = useLocation();
  const navigate = useNavigate();
  const { getIdToken } = useAuth();
  const { clearUnreadCount } = useUnread();

  useEffect(() => {
    if (watchlists.length > 0) {
      setExpandedCategories(prev => ({
        ...prev,
        watchlists: true
      }));
    }
  }, [lastUpdate, watchlists.length]);

  const toggleCategory = (category) => {
    setExpandedCategories(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };

  const handleKeywordClick = async (keyword, alertId) => {
    try {
      navigate('/', { state: { selectedKeywords: [keyword] } });
      
      const token = await getIdToken();
      await fetch(`${process.env.REACT_APP_API_URL}/keyword-alerts/${alertId}/mark-viewed`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      setKeywordAlerts(prevAlerts => 
        prevAlerts.map(alert => 
          alert.id === alertId 
            ? { ...alert, unread_count: 0 }
            : alert
        )
      );
  
      if (window.innerWidth < 768) {
        onClose();
      }
    } catch (error) {
      console.error('Error marking keyword as viewed:', error);
    }
  };

  const handleWatchlistClick = async (item) => {
    try {
      if (item.id.startsWith('watchlist-')) {
        const watchlistId = item.id.replace('watchlist-', '');
        const token = await getIdToken();
        
        // Make the API request to mark as viewed
        await fetch(`${process.env.REACT_APP_API_URL}/watchlists/${watchlistId}/mark-viewed`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        // Update the local unread count
        clearUnreadCount(watchlistId);

        // Close panel on mobile
        if (window.innerWidth < 768) {
          onClose();
        }
      }
    } catch (error) {
      console.error('Error marking watchlist as viewed:', error);
    }
  };

  if (!isVisible) return null;

  return (
    <div 
      className="bg-white border-r border-gray-200 h-screen fixed top-0 flex flex-col z-20 shadow-sm animate-slide-in"
      style={{ left: '72px', width: '256px' }}
    >
      {/* Header */}
      <div className="h-16 px-4 border-b border-gray-200 flex items-center justify-between bg-light-bg-secondary/50 backdrop-blur-sm">
        <h2 className="text-lg font-semibold text-light-text">Feeds</h2>
        <div className="flex items-center space-x-2">
          <button 
            onClick={() => {}} 
            className="p-1.5 hover:bg-light-bg-tertiary rounded-lg transition-colors duration-200"
            title="Add Feed"
          >
            <Plus className="w-4 h-4 text-light-text-secondary hover:text-secondary-DEFAULT transition-colors duration-200" />
          </button>
          <button 
            onClick={onClose}
            className="p-1.5 hover:bg-light-bg-tertiary rounded-lg transition-colors duration-200"
            title="Close Panel"
          >
            <X className="w-4 h-4 text-light-text-secondary hover:text-secondary-DEFAULT transition-colors duration-200" />
          </button>
        </div>
      </div>

      {/* Search */}
      <div className="p-4">
        <div className="flex items-center bg-light-bg-secondary rounded-lg px-3 py-2 focus-within:ring-2 focus-within:ring-secondary-DEFAULT/20 transition-all duration-200">
          <Search className="w-4 h-4 text-light-text-tertiary" />
          <input
            type="text"
            placeholder="Search feeds..."
            className="ml-2 bg-transparent w-full text-sm text-light-text placeholder:text-light-text-tertiary 
                     focus:outline-none"
          />
        </div>
      </div>

      {/* Feed Categories */}
      <div className="flex-1 overflow-y-auto p-4
        [scrollbar-width:thin]
        [scrollbar-color:#E5E7EB_transparent]
        [&::-webkit-scrollbar]:w-1.5
        [&::-webkit-scrollbar-track]:bg-transparent
        [&::-webkit-scrollbar-thumb]:bg-gray-200
        [&::-webkit-scrollbar-thumb]:rounded-full
        [&::-webkit-scrollbar-thumb]:border-4
        [&::-webkit-scrollbar-thumb]:border-solid
        [&::-webkit-scrollbar-thumb]:border-transparent
        hover:[&::-webkit-scrollbar-thumb]:bg-gray-300">
          
        {/* Watchlists */}
        <FeedCategory
          title="Watchlists"
          items={watchlists.map(w => ({ ...w, id: `watchlist-${w.id}` }))}
          isOpen={expandedCategories.watchlists}
          onToggle={() => toggleCategory('watchlists')}
          onItemClick={handleWatchlistClick}
        />

        {/* Keywords */}
        <FeedCategory
          title="Keywords"
          items={keywordAlerts.map(alert => ({
            id: `keyword-${alert.id}`,
            name: alert.keyword,
            unread_count: alert.unread_count,
            to: '/',
            onClick: (e) => {
              e.preventDefault();
              handleKeywordClick(alert.keyword, alert.id);
            }
          }))}
          isOpen={expandedCategories.keywords}
          onToggle={() => toggleCategory('keywords')}
        />

        {/* Trending Companies */}
        <FeedCategory
          title="Trending Companies"
          items={trendingCompanies.map(company => ({
            id: company.stockCode,
            name: company.name,
            to: `/company/${company.stockCode}`
          }))}
          isOpen={expandedCategories.trending}
          onToggle={() => toggleCategory('trending')}
          loading={loading}
        />

        {/* Trending Watchlists */}
        <FeedCategory
          title="Trending Watchlists"
          items={trendingWatchlists.map(watchlist => ({
            id: `watchlist-${watchlist.id}`,
            name: watchlist.name
          }))}
          isOpen={expandedCategories.trendingWatchlists}
          onToggle={() => toggleCategory('trendingWatchlists')}
          loading={loading}
        />
      </div>

      {/* Footer */}
      <div className="p-4 border-t border-gray-200 bg-light-bg-secondary/50">
        <Link
          to="/feeds/settings"
          className="flex items-center text-sm text-light-text-secondary hover:text-secondary-DEFAULT 
                   hover:bg-light-bg-tertiary rounded-lg px-3 py-2 transition-colors duration-200 group"
        >
          <Settings className="w-4 h-4 mr-2 group-hover:text-secondary-DEFAULT transition-colors duration-200" />
          Feed Settings
        </Link>
      </div>
    </div>
  );
};

export default FeedsPanel;