import React, { createContext, useContext, useState, useCallback } from 'react';

const UnreadContext = createContext();

export const UnreadProvider = ({ children }) => {
  const [watchlistUnreadCounts, setWatchlistUnreadCounts] = useState({});

  const updateUnreadCount = useCallback((watchlistId, count) => {
    setWatchlistUnreadCounts(prev => ({
      ...prev,
      [watchlistId]: count
    }));
  }, []);

  const clearUnreadCount = useCallback((watchlistId) => {
    setWatchlistUnreadCounts(prev => ({
      ...prev,
      [watchlistId]: 0
    }));
  }, []);

  return (
    <UnreadContext.Provider value={{ 
      watchlistUnreadCounts, 
      updateUnreadCount,
      clearUnreadCount 
    }}>
      {children}
    </UnreadContext.Provider>
  );
};

export const useUnread = () => {
  const context = useContext(UnreadContext);
  if (!context) {
    throw new Error('useUnread must be used within an UnreadProvider');
  }
  return context;
};