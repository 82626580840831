import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useUnread } from '../contexts/UnreadContext';
import FilingsTable from '../components/FilingsTable';
import NewsTable from '../components/NewsTable';
import FilterBar from '../components/filters/FilterBar';
import useWatchlists from '../hooks/useWatchlists';

const FeedsView = () => {
  const { watchlistId } = useParams();
  const { getIdToken } = useAuth();
  const { refreshWatchlists } = useWatchlists();
  const { clearUnreadCount } = useUnread();
  const [filings, setFilings] = useState([]);
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [offset, setOffset] = useState(0);
  const [watchlistInfo, setWatchlistInfo] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const ITEMS_PER_PAGE = 15;

  // Initialize filters from localStorage
  const [selectedExchanges, setSelectedExchanges] = useState(() => {
    const stored = localStorage.getItem('selectedExchanges');
    if (stored) {
      try {
        return JSON.parse(stored);
      } catch (e) {
        return [];
      }
    }
    return [];
  });

  const [selectedKeywords, setSelectedKeywords] = useState(() => {
    const stored = localStorage.getItem('selectedKeywords');
    if (stored) {
      try {
        return JSON.parse(stored);
      } catch (e) {
        return [];
      }
    }
    return [];
  });

  const [selectedFilingTypes, setSelectedFilingTypes] = useState(() => {
    const stored = localStorage.getItem('selectedFilingTypes');
    if (stored) {
      try {
        return JSON.parse(stored);
      } catch (e) {
        return [];
      }
    }
    return [];
  });
  
  // Initialize read items from localStorage
  const [readItems, setReadItems] = useState(() => {
    const stored = localStorage.getItem('readItems');
    if (stored) {
      try {
        return JSON.parse(stored);
      } catch (e) {
        return {};
      }
    }
    return {};
  });

  const [activeTab, setActiveTab] = useState(() => {
    const stored = localStorage.getItem('feedsActiveTab');
    return stored || 'filings';
  });

  // Mark watchlist as viewed when mounted or watchlist changes
  useEffect(() => {
    let isSubscribed = true;
    let timeoutId = null;
    let hasCleared = false;  // Flag to prevent multiple clearUnreadCount calls

    const markWatchlistAsViewed = async () => {
      if (!watchlistId || hasCleared) return;
      
      try {
        // Clear the unread count immediately for instant UI update
        clearUnreadCount(watchlistId);
        hasCleared = true;  // Set flag to prevent multiple clears
        
        // Delay the API call by 1 second to prevent too many requests
        timeoutId = setTimeout(async () => {
          const token = await getIdToken();
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/watchlists/${watchlistId}/mark-viewed`,
            {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${token}`
              }
            }
          );

          if (!isSubscribed) return;

          if (response.ok) {
            // Only refresh watchlists if the mark-viewed was successful
            refreshWatchlists();
          } else {
            // If API call fails, restore the unread count
            await refreshWatchlists();
          }
        }, 1000);
      } catch (err) {
        console.error('Error marking watchlist as viewed:', err);
        if (isSubscribed) {
          await refreshWatchlists(); // This will restore the correct count from the server
        }
      }
    };

    markWatchlistAsViewed();

    return () => {
      isSubscribed = false;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [watchlistId, getIdToken, clearUnreadCount, refreshWatchlists]);

  const fetchFilings = async (currentOffset) => {
    setLoading(true);
    setError(null);
    
    try {
      const token = await getIdToken();
      const params = new URLSearchParams({
        page: Math.floor(currentOffset / ITEMS_PER_PAGE) + 1,
        limit: ITEMS_PER_PAGE
      });

      // Add exchange filters
      if (selectedExchanges.length > 0) {
        selectedExchanges.forEach(exchange => {
          params.append('exchanges[]', exchange);
        });
      }

      // Add keyword filters
      if (selectedKeywords.length > 0) {
        selectedKeywords.forEach(keyword => {
          params.append('keywords[]', keyword);
        });
      }

      // Add filing type filters
      if (selectedFilingTypes.length > 0) {
        selectedFilingTypes.forEach(type => {
          params.append('filingTypes[]', type);
        });
      }
      
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/watchlists/${watchlistId}/filings?${params.toString()}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      
      if (!response.ok) {
        throw new Error('Failed to fetch filings');
      }
      
      const data = await response.json();
      
      if (!data.data || data.data.length < ITEMS_PER_PAGE) {
        setHasMore(false);
      }
      
      if (data.data?.length > 0) {
        setFilings(prevFilings => 
          currentOffset === 0 ? data.data : [...prevFilings, ...data.data]
        );
        setOffset(currentOffset + data.data.length);
      } else if (currentOffset === 0) {
        setFilings([]);
        setHasMore(false);
      }
      
      setWatchlistInfo(data.watchlist);
    } catch (err) {
      console.error('Error fetching watchlist filings:', err);
      setError(err.message);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  const fetchNews = async (currentOffset) => {
    setLoading(true);
    setError(null);
    
    try {
      const token = await getIdToken();
      const exchangeParams = selectedExchanges.length > 0 ? `&exchanges=${selectedExchanges.join(',')}` : '';
      
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/watchlists/${watchlistId}/news?page=${Math.floor(currentOffset / ITEMS_PER_PAGE) + 1}&limit=${ITEMS_PER_PAGE}${exchangeParams}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      
      if (!response.ok) {
        throw new Error('Failed to fetch news');
      }
      
      const data = await response.json();
      
      if (!data.data || data.data.length < ITEMS_PER_PAGE) {
        setHasMore(false);
      }
      
      if (data.data?.length > 0) {
        setNews(prevNews => 
          currentOffset === 0 ? data.data : [...prevNews, ...data.data]
        );
        setOffset(currentOffset + data.data.length);
      } else if (currentOffset === 0) {
        setNews([]);
        setHasMore(false);
      }
      
      setWatchlistInfo(data.watchlist);
    } catch (err) {
      console.error('Error fetching watchlist news:', err);
      setError(err.message);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  // Reset everything when component mounts or when filters change
  useEffect(() => {
    setFilings([]);
    setNews([]);
    setOffset(0);
    setHasMore(true);
    if (activeTab === 'filings') {
      fetchFilings(0);
    } else {
      fetchNews(0);
    }
  }, [watchlistId, selectedExchanges, selectedKeywords, selectedFilingTypes, activeTab]);

  // Load more callback for infinite scroll
  const loadMore = useCallback(() => {
    if (!loading && hasMore) {
      if (activeTab === 'filings') {
        fetchFilings(offset);
      } else {
        fetchNews(offset);
      }
    }
  }, [loading, offset, activeTab, hasMore]);

  // Add read status to items
  const filingsWithReadStatus = filings.map(filing => ({
    ...filing,
    isRead: readItems[`filing-${filing.bulletinIds}`] || false
  }));

  const newsWithReadStatus = news.map(story => ({
    ...story,
    isRead: readItems[`news-${story.id}`] || false
  }));

  const handleFilingClick = (filingId) => {
    const newReadItems = {
      ...readItems,
      [`filing-${filingId}`]: true
    };
    setReadItems(newReadItems);
    localStorage.setItem('readItems', JSON.stringify(newReadItems));
  };

  const handleNewsClick = (newsId) => {
    const newReadItems = {
      ...readItems,
      [`news-${newsId}`]: true
    };
    setReadItems(newReadItems);
    localStorage.setItem('readItems', JSON.stringify(newReadItems));
  };

  const handleExchangeChange = (exchanges) => {
    setSelectedExchanges(exchanges);
    localStorage.setItem('selectedExchanges', JSON.stringify(exchanges));
  };

  const handleKeywordChange = (keywords) => {
    setSelectedKeywords(keywords);
    localStorage.setItem('selectedKeywords', JSON.stringify(keywords));
  };

  const handleFilingTypeChange = (types) => {
    setSelectedFilingTypes(types);
    localStorage.setItem('selectedFilingTypes', JSON.stringify(types));
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    localStorage.setItem('feedsActiveTab', tab);
  };

  return (
    <div>
      {/* Header */}
      <div className="px-4 py-4">
        {loading && filings.length === 0 && news.length === 0 ? (
          <span className="text-gray-500">Loading...</span>
        ) : watchlistInfo ? (
          <>
            <h1 className="text-2xl font-medium text-gray-900">
              {watchlistInfo.name}
            </h1>
            <p className="text-gray-600 mt-1">
              Tracking filings for {watchlistInfo.stockCodesCount} companies
            </p>
          </>
        ) : null}
      </div>

      {/* Tab Navigation */}
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8 px-4" aria-label="Feeds tabs">
          <button
            onClick={() => handleTabChange('filings')}
            className={`
              border-b-2 py-4 px-1 text-sm font-medium ${
                activeTab === 'filings'
                  ? 'border-secondary-DEFAULT text-secondary-DEFAULT'
                  : 'border-transparent text-light-text-secondary hover:border-gray-300 hover:text-light-text-DEFAULT'
              }
            `}
          >
            Filings
          </button>
          <button
            onClick={() => handleTabChange('news')}
            className={`
              border-b-2 py-4 px-1 text-sm font-medium ${
                activeTab === 'news'
                  ? 'border-secondary-DEFAULT text-secondary-DEFAULT'
                  : 'border-transparent text-light-text-secondary hover:border-gray-300 hover:text-light-text-DEFAULT'
              }
            `}
          >
            News
          </button>
        </nav>
      </div>

      {/* Filters */}
      <div className="py-4">
        <FilterBar 
          selectedExchanges={selectedExchanges}
          onExchangeChange={handleExchangeChange}
          selectedKeywords={selectedKeywords}
          onKeywordChange={handleKeywordChange}
          selectedFilingTypes={selectedFilingTypes}
          onFilingTypeChange={handleFilingTypeChange}
        />
      </div>

      {/* Content */}
      {activeTab === 'filings' ? (
        <FilingsTable 
          filings={filingsWithReadStatus}
          loading={loading}
          error={error}
          onLoadMore={loadMore}
          showCompanyInfo={true}
          hasMore={hasMore}
          onFilingClick={handleFilingClick}
          selectedKeywords={selectedKeywords}
        />
      ) : (
        <NewsTable 
          news={newsWithReadStatus}
          loading={loading}
          error={error}
          onLoadMore={loadMore}
          showCompanyInfo={true}
          hasMore={hasMore}
          onNewsClick={handleNewsClick}
        />
      )}
    </div>
  );
};

export default FeedsView;