import { useState, useEffect, useCallback, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';

const useCompanyNews = (stockCode) => {
  const { user, getIdToken } = useAuth();
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const lastRequestTime = useRef(0);
  const MIN_REQUEST_INTERVAL = 1000;
  const limit = 15;

  const fetchCompanyNews = useCallback(async (requestOffset) => {
    if (!stockCode) {
      console.log('Fetch aborted: No stockCode');
      return;
    }
  
    // Only apply rate limiting for scroll-triggered loads
    const now = Date.now();
    if (requestOffset > limit && now - lastRequestTime.current < MIN_REQUEST_INTERVAL) {
      console.log('Fetch aborted: Too soon since last request');
      return;
    }
    lastRequestTime.current = now;
  
    setLoading(true);
    setError(null);
    
    try {
      const headers = {
        'Content-Type': 'application/json'
      };
      
      if (user) {
        try {
          const token = await getIdToken();
          if (token) {
            headers['Authorization'] = `Bearer ${token}`;
          }
        } catch (error) {
          console.warn('Failed to get auth token:', error);
        }
      }

      const params = new URLSearchParams({
        page: Math.floor(requestOffset / limit) + 1,
        limit: limit.toString()
      });

      const url = `${process.env.REACT_APP_API_URL}/news/company?stockCode=${encodeURIComponent(stockCode)}&${params.toString()}`;
      console.log('Fetching URL:', url);
  
      const response = await fetch(url, { headers });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      
      // Set hasMore based on the number of items received
      if (!data.data || data.data.length < limit) {
        setHasMore(false);
      }
      
      if (data.data?.length > 0) {
        setNews(prev => {
          const newState = requestOffset === 0 ? data.data : [...prev, ...data.data];
          console.log('Updating news:', {
            previousCount: prev.length,
            newCount: newState.length,
            wasReset: requestOffset === 0
          });
          return newState;
        });
        setOffset(requestOffset + limit);
      } else if (requestOffset === 0) {
        // If it's the first fetch and we got no data
        setNews([]);
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching company news:', error);
      setError(error.message);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  }, [stockCode, user, getIdToken]);

  // Handle initial load
  useEffect(() => {
    let mounted = true;
    
    if (stockCode) {
      setNews([]);
      setOffset(0);
      setError(null);
      setHasMore(true); // Reset hasMore when stockCode changes
      if (mounted) {
        fetchCompanyNews(0);
      }
    }

    return () => {
      mounted = false;
    };
  }, [stockCode, fetchCompanyNews]);

  // Handle load more
  const loadMore = useCallback(() => {
    if (!loading && hasMore) {
      fetchCompanyNews(offset);
    }
  }, [fetchCompanyNews, offset, loading, hasMore]);

  return {
    news,
    loading,
    error,
    loadMore,
    hasMore
  };
};

export default useCompanyNews;