import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';

const useLatestNews = (selectedExchanges = []) => {
  const { user, getIdToken } = useAuth();
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const limit = 15;

  const exchangesKey = selectedExchanges.join(',');

  const fetchNews = useCallback(async (pageNumber) => {
    console.log('fetchNews called with:', { 
      pageNumber, 
      selectedExchanges,
      isAuthenticated: !!user,
      userEmail: user?.email
    });
    
    setLoading(true);
    setError(null);
    
    try {
      const headers = {
        'Content-Type': 'application/json'
      };
      
      if (user) {
        try {
          const token = await getIdToken();
          if (token) {
            headers['Authorization'] = `Bearer ${token}`;
          }
        } catch (error) {
          console.warn('Failed to get auth token:', error);
        }
      }

      const params = new URLSearchParams({
        page: pageNumber,
        limit: limit
      });
      
      if (selectedExchanges.length > 0) {
        selectedExchanges.forEach(exchange => {
          params.append('exchanges[]', exchange);
        });
      }

      const url = `${process.env.REACT_APP_API_URL}/news?${params.toString()}`;
      console.log('Fetching news from URL:', url);

      const response = await fetch(url, { headers });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Received news data:', {
        storiesCount: data.stories?.length,
        categories: data.categories,
        pagination: data.pagination
      });
      
      // Set hasMore based on the number of items received
      if (!data.stories || data.stories.length < limit) {
        setHasMore(false);
      }
      
      if (data.stories?.length > 0) {
        setNews(prev => {
          const newState = pageNumber === 1 ? data.stories : [...prev, ...data.stories];
          return newState;
        });
        setPage(pageNumber + 1);
      } else if (pageNumber === 1) {
        // If it's the first page and we got no data
        setNews([]);
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching news:', error);
      setError(error.message);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  }, [exchangesKey, getIdToken, user]); 

  useEffect(() => {
    console.log('exchangesKey changed to:', exchangesKey);
    setNews([]);
    setPage(1);
    setError(null);
    setHasMore(true); // Reset hasMore when filters change
    fetchNews(1);
  }, [fetchNews, exchangesKey]);

  const loadMore = useCallback(() => {
    if (!loading && hasMore) {
      fetchNews(page);
    }
  }, [fetchNews, page, loading, hasMore]);

  return {
    news,
    loading,
    error,
    loadMore,
    hasMore
  };
};

export default useLatestNews;