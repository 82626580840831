import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Plus } from 'lucide-react';
import useCompanyFilings from '../hooks/useCompanyFilings';
import useCompanyNews from '../hooks/useCompanyNews';
import FilingsTable from '../components/FilingsTable';
import NewsTable from '../components/NewsTable';
import FilterBar from '../components/filters/FilterBar';
import CompanyDataPanel from '../components/CompanyDataPanel';
import useWatchlists from '../hooks/useWatchlists';
import WatchlistSelectionPopup from '../components/WatchlistSelectionPopup';

const CompanyFilingsView = ({ isFeedsPanelVisible }) => {
  const { stockCode } = useParams();
  const { 
    watchlists,
    addStockToWatchlist,
    createWatchlist,
    updateStockNotifications,
    refreshWatchlists 
  } = useWatchlists();

  const [activeTab, setActiveTab] = useState(() => {
    const stored = localStorage.getItem('companyViewActiveTab');
    return stored || 'filings';
  });
  
  const [isDataPanelVisible, setIsDataPanelVisible] = useState(() => {
    const stored = localStorage.getItem('companyDataPanelVisible');
    return stored === null ? true : stored === 'true';
  });

  const [isWatchlistPopupOpen, setIsWatchlistPopupOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState(null);

  // Initialize filters from localStorage
  const [selectedKeywords, setSelectedKeywords] = useState(() => {
    const stored = localStorage.getItem('selectedKeywords');
    if (stored) {
      try {
        return JSON.parse(stored);
      } catch (e) {
        return [];
      }
    }
    return [];
  });

  const [selectedFilingTypes, setSelectedFilingTypes] = useState(() => {
    const stored = localStorage.getItem('selectedFilingTypes');
    if (stored) {
      try {
        return JSON.parse(stored);
      } catch (e) {
        return [];
      }
    }
    return [];
  });

  // Initialize read items from localStorage
  const [readItems, setReadItems] = useState(() => {
    const stored = localStorage.getItem('readItems');
    if (stored) {
      try {
        return JSON.parse(stored);
      } catch (e) {
        return {};
      }
    }
    return {};
  });
  
  const {
    filings,
    loading: filingsLoading,
    error: filingsError,
    companyInfo,
    loadMore: loadMoreFilings,
    hasMore: hasMoreFilings
  } = useCompanyFilings(stockCode, selectedKeywords);

  const {
    news,
    loading: newsLoading,
    error: newsError,
    loadMore: loadMoreNews,
    hasMore: hasMoreNews
  } = useCompanyNews(stockCode);

  // Save data panel visibility state
  useEffect(() => {
    localStorage.setItem('companyDataPanelVisible', isDataPanelVisible);
  }, [isDataPanelVisible]);

  // Add read status to items
  const filingsWithReadStatus = filings.map(filing => ({
    ...filing,
    isRead: readItems[`filing-${filing.bulletinIds}`] || false
  }));

  const newsWithReadStatus = news.map(story => ({
    ...story,
    isRead: readItems[`news-${story.id}`] || false
  }));

  const handleAddToWatchlist = async (watchlistId, stockCode) => {
    try {
      await addStockToWatchlist(watchlistId, stockCode);
      
      // Enable all notifications by default
      const notifications = {
        realtime_enabled: 1,
        daily_enabled: 1,
        weekly_enabled: 1,
        smart_scan_enabled: 1
      };
      
      await updateStockNotifications(watchlistId, stockCode, notifications);
      await refreshWatchlists();
    } catch (error) {
      console.error('Error adding stock to watchlist:', error);
    }
  };

  const handleCreateWatchlist = async (name, description, stockCode) => {
    try {
      const newWatchlist = await createWatchlist({ name, description });
      await handleAddToWatchlist(newWatchlist.id, stockCode);
    } catch (error) {
      console.error('Error creating watchlist:', error);
    }
  };

  const isCompanyInWatchlists = (stockCode) => {
    return watchlists.some(watchlist => 
      watchlist.stocks?.some(stock => stock.stockCode === stockCode)
    );
  };

  const handleFilingClick = (filingId) => {
    const newReadItems = {
      ...readItems,
      [`filing-${filingId}`]: true
    };
    setReadItems(newReadItems);
    localStorage.setItem('readItems', JSON.stringify(newReadItems));
  };

  const handleNewsClick = (newsId) => {
    const newReadItems = {
      ...readItems,
      [`news-${newsId}`]: true
    };
    setReadItems(newReadItems);
    localStorage.setItem('readItems', JSON.stringify(newReadItems));
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    localStorage.setItem('companyViewActiveTab', tab);
  };

  const handleKeywordChange = (newSelectedKeywords) => {
    setSelectedKeywords(newSelectedKeywords);
    localStorage.setItem('selectedKeywords', JSON.stringify(newSelectedKeywords));
  };

  const handleFilingTypeChange = (newSelectedFilingTypes) => {
    setSelectedFilingTypes(newSelectedFilingTypes);
    localStorage.setItem('selectedFilingTypes', JSON.stringify(newSelectedFilingTypes));
  };

  // Get company name from filings if companyInfo is not available
  const companyName = companyInfo?.longNameEn || filings?.[0]?.longNameEn;

  return (
    <div className="flex flex-col h-full">
      {/* Header */}
      <div className="px-4 py-4">
        {filingsLoading && filings.length === 0 ? (
          <span className="text-gray-500">Loading...</span>
        ) : companyName ? (
          <div className="flex items-center space-x-3">
            <h1 className="text-2xl font-medium text-gray-900">
              {companyName} ({stockCode})
            </h1>
            {isCompanyInWatchlists(stockCode) ? (
              <span className="px-2 py-0.5 text-xs bg-secondary-50 text-secondary-700 rounded-full">
                Following
              </span>
            ) : (
              <div className="relative inline-block">
                <button
                  onClick={() => setIsWatchlistPopupOpen(true)}
                  className="flex items-center px-3 py-1 text-sm bg-secondary hover:bg-secondary-accent text-white rounded-md transition-colors"
                >
                  <Plus className="w-4 h-4 mr-1" />
                  Follow
                </button>
                {isWatchlistPopupOpen && (
                  <WatchlistSelectionPopup
                    isOpen={isWatchlistPopupOpen}
                    onClose={() => setIsWatchlistPopupOpen(false)}
                    watchlists={watchlists}
                    stockCode={stockCode}
                    onAddToWatchlist={handleAddToWatchlist}
                    onCreateWatchlist={handleCreateWatchlist}
                  />
                )}
              </div>
            )}
          </div>
        ) : (
          <h1 className="text-2xl font-medium text-gray-900">{stockCode}</h1>
        )}
      </div>

      {/* Tab Navigation */}
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8 px-4" aria-label="Company view tabs">
          <button
            onClick={() => handleTabChange('filings')}
            className={`
              border-b-2 py-4 px-1 text-sm font-medium ${
                activeTab === 'filings'
                  ? 'border-secondary-DEFAULT text-secondary-DEFAULT'
                  : 'border-transparent text-light-text-secondary hover:border-gray-300 hover:text-light-text-DEFAULT'
              }
            `}
          >
            Filings
          </button>
          <button
            onClick={() => handleTabChange('news')}
            className={`
              border-b-2 py-4 px-1 text-sm font-medium ${
                activeTab === 'news'
                  ? 'border-secondary-DEFAULT text-secondary-DEFAULT'
                  : 'border-transparent text-light-text-secondary hover:border-gray-300 hover:text-light-text-DEFAULT'
              }
            `}
          >
            News
          </button>
        </nav>
      </div>

      {/* Filters */}
      <div className="py-4">
        <FilterBar 
          selectedKeywords={selectedKeywords}
          onKeywordChange={handleKeywordChange}
          selectedFilingTypes={selectedFilingTypes}
          onFilingTypeChange={handleFilingTypeChange}
          showExchangeFilter={false} 
        />
      </div>

      {/* Content */}
      <div 
        className="flex-1 relative"
        style={{
          marginBottom: isDataPanelVisible ? '400px' : '48px'
        }}
      >
        {activeTab === 'filings' ? (
          <FilingsTable
            filings={filingsWithReadStatus}
            loading={filingsLoading}
            error={filingsError}
            onLoadMore={loadMoreFilings}
            showCompanyInfo={false}
            hasMore={hasMoreFilings}
            onFilingClick={handleFilingClick}
            selectedKeywords={selectedKeywords}
          />
        ) : (
          <NewsTable
            news={newsWithReadStatus}
            loading={newsLoading}
            error={newsError}
            onLoadMore={loadMoreNews}
            showCompanyInfo={false}
            hasMore={hasMoreNews}
            onNewsClick={handleNewsClick}
          />
        )}
      </div>

      {/* Company Data Panel */}
              <CompanyDataPanel
        isVisible={isDataPanelVisible}
        onToggleVisibility={() => setIsDataPanelVisible(prev => !prev)}
        stockCode={stockCode}
        isFeedsPanelVisible={isFeedsPanelVisible}
      />

      {/* Watchlist Selection Popup */}
      <WatchlistSelectionPopup
        isOpen={isWatchlistPopupOpen}
        onClose={() => setIsWatchlistPopupOpen(false)}
        watchlists={watchlists}
        stockCode={stockCode}
        onAddToWatchlist={handleAddToWatchlist}
        onCreateWatchlist={handleCreateWatchlist}
      />
    </div>
  );
};

export default CompanyFilingsView;