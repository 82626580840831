import React, { useState } from 'react';
import { X } from 'lucide-react';
import SignInForm from './SignInForm';
import SignUpForm from './SignUpForm';
import { useAuth } from '../../contexts/AuthContext';

const AuthModal = ({ isOpen, onClose, inviteId = null }) => {
  const [activeTab, setActiveTab] = useState(inviteId ? 'signup' : 'signin');
  const { signUp, signIn, signInWithGoogle, isAuthenticated } = useAuth();

  if (!isOpen) return null;

  // Determine if the modal can be closed (only if user is authenticated)
  const canClose = isAuthenticated;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Backdrop */}
      <div 
        className="absolute inset-0 bg-black/50 backdrop-blur-sm"
        onClick={canClose ? onClose : undefined}
      />
      
      {/* Modal */}
      <div className="relative w-full max-w-md bg-white rounded-lg shadow-xl">
        {/* Close button - only show if can close */}
        {canClose && (
          <button
            onClick={onClose}
            className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
          >
            <X className="w-5 h-5" />
          </button>
        )}

        {/* Header with conditional title for invites */}
        <div className="px-6 py-4">
          {inviteId && (
            <div className="text-center mb-4">
              <h2 className="text-xl font-semibold text-gray-900">
                {activeTab === 'signin' ? 'Sign in to Accept Invitation' : 'Create Account to Join Team'}
              </h2>
            </div>
          )}
          <div className="flex space-x-4">
            <button
              className={`px-4 py-2 text-sm font-medium rounded-t-lg transition-colors ${
                activeTab === 'signin'
                  ? '!bg-secondary-500 text-white'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              }`}
              onClick={() => setActiveTab('signin')}
            >
              Sign In
            </button>
            <button
              className={`px-4 py-2 text-sm font-medium rounded-t-lg transition-colors ${
                activeTab === 'signup'
                  ? '!bg-secondary-500 text-white'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              }`}
              onClick={() => setActiveTab('signup')}
            >
              Sign Up
            </button>
          </div>
          <div className="mt-0.5 border-b border-gray-200"></div>
        </div>

        {/* Content */}
        <div className="p-6 pt-0">
          {activeTab === 'signin' ? (
            <SignInForm 
              onSuccess={onClose} 
              signIn={signIn}
              signInWithGoogle={signInWithGoogle}
              inviteId={inviteId}
            />
          ) : (
            <SignUpForm 
              onSuccess={onClose}
              signUp={signUp}
              signInWithGoogle={signInWithGoogle}
              inviteId={inviteId}
            />
          )}

          {/* Note for invited users */}
          {inviteId && (
            <div className="mt-4 text-sm text-gray-600 text-center">
              {activeTab === 'signin' 
                ? "Already have an account? Sign in to accept the invitation."
                : "Create an account to join the team."}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthModal;