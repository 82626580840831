// SaveFilingButton.js
import React, { useState } from 'react';
import { BookmarkPlus } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { useFilingContext } from '../contexts/FilingContext';

export function SaveFilingButton({ filing }) {
  const { user, getIdToken } = useAuth();
  const { triggerRefresh } = useFilingContext();
  const [isLoading, setIsLoading] = useState(false);
  // Check both possible property names to maintain backward compatibility
  const [isSavedLocally, setIsSavedLocally] = useState(filing.isSaved || filing.is_saved);

  const handleClick = async (e) => {
    e.stopPropagation();
    
    if (!user) {
      console.log('No current user');
      return;
    }

    setIsLoading(true);
    
    try {
      const token = await getIdToken();
      
      if (isSavedLocally) {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/saved-filings/${filing.bulletinIds}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        if (!response.ok) throw new Error('Failed to remove filing');
        setIsSavedLocally(false);
      } else {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/saved-filings`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            filing_id: filing.bulletinIds,
            stock_code: filing.stockCodes,
            company_name: filing.longNameEn,
            filing_date: filing.timeStamps
          })
        });
        
        if (!response.ok) throw new Error('Failed to save filing');
        setIsSavedLocally(true);
      }
      
      // Trigger refresh after successful save/unsave
      triggerRefresh();
    } catch (error) {
      console.error('Error in save/remove operation:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      disabled={isLoading}
      className={`inline-flex items-center justify-center transition-colors opacity-50 group-hover:opacity-100
        ${isSavedLocally
          ? 'text-secondary-accent hover:text-secondary-accent-dark' 
          : 'text-light-text-tertiary hover:text-secondary-accent'
        }`}
    >
      <BookmarkPlus className="w-4 h-4" />
    </button>
  );
}