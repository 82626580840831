import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Mail, Building2, Loader2, AlertCircle, Check } from 'lucide-react';
import AuthModal from '../components/auth/AuthModal';

const InviteAcceptView = () => {
  const { id: inviteId } = useParams();
  const { user, getIdToken, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [inviteDetails, setInviteDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [acceptStatus, setAcceptStatus] = useState('pending'); // pending, accepting, success, error

  // Fetch invitation details
  useEffect(() => {
    const fetchInviteDetails = async () => {
      try {
        const token = await getIdToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}/organizations/invites/${inviteId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Invalid or expired invitation');
        }

        const data = await response.json();
        setInviteDetails(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (isAuthenticated) {
      fetchInviteDetails();
    }
  }, [inviteId, isAuthenticated, getIdToken]);

  const handleAcceptInvite = async () => {
    setAcceptStatus('accepting');
    try {
      const token = await getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/organizations/invites/${inviteId}/accept`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to accept invitation');
      }

      setAcceptStatus('success');
      // Redirect to team view after short delay
      setTimeout(() => navigate('/team'), 1500);
    } catch (err) {
      setAcceptStatus('error');
      setError(err.message);
    }
  };

  if (!isAuthenticated) {
    return (
      <AuthModal 
        isOpen={true}
        inviteId={inviteId}
        onClose={() => {/* prevent closing */}}
      />
    );
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="w-8 h-8 text-secondary-DEFAULT animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-2xl mx-auto px-4 py-12">
        <div className="bg-red-50 p-6 rounded-lg flex items-start gap-3">
          <AlertCircle className="w-6 h-6 text-red-600 mt-0.5" />
          <div>
            <h3 className="font-semibold text-red-600">Invalid Invitation</h3>
            <p className="text-red-700 mt-1">{error}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto px-4 py-12">
      <div className="text-center mb-8">
        <div className="w-16 h-16 bg-secondary-100 rounded-full flex items-center justify-center mx-auto mb-4">
          <Building2 className="w-8 h-8 text-secondary-DEFAULT" />
        </div>
        <h1 className="text-3xl font-bold mb-2">Accept Team Invitation</h1>
        <p className="text-gray-600 text-lg">
          Join {inviteDetails.organizationName} as a {inviteDetails.role}
        </p>
      </div>

      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="space-y-4">
          <div>
            <label className="text-sm font-medium text-gray-700">Organization</label>
            <p className="mt-1 text-gray-900">{inviteDetails.organizationName}</p>
          </div>
          
          <div>
            <label className="text-sm font-medium text-gray-700">Your Role</label>
            <p className="mt-1 text-gray-900 capitalize">{inviteDetails.role}</p>
          </div>
          
          <div>
            <label className="text-sm font-medium text-gray-700">Invited By</label>
            <p className="mt-1 text-gray-900">{inviteDetails.invitedByEmail}</p>
          </div>

          {acceptStatus === 'error' && (
            <div className="bg-red-50 p-3 rounded-lg flex items-center gap-2 text-sm text-red-600">
              <AlertCircle className="w-4 h-4" />
              {error}
            </div>
          )}

          <button
            onClick={handleAcceptInvite}
            disabled={acceptStatus === 'accepting' || acceptStatus === 'success'}
            className="w-full flex items-center justify-center gap-2 py-2 px-4 bg-secondary-DEFAULT text-white rounded-lg hover:bg-secondary-accent transition-colors duration-200 disabled:opacity-50"
          >
            {acceptStatus === 'accepting' ? (
              <Loader2 className="w-5 h-5 animate-spin" />
            ) : acceptStatus === 'success' ? (
              <>
                <Check className="w-5 h-5" />
                Accepted!
              </>
            ) : (
              'Accept Invitation'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InviteAcceptView;