import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import CreateWatchlistModal from './watchlists/CreateWatchlistModal';

const WatchlistSelectionPopup = ({
  isOpen,
  onClose,
  watchlists,
  stockCode,
  onAddToWatchlist,
  onCreateWatchlist,
  position
}) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  if (!isOpen) return null;

  const handleAddToWatchlist = async (watchlistId) => {
    await onAddToWatchlist(watchlistId, stockCode);
    onClose();
  };

  const handleCreateWatchlist = async (name, description) => {
    await onCreateWatchlist(name, description, stockCode);
    setIsCreateModalOpen(false);
    onClose();
  };

  return (
    <>
      <div 
        className="fixed inset-0" 
        onClick={onClose}
      />
      <div 
        className="absolute z-50 w-64 bg-white rounded-lg shadow-lg border border-gray-200"
        style={{
          top: '100%',
          left: '0',
          marginTop: '0.5rem'
        }}
      >
        <div className="py-2">
          {watchlists.map((watchlist) => (
            <button
              key={watchlist.id}
              onClick={() => handleAddToWatchlist(watchlist.id)}
              className="w-full px-4 py-2 text-sm text-left hover:bg-gray-50 flex items-center justify-between group"
            >
              <span className="text-gray-700">{watchlist.name}</span>
              <span className="text-secondary-accent opacity-0 group-hover:opacity-100">
                Add
              </span>
            </button>
          ))}
          
          <div className="border-t border-gray-200 mt-2 pt-2">
            <button
              onClick={() => setIsCreateModalOpen(true)}
              className="w-full px-4 py-2 text-sm text-left hover:bg-gray-50 text-gray-700 flex items-center"
            >
              <Plus className="w-4 h-4 mr-2" />
              Create New Watchlist
            </button>
          </div>
        </div>
      </div>

      <CreateWatchlistModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onSubmit={handleCreateWatchlist}
        initialStockCode={stockCode}
      />
    </>
  );
};

export default WatchlistSelectionPopup;