// src/components/team/MemberList.js
import React from 'react';
import { User, MoreVertical, UserX, UserCheck, UserMinus } from 'lucide-react';

const MemberList = ({ members, currentUser, onRoleUpdate, onRemoveMember }) => {
  const [openMenu, setOpenMenu] = React.useState(null);

  // Convert numeric is_administrator to boolean
  const isCurrentUserAdmin = Boolean(currentUser.is_administrator);

  return (
    <div className="bg-white rounded-lg shadow mb-8">
      <div className="px-6 py-4 border-b border-gray-200">
        <h2 className="text-lg font-medium text-gray-900">Team Members</h2>
      </div>
      
      <div className="divide-y divide-gray-200">
        {members.map((member) => {
          // Convert numeric is_administrator to boolean for each member
          const isAdmin = Boolean(member.is_administrator);
          const isCurrentMember = member.id === currentUser.id;
          
          return (
            <div 
              key={member.id}
              className="px-6 py-4 flex items-center justify-between hover:bg-gray-50"
            >
              <div className="flex items-center space-x-4">
                <div className="w-10 h-10 rounded-full bg-secondary-DEFAULT/10 flex items-center justify-center">
                  {member.full_name ? (
                    <span className="text-secondary-DEFAULT font-medium">
                      {member.full_name.split(' ').map(n => n[0]).join('')}
                    </span>
                  ) : (
                    <User className="w-5 h-5 text-secondary-DEFAULT" />
                  )}
                </div>
                
                <div>
                  <div className="flex items-center gap-2">
                    <span className="font-medium text-gray-900">
                      {member.email}
                    </span>
                    {isAdmin && (
                      <span className="text-xs bg-green-100 text-green-800 px-2 py-0.5 rounded-full font-medium">
                        Admin
                      </span>
                    )}
                    {isCurrentMember && (
                      <span className="text-xs text-gray-500 font-medium">
                        (you)
                      </span>
                    )}
                  </div>
                  {member.job_title && (
                    <span className="text-sm text-gray-500">{member.job_title}</span>
                  )}
                </div>
              </div>

              {isCurrentUserAdmin && !isCurrentMember && (
                <div className="relative">
                  <button
                    onClick={() => setOpenMenu(openMenu === member.id ? null : member.id)}
                    className="p-2 hover:bg-gray-100 rounded-full"
                  >
                    <MoreVertical className="w-5 h-5 text-gray-400" />
                  </button>

                  {openMenu === member.id && (
                    <div 
                      className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200 py-1 z-10"
                    >
                      <button
                        onClick={() => {
                          onRoleUpdate(member.id, !isAdmin);
                          setOpenMenu(null);
                        }}
                        className="w-full px-4 py-2 text-sm text-left hover:bg-gray-50 flex items-center gap-2"
                      >
                        {isAdmin ? (
                          <>
                            <UserX className="w-4 h-4" />
                            Remove Admin Role
                          </>
                        ) : (
                          <>
                            <UserCheck className="w-4 h-4" />
                            Make Admin
                          </>
                        )}
                      </button>
                      <button
                        onClick={() => {
                          onRemoveMember(member.id);
                          setOpenMenu(null);
                        }}
                        className="w-full px-4 py-2 text-sm text-left text-red-600 hover:bg-red-50 flex items-center gap-2"
                      >
                        <UserMinus className="w-4 h-4" />
                        Remove from Team
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MemberList;