import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';

export const useKeywordAlerts = () => {
  const [keywordAlerts, setKeywordAlerts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user, getIdToken } = useAuth();

  const fetchKeywordAlerts = useCallback(async () => {
    if (!user) {
      setKeywordAlerts([]);
      setLoading(false);
      return;
    }

    try {
      const token = await getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/keyword-alerts`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch keyword alerts');
      }

      const data = await response.json();
      setKeywordAlerts(data);
      setError(null);
    } catch (err) {
      console.error('Error fetching keyword alerts:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [user, getIdToken]);

  useEffect(() => {
    fetchKeywordAlerts();
  }, [fetchKeywordAlerts]);

  const addKeywordAlert = useCallback(async (keyword, exchanges, alertConfig) => {
    try {
      const token = await getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/keyword-alerts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          keyword,
          exchanges,
          notifications: {
            realtime_enabled: alertConfig?.realtime_enabled ?? 0,
            daily_enabled: alertConfig?.daily_enabled ?? 1,
            weekly_enabled: alertConfig?.weekly_enabled ?? 1,
            smart_scan_enabled: alertConfig?.smart_scan_enabled ?? 0
          }
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to add keyword alert');
      }

      const newAlert = await response.json();
      setKeywordAlerts(prev => [...prev, newAlert]);
      return newAlert;
    } catch (err) {
      console.error('Error adding keyword alert:', err);
      throw err;
    }
  }, [getIdToken]);

  const updateKeywordAlert = useCallback(async (alertId, updateData) => {
    try {
      const token = await getIdToken();
      
      // Get default notification settings for new exchanges
      const defaultNotifications = {
        realtime_enabled: 1,
        daily_enabled: 1,
        weekly_enabled: 1,
        smart_scan_enabled: 1
      };
  
      // Create notifications object for the request
      const notificationsObject = {};
      updateData.exchanges.forEach(exchange => {
        if (exchange) {
          notificationsObject[exchange] = defaultNotifications;
        }
      });
  
      const response = await fetch(`${process.env.REACT_APP_API_URL}/keyword-alerts/${alertId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          keyword: updateData.keyword,
          exchanges: updateData.exchanges.filter(Boolean),
          notifications: defaultNotifications
        })
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update keyword alert');
      }
  
      const updatedAlert = await response.json();
      setKeywordAlerts(prev => prev.map(alert => 
        alert.id === alertId ? updatedAlert : alert
      ));
      return updatedAlert;
    } catch (err) {
      console.error('Error updating keyword alert:', err);
      throw err;
    }
  }, [getIdToken]);

  const updateKeywordNotifications = useCallback(async (keywordId, exchange, notifications) => {
    try {
      const token = await getIdToken();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/keyword-alerts/${keywordId}/notifications/${exchange}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ notifications })
        }
      );
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update keyword notifications');
      }
  
      // Only update state after successful API response
      setKeywordAlerts(prev => prev.map(alert => {
        if (alert.id === keywordId) {
          return {
            ...alert,
            notifications: {
              ...alert.notifications,
              [exchange]: {
                ...alert.notifications[exchange],
                ...notifications
              }
            }
          };
        }
        return alert;
      }));
      
    } catch (err) {
      console.error('Error updating keyword notifications:', err);
      throw err;
    }
  }, [getIdToken]);

  const updateKeywordBatchNotifications = useCallback(async (keywordId, notificationType, enabled, exchanges) => {
    try {
      // Update local state immediately
      setKeywordAlerts(prev => prev.map(alert => {
        if (alert.id === keywordId) {
          const updatedNotifications = { ...alert.notifications };
          exchanges.forEach(exchange => {
            updatedNotifications[exchange] = {
              ...updatedNotifications[exchange],
              [notificationType]: enabled ? 1 : 0
            };
          });
          return {
            ...alert,
            notifications: updatedNotifications
          };
        }
        return alert;
      }));

      const token = await getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/keyword-alerts/batch-notifications`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          alertId: keywordId,
          notification_type: notificationType,
          enabled: enabled ? 1 : 0,
          exchanges
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update batch notifications');
      }

      // Fetch to ensure we're in sync with server
      await fetchKeywordAlerts();
      
    } catch (err) {
      console.error('Error updating batch notifications:', err);
      // Revert local state on error
      await fetchKeywordAlerts();
      throw err;
    }
  }, [getIdToken, fetchKeywordAlerts]);

  const deleteKeywordAlert = useCallback(async (alertId) => {
    try {
      // Update local state immediately
      setKeywordAlerts(prev => prev.filter(alert => alert.id !== alertId));

      const token = await getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/keyword-alerts/${alertId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to delete keyword alert');
      }

    } catch (err) {
      console.error('Error deleting keyword alert:', err);
      // Revert local state on error
      await fetchKeywordAlerts();
      throw err;
    }
  }, [getIdToken, fetchKeywordAlerts]);

  const deleteExchanges = useCallback(async (alertId, exchangesToDelete) => {
    try {
      // Update local state immediately
      setKeywordAlerts(prev => prev.map(alert => {
        if (alert.id === alertId) {
          return {
            ...alert,
            exchanges: alert.exchanges.filter(
              exchange => !exchangesToDelete.includes(exchange)
            )
          };
        }
        return alert;
      }));

      const token = await getIdToken();
      const alert = keywordAlerts.find(a => a.id === alertId);
      if (!alert) return;
      
      const remainingExchanges = alert.exchanges.filter(
        exchange => !exchangesToDelete.includes(exchange)
      );
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}/keyword-alerts/${alertId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          exchanges: remainingExchanges
        })
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to delete exchanges');
      }

      // Fetch to ensure we're in sync with server
      await fetchKeywordAlerts();
      
    } catch (err) {
      console.error('Error deleting exchanges:', err);
      // Revert local state on error
      await fetchKeywordAlerts();
      throw err;
    }
  }, [getIdToken, keywordAlerts, fetchKeywordAlerts]);

  return {
    keywordAlerts,
    loading,
    error,
    addKeywordAlert,
    updateKeywordAlert,
    updateKeywordNotifications,
    updateKeywordBatchNotifications,
    deleteKeywordAlert,
    deleteExchanges,
    refreshKeywordAlerts: fetchKeywordAlerts
  };
};

export default useKeywordAlerts;