import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { X, Clock, Building2, Share2, Newspaper, Lock, FileText } from 'lucide-react';
import { useViewerAuth } from '../../contexts/AuthContext';
import ContentAuthGate from '../auth/ContentAuthGate';

const NewsStoryModal = ({ 
  storyId, 
  isOpen, 
  onClose,
  exchange,
  date,
  slug 
}) => {
  const { canAccessProFeatures, getIdToken, user } = useViewerAuth();
  const [story, setStory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const fetchStory = async () => {
      if (!isOpen || !exchange || !date || !slug) return;

      try {
        const token = await getIdToken();
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/news/${exchange}/${date}/${slug}`,
          {
            headers: { 
              'Authorization': `Bearer ${token}` 
            },
            signal: controller.signal
          }
        );
        
        if (!response.ok) throw new Error('Failed to load story');
        
        const data = await response.json();
        if (isMounted) {
          setStory(data);
          setError(null);
        }
      } catch (err) {
        if (isMounted && err.name !== 'AbortError') {
          setError(err.message);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchStory();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [isOpen, exchange, date, slug, getIdToken]);

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: story.title,
        text: story.summary,
        url: `${window.location.origin}/news-wire/${exchange}/${date}/${slug}`
      });
    } else {
      navigator.clipboard.writeText(window.location.href);
      // TODO: Add toast notification here
    }
  };

  if (!isOpen) return null;

  return createPortal(
    <div className="fixed inset-0 bg-black/50 z-[100] flex items-center justify-center p-4" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <div className="bg-white rounded-lg w-full max-w-3xl max-h-[90vh] flex flex-col">
        {/* Modal Header */}
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-semibold">News Story</h2>
          <button
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {/* Modal Content */}
        <div className="flex-1 p-6 overflow-y-auto
          [scrollbar-width:thin]
          [scrollbar-color:#E5E7EB_transparent]
          [&::-webkit-scrollbar]:w-1.5
          [&::-webkit-scrollbar-track]:bg-transparent
          [&::-webkit-scrollbar-thumb]:bg-gray-200
          [&::-webkit-scrollbar-thumb]:rounded-full
          [&::-webkit-scrollbar-thumb]:border-4
          [&::-webkit-scrollbar-thumb]:border-solid
          [&::-webkit-scrollbar-thumb]:border-transparent
          hover:[&::-webkit-scrollbar-thumb]:bg-gray-300">
          <ContentAuthGate>
            {loading ? (
              <div className="animate-pulse space-y-4">
                <div className="h-8 bg-gray-200 rounded w-3/4"></div>
                <div className="h-4 bg-gray-200 rounded w-1/4"></div>
                <div className="h-32 bg-gray-200 rounded"></div>
              </div>
            ) : error ? (
              <div className="text-center text-red-600 py-8">{error}</div>
            ) : story ? (
              <div className="space-y-6">
                {canAccessProFeatures ? (
                  <>
                    <div className="space-y-4">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center text-light-text-secondary text-sm">
                            <Clock className="w-4 h-4 mr-1" />
                            <span>{new Date(story.created_at).toLocaleString()}</span>
                            <span className="mx-2">·</span>
                            <span>{story.read_time} read</span>
                        </div>
                        <button
                          onClick={handleShare}
                          className="p-2 text-light-text-secondary hover:text-secondary rounded-lg hover:bg-light-bg-secondary transition-colors"
                        >
                          <Share2 className="w-4 h-4" />
                        </button>
                      </div>

                      <h1 className="text-2xl font-bold text-light-text">
                        {story.title}
                      </h1>

                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <Building2 className="w-4 h-4 mr-1 text-secondary" />
                          <span className="font-medium text-light-text">
                            {story.company_name}
                          </span>
                          <span className="mx-2 text-light-text-tertiary">·</span>
                          <span className="font-mono text-light-text-secondary">
                            {story.stock_code}
                          </span>
                        </div>
                        <div className="flex items-center text-light-text-secondary text-sm">
                          <Newspaper className="w-4 h-4 mr-1" />
                          <span>{story.category}</span>
                        </div>
                      </div>

                      <div className="prose max-w-none">
                        <p className="text-light-text-secondary text-lg leading-relaxed">
                          {story.summary}
                        </p>
                      </div>
                    </div>

                    {story.source_docs?.length > 0 && (
                      <div className="pt-6 mt-6 border-t">
                        <h2 className="text-lg font-semibold text-light-text mb-4">
                          Source Documents
                        </h2>
                        <div className="space-y-2">
                          {story.source_docs.map((doc, index) => (
                            <a
                                key={index}
                                href={`/filing/${story.filing_ids[index]}/view`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center p-3 rounded-lg hover:bg-light-bg-secondary transition-colors"
                            >
                              <FileText className="w-4 h-4 mr-2 text-light-text-secondary" />
                              <span className="text-light-text-secondary hover:text-secondary">
                                {doc}
                              </span>
                            </a>
                          ))}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="text-center py-12">
                    <div className="w-12 h-12 mx-auto bg-secondary/10 rounded-full flex items-center justify-center mb-4">
                      <Lock className="w-6 h-6 text-secondary" />
                    </div>
                    <h3 className="text-xl font-semibold mb-2">
                      Pro Subscription Required
                    </h3>
                    <p className="text-gray-600 max-w-md mx-auto mb-6">
                      Get unlimited access to news stories, filings, and more with a Pro subscription.
                    </p>
                    <a
                      href="/plans"
                      className="inline-block px-6 py-2 bg-secondary text-white rounded-lg hover:bg-secondary/90 transition-colors"
                    >
                      View Plans
                    </a>
                  </div>
                )}
              </div>
            ) : null}
          </ContentAuthGate>
        </div>

        {/* Optional Footer */}
        {story && canAccessProFeatures && (
          <div className="border-t p-4">
            <a
              href={`/news-wire/${exchange}/${date}/${slug}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-light-text-secondary hover:text-secondary"
            >
              View original story
            </a>
          </div>
        )}
      </div>
    </div>
  , document.body);
};

export default NewsStoryModal;