export const formatDateTime = (timestamp) => {
  try {
    return new Date(timestamp).toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  } catch (err) {
    console.error('Date formatting error:', err);
    return 'Invalid date';
  }
};

export const formatStockCode = (stockCode) => {
  const [exchange, code] = stockCode.split(':');
  return code ? `${exchange}:${code}` : stockCode;
};

export const truncateText = (text, maxLength = 100) => {
  if (!text || text.length <= maxLength) return text;
  return `${text.slice(0, maxLength)}...`;
};

/**
 * Format numbers with localization and various formatting options
 * @param {number} value - The number to format
 * @param {Object} options - Intl.NumberFormat options object
 * @param {string} options.style - The formatting style ('decimal', 'currency', 'percent', etc.)
 * @param {string} options.notation - The notation to use ('standard', 'scientific', 'engineering', 'compact')
 * @param {string} options.currency - The currency to use in currency formatting
 * @param {number} options.maximumFractionDigits - Maximum number of fraction digits to use
 * @returns {string} The formatted number
 */
export const formatNumber = (value, options = {}) => {
  if (value === null || value === undefined) return '';
  
  const defaultOptions = {
    style: 'decimal',
    notation: 'standard',
    maximumFractionDigits: 2
  };

  try {
    // Use en-US locale to match the application's locale settings
    return new Intl.NumberFormat('en-US', {
      ...defaultOptions,
      ...options
    }).format(value);
  } catch (error) {
    console.error('Number formatting error:', error);
    return String(value);
  }
};