import React, { useState, useEffect, Suspense } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Loader2, Download, Lock } from 'lucide-react';
import { formatDateTime } from '../utils/formatters';
import { useViewerAuth } from '../contexts/AuthContext';
import ContentAuthGate from '../components/auth/ContentAuthGate';

// Lazy load non-critical component
const SummaryPanel = React.lazy(() => import('../components/SummaryPanel'));

const FilingContent = ({ filing, canAccessProFeatures, handleViewPlans, summaryExpanded, setSummaryExpanded }) => (
  <div className="min-h-screen flex flex-col">
    {/* Header */}
    <header className="bg-white border-b border-gray-200 py-4 px-6">
      <div className="flex items-center justify-between max-w-7xl mx-auto">
        <div>
          <h1 className="text-xl font-semibold text-light-text">
            {filing.longNameEn || 'Company Name Not Available'}
          </h1>
          <div className="mt-1 flex items-center space-x-4 text-sm text-light-text-secondary">
            <span>{filing.stockCodes}</span>
            <span>•</span>
            <span>{formatDateTime(filing.timeStamps)}</span>
            {filing.pageCount && (
              <>
                <span>•</span>
                <span>{filing.pageCount} pages</span>
              </>
            )}
          </div>
          <p className="mt-2 text-sm text-light-text-secondary">{filing.titlesTranslated}</p>
        </div>
        {canAccessProFeatures && filing.documentUrl && (
          <a
            href={filing.documentUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center space-x-2 px-4 py-2 bg-secondary-DEFAULT text-light-bg rounded-lg hover:bg-secondary-accent"
          >
            <Download className="w-4 h-4" />
            <span>Download PDF</span>
          </a>
        )}
      </div>
    </header>

    {/* Content */}
    <div className="flex-1 bg-gray-100">
      <div className="max-w-7xl mx-auto p-6 h-full">
        <div className="flex space-x-4 h-[calc(100vh-120px)]">
          {/* PDF Viewer */}
          <div className="bg-white rounded-lg shadow flex-1">
            <div className="w-full h-full">
              {canAccessProFeatures && filing.documentUrl ? (
                <object
                  data={filing.documentUrl}
                  type="application/pdf"
                  className="w-full h-full rounded-lg"
                >
                  <p className="p-4">
                    Unable to display PDF file. <a href={filing.documentUrl} target="_blank" rel="noopener noreferrer">Download</a> instead.
                  </p>
                </object>
              ) : (
                <div className="w-full h-full flex flex-col items-center justify-center">
                  <div className="w-12 h-12 bg-secondary-DEFAULT/10 rounded-full flex items-center justify-center mb-4">
                    <Lock className="w-6 h-6 text-secondary-DEFAULT" />
                  </div>
                  <h3 className="text-xl font-semibold text-center mb-2">
                    Pro Subscription Required
                  </h3>
                  <p className="text-gray-600 text-center max-w-md mb-6">
                    Get unlimited access to filings, real-time alerts, and advanced search features with a Pro subscription.
                  </p>
                  <div className="flex flex-col gap-3 items-center">
                    <button
                      onClick={handleViewPlans}
                      className="px-6 py-2 bg-secondary-500 text-white rounded-lg hover:bg-secondary-600 transition-colors font-medium"
                    >
                      View Plans
                    </button>
                    <p className="text-sm text-gray-500">
                      Choose a plan that works best for you
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Summary Panel - Only show for pro users */}
          {canAccessProFeatures && (
            <Suspense fallback={<div className="w-64" />}>
              <SummaryPanel
                filing={filing}
                expanded={summaryExpanded}
                onToggleExpand={() => setSummaryExpanded(!summaryExpanded)}
              />
            </Suspense>
          )}
        </div>
      </div>
    </div>
  </div>
);

const FilingViewer = () => {
  const { filingId } = useParams();
  const navigate = useNavigate();
  const { canAccessProFeatures, getIdToken, loading: authLoading, user } = useViewerAuth();
  const [filing, setFiling] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [summaryExpanded, setSummaryExpanded] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const fetchFiling = async () => {
      if (!filingId) {
        setError('No filing ID provided');
        setLoading(false);
        return;
      }

      // Don't fetch if not authenticated or while auth is initializing
      if (authLoading || !user) {
        return;
      }

      try {
        const token = await getIdToken();
        const endpoints = [
          `${process.env.REACT_APP_API_URL}/filings/${encodeURIComponent(filingId)}/metadata`,
          canAccessProFeatures && `${process.env.REACT_APP_API_URL}/filings/${encodeURIComponent(filingId)}/document`
        ].filter(Boolean);

        const responses = await Promise.all(
          endpoints.map(url => 
            fetch(url, {
              headers: { 'Authorization': `Bearer ${token}` },
              signal: controller.signal
            })
          )
        );

        if (!isMounted) return;

        // Check if any response is not ok
        const failedResponse = responses.find(response => !response.ok);
        if (failedResponse) {
          throw new Error(`API request failed with status ${failedResponse.status}`);
        }

        const metadata = await responses[0].json();
        const documentUrl = responses[1] ? (await responses[1].json()).documentUrl : null;

        if (isMounted) {
          setFiling({
            ...metadata,
            documentUrl
          });
          setError(null);
        }
      } catch (err) {
        if (!isMounted) return;
        if (err.name === 'AbortError') return;
        console.error('Error fetching filing:', err);
        setError(err.message);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchFiling();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [filingId, canAccessProFeatures, getIdToken, authLoading, user]);

  const handleViewPlans = () => {
    navigate('/plans');
  };

  return (
    <ContentAuthGate 
      title="Sign in to View Filing"
      description="Access detailed filing information and analysis tools"
    >
      {loading ? (
        <div className="flex items-center justify-center min-h-screen">
          <Loader2 className="w-8 h-8 animate-spin text-secondary-DEFAULT" />
        </div>
      ) : error ? (
        <div className="flex items-center justify-center min-h-screen">
          <div className="bg-red-50 p-6 rounded-lg max-w-md w-full mx-4">
            <p className="text-red-600 text-center">{error}</p>
          </div>
        </div>
      ) : !filing ? null : (
        <FilingContent 
          filing={filing}
          canAccessProFeatures={canAccessProFeatures}
          handleViewPlans={handleViewPlans}
          summaryExpanded={summaryExpanded}
          setSummaryExpanded={setSummaryExpanded}
        />
      )}
    </ContentAuthGate>
  );
};

export default FilingViewer;