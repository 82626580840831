import React, { useState } from 'react';
import { Loader2 } from 'lucide-react';
import { useViewerAuth } from '../../contexts/AuthContext';
import SignInForm from './SignInForm';
import SignUpForm from './SignUpForm';

const ContentAuthGate = ({ 
  children,
  title = "Sign in to View Content",
  description
}) => {
  const { user, loading: authLoading } = useViewerAuth();
  const [authTab, setAuthTab] = useState('signin');

  if (authLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="w-8 h-8 animate-spin text-secondary-DEFAULT" />
      </div>
    );
  }

  if (!user) {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col">
        {/* Simple header */}
        <header className="bg-white border-b border-gray-200 py-4 px-6">
          <div className="max-w-7xl mx-auto">
            <h1 className="text-xl font-semibold text-gray-900">{title}</h1>
            {description && (
              <p className="mt-1 text-sm text-gray-500">{description}</p>
            )}
          </div>
        </header>

        {/* Auth content */}
        <div className="flex-1 flex items-center justify-center p-6">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
            {/* Tabs */}
            <div className="flex space-x-4 mb-6">
              <button
                className={`flex-1 py-2 text-sm font-medium rounded-lg transition-colors ${
                  authTab === 'signin'
                    ? 'bg-secondary-500 text-white'
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
                onClick={() => setAuthTab('signin')}
              >
                Sign In
              </button>
              <button
                className={`flex-1 py-2 text-sm font-medium rounded-lg transition-colors ${
                  authTab === 'signup'
                    ? 'bg-secondary-500 text-white'
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
                onClick={() => setAuthTab('signup')}
              >
                Sign Up
              </button>
            </div>

            {/* Forms */}
            {authTab === 'signin' ? (
              <SignInForm onSuccess={() => null} />
            ) : (
              <SignUpForm onSuccess={() => null} />
            )}
          </div>
        </div>
      </div>
    );
  }

  return children;
};

export default ContentAuthGate;