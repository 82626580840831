import React, { useState, useEffect } from 'react';
import { 
  Users,
  Plus,
  BarChart3,
  BarChart2,
  Building2,
  Building,
  Laptop,
  Stethoscope,
  FlaskConical,
  Milk,
  Fish,
  Trees,
  Sun,
  Wind,
  Fuel,
  Factory,
  Hammer,
  Construction,
  Car,
  Plane,
  Ship,
  PlaneTakeoff,
  Wheat,
  Loader2
} from 'lucide-react';
import useWatchlists from '../hooks/useWatchlists';
import { useAuth } from '../contexts/AuthContext';

// Icon mapping object remains the same
const ICON_MAPPING = {
  'Building2': Building2,
  'Building': Building,
  'BarChart3': BarChart3,
  'BarChart2': BarChart2,
  'Stethoscope': Stethoscope,
  'FlaskConical': FlaskConical,
  'Milk': Milk,
  'Fish': Fish,
  'Trees': Trees,
  'Sun': Sun,
  'Wind': Wind,
  'Fuel': Fuel,
  'Factory': Factory,
  'Hammer': Hammer,
  'Construction': Construction,
  'Car': Car,
  'Plane': Plane,
  'Ship': Ship,
  'PlaneTakeoff': PlaneTakeoff,
  'Wheat': Wheat
};

const DEFAULT_ICON = Building2;

const CategoryCard = ({ 
  title, 
  description, 
  iconName, 
  followersCount, 
  stockCount, 
  backgroundImage, 
  gradient_colors, 
  isFollowing, 
  onToggleFollow 
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const Icon = ICON_MAPPING[iconName] || DEFAULT_ICON;
  
  const handleFollow = async () => {
    setIsLoading(true);
    await onToggleFollow();
    setIsLoading(false);
  };

  const imageUrl = backgroundImage ? (
    backgroundImage.startsWith('//') ? 
      `https:${backgroundImage}` : 
      backgroundImage.startsWith('http') ? 
        backgroundImage : 
        `https://${backgroundImage}`
  ) : null;

  return (
    <div className="relative group rounded-xl overflow-hidden h-64 transform transition-all duration-300 hover:scale-[1.02] hover:shadow-xl">
      <div className="absolute inset-0">
        {imageUrl ? (
          <>
            <img 
              src={imageUrl}
              alt={title} 
              className="w-full h-full object-cover brightness-[0.65] transition-all duration-300 group-hover:brightness-[0.7]"
            />
            <div 
              className={`absolute inset-0 bg-gradient-to-br ${
                gradient_colors?.replace(
                  /(from-\w+-\d+) (to-\w+-\d+)/, 
                  '$1/80 $2/90'
                ) || 'from-blue-600/80 to-blue-900/90'
              } mix-blend-multiply transition-opacity group-hover:opacity-90`}
            />
          </>
        ) : (
          <div className={`w-full h-full bg-gradient-to-br ${gradient_colors || 'from-blue-600 to-blue-900'}`} />
        )}
      </div>

      <div className="relative p-6 h-full flex flex-col">
        <div className="flex items-start justify-between mb-4">
          <div className="p-3 rounded-lg bg-white/20 transition-all duration-300 group-hover:bg-white/30">
            <Icon className="w-6 h-6 text-white" />
          </div>
          <button
            onClick={handleFollow}
            disabled={isLoading}
            className={`flex items-center px-4 py-2 rounded-lg text-sm font-medium transition-all duration-300 ${
              isFollowing
                ? 'bg-white/20 text-white hover:bg-white/30 backdrop-blur-sm'
                : 'bg-white text-gray-900 hover:bg-opacity-90 hover:shadow-lg'
            }`}
          >
            {isLoading ? (
              <Loader2 className="w-4 h-4 animate-spin" />
            ) : isFollowing ? (
              'Following'
            ) : (
              <>
                <Plus className="w-4 h-4 mr-1" />
                Follow
              </>
            )}
          </button>
        </div>

        <div className="flex-1 text-shadow-sm">
          <h3 className="text-2xl font-bold text-white mb-2">{title}</h3>
          <p className="text-white/90 text-sm leading-relaxed mb-4">{description}</p>
        </div>

        <div className="flex items-center justify-between text-white/80 text-sm font-medium bg-black/20 rounded-lg p-3">
          <div className="flex items-center">
            <Users className="w-4 h-4 mr-2" />
            {followersCount.toLocaleString()} followers
          </div>
          <div className="flex items-center">
            <BarChart3 className="w-4 h-4 mr-2" />
            {stockCount} stocks
          </div>
        </div>
      </div>
    </div>
  );
};

const DiscoverySection = ({ title, description, items, onToggleFollow }) => (
  <div className="mb-12">
    <div className="mb-8">
      <h2 className="text-2xl font-bold text-gray-900">{title}</h2>
      <p className="text-gray-600 mt-2 text-lg">{description}</p>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
      {items.map((item) => (
        <CategoryCard
          key={item.id}
          {...item}
          onToggleFollow={() => onToggleFollow(item.id, !item.isFollowing)}
        />
      ))}
    </div>
  </div>
);

const DiscoveryView = () => {
  // State and hooks remain the same
  const { getIdToken } = useAuth();
  const { createWatchlist } = useWatchlists();
  const [followedItems, setFollowedItems] = useState(new Set());
  const [isLoading, setIsLoading] = useState(true);
  const [featuredWatchlists, setFeaturedWatchlists] = useState([]);

  // Market categories data remains the same
  const [marketCategories] = useState([
    {
      id: 'mergers',
      title: 'Mergers & Acquisitions',
      description: 'Track latest M&A activities, deals, and corporate restructuring news',
      iconName: 'Building2',
      followersCount: 15234,
      stockCount: 150,
      gradient_colors: 'from-purple-600 to-purple-900'
    },
    {
      id: 'earnings',
      title: 'Earnings Reports',
      description: 'Stay updated on quarterly and annual earnings announcements',
      iconName: 'BarChart3',
      followersCount: 12500,
      stockCount: 200,
      gradient_colors: 'from-indigo-600 to-indigo-900'
    },
    {
      id: 'tech',
      title: 'Technology Sector',
      description: 'Follow tech companies and stay informed about industry developments',
      iconName: 'Laptop',
      followersCount: 18900,
      stockCount: 175,
      gradient_colors: 'from-blue-600 to-blue-900'
    }
  ]);

  // Effects and handlers remain the same...
  useEffect(() => {
    const fetchFeaturedWatchlists = async () => {
      try {
        const token = await getIdToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}/featured-watchlists`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        
        if (!response.ok) throw new Error('Failed to fetch featured watchlists');
        
        const data = await response.json();
        setFeaturedWatchlists(data);
      } catch (error) {
        console.error('Error fetching featured watchlists:', error);
      }
    };

    const fetchFollowedItems = async () => {
      try {
        const token = await getIdToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user/subscriptions`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        
        if (!response.ok) throw new Error('Failed to fetch subscriptions');
        
        const data = await response.json();
        setFollowedItems(new Set(data.map(item => item.id)));
      } catch (error) {
        console.error('Error fetching followed items:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFeaturedWatchlists();
    fetchFollowedItems();
  }, [getIdToken]);

  const handleToggleFollow = async (itemId, shouldFollow) => {
    try {
      const token = await getIdToken();
      const method = shouldFollow ? 'POST' : 'DELETE';
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/subscriptions/${itemId}`, {
        method,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        ...(shouldFollow && {
          body: JSON.stringify({
            notifications: {
              realtime_enabled: 1,
              daily_enabled: 1,
              weekly_enabled: 1,
              smart_scan_enabled: 1
            }
          })
        })
      });
  
      if (!response.ok) throw new Error('Failed to update subscription');
  
      setFollowedItems(prev => {
        const newSet = new Set(prev);
        if (shouldFollow) {
          newSet.add(itemId);
        } else {
          newSet.delete(itemId);
        }
        return newSet;
      });
  
    } catch (error) {
      console.error('Error updating subscription:', error);
      setFollowedItems(prev => {
        const newSet = new Set(prev);
        if (shouldFollow) {
          newSet.delete(itemId);
        } else {
          newSet.add(itemId);
        }
        return newSet;
      });
    }
  };
  
  if (isLoading) {
    return (
      <div className="p-8">
        <div className="animate-pulse max-w-7xl mx-auto">
          <div className="h-10 bg-gray-200 rounded w-1/4 mb-4"></div>
          <div className="h-6 bg-gray-200 rounded w-2/3 mb-12"></div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            {[1, 2, 3].map((n) => (
              <div key={n} className="h-64 bg-gray-200 rounded-xl"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  const enhanceItems = (items) =>
    items.map(item => ({
      ...item,
      backgroundImage: item.background_image,
      isFollowing: followedItems.has(item.id),
    }));

  return (
    <div className="p-8 max-w-7xl mx-auto">
      <div className="mb-12">
        <h1 className="text-3xl font-bold text-gray-900">Discover</h1>
        <p className="text-gray-600 text-xl mt-2">
          Follow industry sectors and market topics to stay informed
        </p>
      </div>

      <DiscoverySection
        title="Featured Industries"
        description="Popular industry sectors with curated company lists"
        items={enhanceItems(featuredWatchlists)}
        onToggleFollow={handleToggleFollow}
      />

      <DiscoverySection
        title="Market Topics"
        description="Get notifications for specific types of market events and announcements"
        items={enhanceItems(marketCategories)}
        onToggleFollow={handleToggleFollow}
      />
    </div>
  );
};

export default DiscoveryView;