import { useState, useEffect, useCallback, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';

const useExchangeAccess = () => {
  const [includedExchanges, setIncludedExchanges] = useState([]);
  const [notIncludedExchanges, setNotIncludedExchanges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [subscriptionLimits, setSubscriptionLimits] = useState({
    current: 0,
    maximum: 2
  });
  
  const { getIdToken, user } = useAuth();

  const fetchExchangesRef = useRef(null);

  fetchExchangesRef.current = async () => {
    console.log('Fetching exchange access...');
    try {
      const token = await getIdToken();
      console.log('Token exists:', !!token);
      const apiUrl = `${process.env.REACT_APP_API_URL}/exchanges/access`;
      console.log('Making request to:', apiUrl);
      
      const response = await fetch(apiUrl, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      console.log('Response:', response.status, response.statusText);
  
      if (!response.ok) {
        const text = await response.text();
        console.log('Error response body:', text);
        throw new Error('Failed to fetch exchange access');
      }
  
      const data = await response.json();
      console.log('Received data:', data);
      
      setIncludedExchanges(data.included || []);
      setNotIncludedExchanges(data.notIncluded || []);
      setSubscriptionLimits(prev => ({
        ...prev,
        current: data.included?.length || 0
      }));
      setError(null);
    } catch (err) {
      console.error('Error fetching exchange access:', {
        message: err.message,
        stack: err.stack
      });
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (getIdToken && user) {
      console.log('Triggering exchange access fetch...');
      fetchExchangesRef.current();
    } else {
      console.log('Skipping fetch - missing auth requirements:', {
        hasGetIdToken: !!getIdToken,
        hasUser: !!user
      });
    }
  }, [getIdToken, user]);

  const enableExchange = useCallback(async (exchangeId) => {
    console.log('Enabling exchange:', exchangeId);
    try {
      const token = await getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/exchanges/access`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ exchangeId })
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to enable exchange access');
      }

      console.log('Enable exchange response:', data);

      setSubscriptionLimits({
        current: data.exchangesEnabled,
        maximum: data.maxExchanges
      });

      await fetchExchangesRef.current();
      return { success: true };
    } catch (err) {
      console.error('Error enabling exchange:', err);
      return { 
        success: false, 
        error: err.message 
      };
    }
  }, [getIdToken]);

  const disableExchange = useCallback(async (exchangeId) => {
    console.log('Disabling exchange:', exchangeId);
    try {
      const token = await getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/exchanges/access/${exchangeId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to disable exchange access');
      }

      console.log('Disable exchange response:', data);

      setSubscriptionLimits(prev => ({
        ...prev,
        current: data.exchangesEnabled
      }));

      await fetchExchangesRef.current();
      return { success: true };
    } catch (err) {
      console.error('Error disabling exchange:', err);
      return { 
        success: false, 
        error: err.message 
      };
    }
  }, [getIdToken]);

  return {
    includedExchanges,
    notIncludedExchanges,
    loading,
    error,
    enableExchange,
    disableExchange,
    refreshExchanges: fetchExchangesRef.current,
    subscriptionLimits
  };
};

export default useExchangeAccess;