import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp, Loader2 } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import useCompanyData from '../hooks/useCompanyData';

const TabButton = ({ active, children, onClick }) => (
  <button
    onClick={onClick}
    className={`px-2 sm:px-4 py-2 text-sm font-medium transition-colors duration-200 ${
      active 
        ? 'text-secondary-DEFAULT border-b-2 border-secondary-DEFAULT' 
        : 'text-light-text-secondary hover:text-light-text-DEFAULT'
    }`}
  >
    {children}
  </button>
);

const LoadingSpinner = () => (
  <div className="h-full w-full flex items-center justify-center">
    <Loader2 className="w-6 h-6 animate-spin text-secondary-DEFAULT" />
  </div>
);

const ErrorDisplay = ({ message }) => (
  <div className="h-full w-full flex items-center justify-center">
    <p className="text-red-500 text-sm">{message}</p>
  </div>
);

const CompanyDataPanel = ({ isVisible, stockCode, isFeedsPanelVisible }) => {
  const [activeTab, setActiveTab] = useState('chart');
  const [isPanelExpanded, setIsPanelExpanded] = useState(true);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const { chartData, fundamentals, profile, loading, error } = useCompanyData(stockCode);

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!isVisible) return null;

  // Determine panel height based on screen size
  const getPanelHeight = () => {
    const { width } = dimensions;
    if (isPanelExpanded) {
      if (width >= 1536) return 'h-[350px]';  // 2xl
      if (width >= 1280) return 'h-[300px]';  // xl
      if (width >= 1024) return 'h-[250px]';  // lg
      if (width >= 768) return 'h-[200px]';   // md
      if (width >= 640) return 'h-[180px]';   // sm
      return 'h-[160px]';                     // default
    }
    return 'h-12';  // collapsed height
  };

  const formatValue = (value, type) => {
    if (!value) return '-';
    
    switch (type) {
      case 'marketCap':
        return typeof value === 'number' 
          ? `$${(value / 1e9).toFixed(2)}B`
          : value;
      case 'percentage':
        return typeof value === 'number'
          ? `${value.toFixed(2)}%`
          : value;
      case 'number':
        return typeof value === 'number'
          ? value.toLocaleString()
          : value;
      default:
        return value;
    }
  };

  const renderContent = () => {
    if (loading) return <LoadingSpinner />;
    if (error) return <ErrorDisplay message={error} />;

    switch (activeTab) {
      case 'chart':
        return (
          <div className="h-full w-full">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={chartData}>
                <XAxis 
                  dataKey="date" 
                  tickFormatter={(date) => new Date(date).toLocaleDateString()}
                />
                <YAxis />
                <Tooltip 
                  labelFormatter={(date) => new Date(date).toLocaleDateString()}
                  formatter={(value) => [`$${value.toFixed(2)}`, 'Price']}
                />
                <Line 
                  type="monotone" 
                  dataKey="price" 
                  stroke="#6366F1" 
                  strokeWidth={2} 
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        );

      case 'fundamentals':
        return (
          <div className="h-full flex items-center">
            <div className="w-full grid grid-cols-2 md:grid-cols-5 gap-2 sm:gap-4 p-2 sm:p-4">
              <div className="text-center">
                <div className="text-xs sm:text-sm text-light-text-tertiary">Market Cap</div>
                <div className="text-sm sm:text-lg font-medium text-light-text-DEFAULT">
                  {formatValue(fundamentals.marketCap, 'marketCap')}
                </div>
              </div>
              <div className="text-center">
                <div className="text-xs sm:text-sm text-light-text-tertiary">P/E Ratio</div>
                <div className="text-sm sm:text-lg font-medium text-light-text-DEFAULT">
                  {formatValue(fundamentals.peRatio, 'number')}
                </div>
              </div>
              <div className="text-center">
                <div className="text-xs sm:text-sm text-light-text-tertiary">EPS</div>
                <div className="text-sm sm:text-lg font-medium text-light-text-DEFAULT">
                  {formatValue(fundamentals.eps, 'number')}
                </div>
              </div>
              <div className="text-center">
                <div className="text-xs sm:text-sm text-light-text-tertiary">Dividend Yield</div>
                <div className="text-sm sm:text-lg font-medium text-light-text-DEFAULT">
                  {formatValue(fundamentals.dividend, 'percentage')}
                </div>
              </div>
              <div className="text-center">
                <div className="text-xs sm:text-sm text-light-text-tertiary">Volume</div>
                <div className="text-sm sm:text-lg font-medium text-light-text-DEFAULT">
                  {formatValue(fundamentals.volume, 'number')}
                </div>
              </div>
            </div>
          </div>
        );

      case 'profile':
        return (
          <div className="h-full flex flex-col justify-center p-2 sm:p-4 space-y-2 sm:space-y-4">
            <p className="text-xs sm:text-sm text-light-text-DEFAULT leading-relaxed max-h-20 overflow-hidden">
              {profile.description || 'No description available.'}
            </p>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2 sm:gap-4">
              <div>
                <div className="text-xs sm:text-sm text-light-text-tertiary">Sector</div>
                <div className="text-xs sm:text-sm text-light-text-DEFAULT">
                  {profile.sector || '-'}
                </div>
              </div>
              <div>
                <div className="text-xs sm:text-sm text-light-text-tertiary">Industry</div>
                <div className="text-xs sm:text-sm text-light-text-DEFAULT">
                  {profile.industry || '-'}
                </div>
              </div>
              <div>
                <div className="text-xs sm:text-sm text-light-text-tertiary">Employees</div>
                <div className="text-xs sm:text-sm text-light-text-DEFAULT">
                  {profile.employees || '-'}
                </div>
              </div>
              <div>
                <div className="text-xs sm:text-sm text-light-text-tertiary">Founded</div>
                <div className="text-xs sm:text-sm text-light-text-DEFAULT">
                  {profile.founded || '-'}
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <div 
      className={`fixed bottom-0 right-0 bg-white border-t border-gray-200 shadow-lg 
                  transition-all duration-300 z-30 ${getPanelHeight()}`}
      style={{ 
        marginLeft: isFeedsPanelVisible ? '328px' : '72px',
        left: 0
      }}
    >
      {/* Header */}
      <div className="h-12 border-b border-gray-200 flex items-center justify-between px-2 sm:px-4 bg-light-bg-secondary/50 backdrop-blur-sm">
        <div className="flex items-center space-x-2 sm:space-x-4">
          <button
            onClick={() => setIsPanelExpanded(!isPanelExpanded)}
            className="p-1 hover:bg-light-bg-tertiary rounded-lg transition-colors duration-200"
          >
            {isPanelExpanded ? (
              <ChevronDown className="w-4 h-4 sm:w-5 sm:h-5 text-light-text-secondary" />
            ) : (
              <ChevronUp className="w-4 h-4 sm:w-5 sm:h-5 text-light-text-secondary" />
            )}
          </button>
          <h3 className="text-xs sm:text-sm font-medium text-light-text-DEFAULT">
            Company Data
          </h3>
        </div>

        {isPanelExpanded && (
          <div className="flex items-center">
            <div className="flex space-x-1 sm:space-x-2">
              <TabButton 
                active={activeTab === 'chart'} 
                onClick={() => setActiveTab('chart')}
              >
                Price Chart
              </TabButton>
              <TabButton 
                active={activeTab === 'fundamentals'} 
                onClick={() => setActiveTab('fundamentals')}
              >
                Fundamentals
              </TabButton>
              <TabButton 
                active={activeTab === 'profile'} 
                onClick={() => setActiveTab('profile')}
              >
                Profile
              </TabButton>
            </div>
          </div>
        )}
      </div>

      {/* Content */}
      {isPanelExpanded && (
        <div className="h-[calc(100%-48px)]">
          {renderContent()}
        </div>
      )}
    </div>
  );
};

export default CompanyDataPanel;