import React, { useState } from 'react';
import { formatDateTime } from '../utils/formatters';
import { Loader2 } from 'lucide-react';

const ReportsTable = ({ 
  reports, 
  loading, 
  error, 
  onLoadMore,
  hasMore 
}) => {
  const [expandedReports, setExpandedReports] = useState(new Set());

  const toggleReportExpansion = (reportId) => {
    const newExpandedReports = new Set(expandedReports);
    if (expandedReports.has(reportId)) {
      newExpandedReports.delete(reportId);
    } else {
      newExpandedReports.add(reportId);
    }
    setExpandedReports(newExpandedReports);
  };

  const formatExchangeName = (code) => {
    const names = {
      'hongkong': 'Hong Kong',
      'shanghai': 'Shanghai',
      'shenzhen': 'Shenzhen'
    };
    return names[code] || code;
  };

  if (error) {
    return (
      <div className="bg-red-50 p-4 rounded-lg">
        <div className="flex flex-col items-center text-center">
          <p className="text-red-600 mb-2">Error loading reports: {error}</p>
          <button
            onClick={onLoadMore}
            className="px-4 py-2 bg-red-100 text-red-700 rounded-lg hover:bg-red-200 transition-colors"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-4">
      <div className="bg-white rounded-lg shadow-sm overflow-hidden">
        <table className="w-full">
          <thead>
            <tr className="bg-white border-b border-gray-200">
              <th className="px-4 py-2 text-left text-xs font-medium text-light-text-secondary uppercase tracking-wider">
                Exchange
              </th>
              <th className="px-4 py-2 text-left text-xs font-medium text-light-text-secondary uppercase tracking-wider">
                Date
              </th>
              <th className="px-4 py-2 text-left text-xs font-medium text-light-text-secondary uppercase tracking-wider">
                Filings Analyzed
              </th>
              <th className="px-4 py-2 text-left text-xs font-medium text-light-text-secondary uppercase tracking-wider">
                Published
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100">
            {reports.length === 0 && !loading ? (
              <tr>
                <td colSpan={4} className="px-4 py-4 text-center text-light-text-tertiary">
                  No reports found
                </td>
              </tr>
            ) : (
              reports.map((report) => {
                const isExpanded = expandedReports.has(report.id);
                
                return (
                  <React.Fragment key={report.id}>
                    <tr 
                      className="hover:bg-gray-50 cursor-pointer"
                      onClick={() => toggleReportExpansion(report.id)}
                    >
                      <td className="px-4 py-2">
                        <span className="inline-flex text-sm font-medium">
                          {formatExchangeName(report.exchange_code)}
                        </span>
                      </td>
                      <td className="px-4 py-2">
                        <span className="text-sm">
                          {new Date(report.report_date).toLocaleDateString()}
                        </span>
                      </td>
                      <td className="px-4 py-2">
                        <span className="text-sm">
                          {report.important_filings_count} / {report.total_filings_analyzed}
                        </span>
                      </td>
                      <td className="px-4 py-2">
                        <span className="text-sm text-light-text-tertiary">
                          {formatDateTime(report.published_at)}
                        </span>
                      </td>
                    </tr>
                    {isExpanded && (
                      <tr>
                        <td colSpan={4} className="px-4 py-4 bg-gray-50">
                          <div className="prose max-w-none">
                            <div 
                              className="text-sm text-light-text-DEFAULT"
                              dangerouslySetInnerHTML={{ __html: report.report_content }}
                            />
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })
            )}
          </tbody>
        </table>
      </div>

      {(reports.length > 0 || loading) && (
        <div className="h-16 flex items-center justify-center">
          {loading ? (
            <Loader2 className="w-6 h-6 animate-spin text-secondary-DEFAULT" />
          ) : hasMore ? (
            <button 
              onClick={onLoadMore}
              className="text-sm text-secondary-DEFAULT hover:text-secondary-accent"
            >
              Load more
            </button>
          ) : (
            <span className="text-sm text-light-text-tertiary">
              No more reports to load
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default ReportsTable;