import React from 'react';
import { Dialog } from '@headlessui/react';
import { AlertTriangle } from 'lucide-react';

const DeleteKeywordAlertDialog = ({ isOpen, onClose, keyword, onDelete }) => {
  if (!keyword) return null;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="fixed z-50 inset-0 overflow-y-auto"
    >
      <div className="flex items-center justify-center min-h-screen px-4">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        
        <div className="relative bg-white rounded-lg max-w-md w-full p-6">
          <div className="flex items-center mb-6">
            <div className="mr-4">
              <AlertTriangle className="w-6 h-6 text-red-600" />
            </div>
            <Dialog.Title className="text-lg font-medium text-gray-900">
              Delete Keyword Alert
            </Dialog.Title>
          </div>

          <div className="mb-6">
            <p className="text-sm text-gray-500">
              Are you sure you want to delete the keyword alert for "{keyword.keyword}"? 
              This action cannot be undone.
            </p>
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500 transition-colors"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={onDelete}
              className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-md 
                       transition-colors duration-200 text-sm font-medium
                       focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Delete Alert
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteKeywordAlertDialog;