export const exchanges = {
  'Asia Pacific': [
    { id: 'sse', label: 'Shanghai Stock Exchange', pattern: 'SSE:%' },
    { id: 'szse', label: 'Shenzhen Stock Exchange', pattern: 'SZSE:%' },
    { id: 'hkex', label: 'Hong Kong Exchange', pattern: 'HKEX:%' },
    { id: 'jse', label: 'Japan Exchange', pattern: 'JPX:%' },
    { id: 'twse', label: 'Taiwan Stock Exchange', pattern: 'TWSE:%' },
  ],
  'Americas': [
    { id: 'nyse', label: 'New York Stock Exchange', pattern: 'NYSE:%' },
    { id: 'nasdaq', label: 'NASDAQ', pattern: 'NASDAQ:%' },
  ],
  'Europe': [
    { id: 'lse', label: 'London Stock Exchange', pattern: 'LSE:%' },
    { id: 'euronext', label: 'Euronext', pattern: 'EURONEXT:%' },
    { id: 'deutsche', label: 'Deutsche Börse', pattern: 'DB:%' },
  ]
};