import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown, X } from 'lucide-react';
import { exchanges } from '../../utils/exchanges';
import { useKeywordAlerts } from '../../hooks/useKeywordAlerts';

// Filing types data
const filingTypes = {
  'Financial Reports': [
    { id: 'annual', label: 'Annual Report' },
    { id: 'interim', label: 'Interim Report' },
    { id: 'quarterly', label: 'Quarterly Report' }
  ],
  'Corporate Actions': [
    { id: 'dividend', label: 'Dividend Announcement' },
    { id: 'merger', label: 'Merger & Acquisition' },
    { id: 'restructure', label: 'Restructuring' }
  ],
  'Governance': [
    { id: 'director', label: 'Director Changes' },
    { id: 'meeting', label: 'Meeting Notices' },
    { id: 'voting', label: 'Voting Results' }
  ]
};

// Suggested keywords organized by categories
const suggestedKeywords = {
  'Financial': [
    'acquisition', 'merger', 'dividend', 'profit', 'loss', 'revenue',
    'debt', 'financing', 'investment', 'partnership'
  ],
  'Industries': [
    'airline', 'banking', 'technology', 'energy', 'lithium', 'mining',
    'healthcare', 'retail', 'automotive', 'semiconductor'
  ],
  'Corporate Actions': [
    'restructuring', 'layoff', 'expansion', 'contract', 'lawsuit',
    'resignation', 'appointment', 'spinoff', 'bankruptcy'
  ],
  'Market Activity': [
    'IPO', 'delisting', 'trading halt', 'stock split', 'buyback',
    'privatization', 'tender offer'
  ]
};

const FilterBar = ({ 
  selectedExchanges = [], 
  onExchangeChange,
  selectedKeywords = [],
  onKeywordChange,
  selectedFilingTypes = [],
  onFilingTypeChange,
  showExchangeFilter = true
}) => {
  const [isExchangeDropdownOpen, setIsExchangeDropdownOpen] = useState(false);
  const [isFilingTypeDropdownOpen, setIsFilingTypeDropdownOpen] = useState(false);
  const [isKeywordDropdownOpen, setIsKeywordDropdownOpen] = useState(false);
  
  const { keywordAlerts, loading: keywordsLoading } = useKeywordAlerts();
  
  const exchangeDropdownRef = useRef(null);
  const exchangeButtonRef = useRef(null);
  const filingTypeDropdownRef = useRef(null);
  const filingTypeButtonRef = useRef(null);
  const keywordDropdownRef = useRef(null);
  const keywordButtonRef = useRef(null);

  // Get user's keywords from alerts
  const userKeywords = keywordAlerts.map(alert => alert.keyword);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Handle Exchange dropdown
      if (exchangeDropdownRef.current && 
          !exchangeDropdownRef.current.contains(event.target) &&
          !exchangeButtonRef.current.contains(event.target)) {
        setIsExchangeDropdownOpen(false);
      }
      // Handle Filing Type dropdown
      if (filingTypeDropdownRef.current && 
          !filingTypeDropdownRef.current.contains(event.target) &&
          !filingTypeButtonRef.current.contains(event.target)) {
        setIsFilingTypeDropdownOpen(false);
      }
      // Handle Keyword dropdown
      if (keywordDropdownRef.current && 
          !keywordDropdownRef.current.contains(event.target) &&
          !keywordButtonRef.current.contains(event.target)) {
        setIsKeywordDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const toggleExchange = (exchangeId) => {
    if (selectedExchanges.includes(exchangeId)) {
      onExchangeChange(selectedExchanges.filter(id => id !== exchangeId));
    } else {
      onExchangeChange([...selectedExchanges, exchangeId]);
    }
  };

  const toggleFilingType = (typeId) => {
    if (selectedFilingTypes.includes(typeId)) {
      onFilingTypeChange(selectedFilingTypes.filter(id => id !== typeId));
    } else {
      onFilingTypeChange([...selectedFilingTypes, typeId]);
    }
  };

  const toggleKeyword = (keyword) => {
    if (selectedKeywords.includes(keyword)) {
      onKeywordChange(selectedKeywords.filter(k => k !== keyword));
    } else {
      onKeywordChange([...selectedKeywords, keyword]);
    }
  };

  const getSelectedExchangeLabels = () => {
    const selected = [];
    Object.values(exchanges).forEach(region => {
      region.forEach(exchange => {
        if (selectedExchanges.includes(exchange.id)) {
          selected.push(exchange.label);
        }
      });
    });
    return selected;
  };

  const getSelectedFilingTypeLabels = () => {
    const selected = [];
    Object.values(filingTypes).forEach(category => {
      category.forEach(type => {
        if (selectedFilingTypes.includes(type.id)) {
          selected.push(type.label);
        }
      });
    });
    return selected;
  };

  return (
    <div className="mb-4 space-y-2 pl-4">
      <div className="flex items-center gap-2">
        {/* Exchange Filter - Only show if showExchangeFilter is true */}
        {showExchangeFilter && (
          <div className="relative">
            <button
              ref={exchangeButtonRef}
              onClick={() => setIsExchangeDropdownOpen(!isExchangeDropdownOpen)}
              className="inline-flex items-center px-3 py-1.5 bg-[#C1C1C1]/20 hover:bg-[#C1C1C1]/30 
                text-[#302d4c] rounded-lg border border-transparent hover:border-[#C1C1C1]/40 
                transition-all duration-200"
            >
              <span className="text-sm font-medium">Exchanges</span>
              <ChevronDown className={`ml-1.5 h-4 w-4 transition-transform duration-200 
                ${isExchangeDropdownOpen ? 'rotate-180' : ''}`} 
              />
            </button>
  
            {isExchangeDropdownOpen && (
              <div
                ref={exchangeDropdownRef}
                className="absolute left-0 mt-1 w-72 rounded-lg bg-white shadow-lg ring-1 ring-black/5 z-50"
              >
                <div className="max-h-96 overflow-y-auto rounded-lg [scrollbar-width:thin] 
                  [scrollbar-color:#E5E7EB_transparent] 
                  [&::-webkit-scrollbar]:w-1.5 
                  [&::-webkit-scrollbar-track]:bg-transparent
                  [&::-webkit-scrollbar-thumb]:rounded-full
                  [&::-webkit-scrollbar-thumb]:bg-gray-300"
                >
                  {Object.entries(exchanges).map(([region, exchangeList]) => (
                    <div key={region} className="px-1 py-1">
                      <div className="px-3 py-2 text-sm font-semibold text-[#302d4c] sticky top-0 bg-white/95 backdrop-blur-sm">
                        {region}
                      </div>
                      <div className="space-y-0.5">
                        {exchangeList.map(exchange => (
                          <button
                            key={exchange.id}
                            onClick={() => toggleExchange(exchange.id)}
                            className={`w-full text-left px-3 py-2 rounded-md text-sm transition-colors duration-150 
                              ${selectedExchanges.includes(exchange.id)
                                ? 'bg-[#C1C1C1]/20 text-[#302d4c] hover:bg-[#C1C1C1]/30'
                                : 'text-[#302d4c] hover:bg-gray-100'
                              }`}
                          >
                            {exchange.label}
                          </button>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}

        {/* Filing Type Filter */}
        <div className="relative">
          <button
            ref={filingTypeButtonRef}
            onClick={() => setIsFilingTypeDropdownOpen(!isFilingTypeDropdownOpen)}
            className="inline-flex items-center px-3 py-1.5 bg-[#C1C1C1]/20 hover:bg-[#C1C1C1]/30 
              text-[#302d4c] rounded-lg border border-transparent hover:border-[#C1C1C1]/40 
              transition-all duration-200"
          >
            <span className="text-sm font-medium">Filing Type</span>
            <ChevronDown className={`ml-1.5 h-4 w-4 transition-transform duration-200 
              ${isFilingTypeDropdownOpen ? 'rotate-180' : ''}`} 
            />
          </button>

          {isFilingTypeDropdownOpen && (
            <div
              ref={filingTypeDropdownRef}
              className="absolute left-0 mt-1 w-72 rounded-lg bg-white shadow-lg ring-1 ring-black/5 z-50"
            >
              <div className="max-h-96 overflow-y-auto rounded-lg [scrollbar-width:thin] 
                [scrollbar-color:#E5E7EB_transparent] 
                [&::-webkit-scrollbar]:w-1.5 
                [&::-webkit-scrollbar-track]:bg-transparent
                [&::-webkit-scrollbar-thumb]:rounded-full
                [&::-webkit-scrollbar-thumb]:bg-gray-300"
              >
                {Object.entries(filingTypes).map(([category, types]) => (
                  <div key={category} className="px-1 py-1">
                    <div className="px-3 py-2 text-sm font-semibold text-[#302d4c] sticky top-0 bg-white/95 backdrop-blur-sm">
                      {category}
                    </div>
                    <div className="space-y-0.5">
                      {types.map(type => (
                        <button
                          key={type.id}
                          onClick={() => toggleFilingType(type.id)}
                          className={`w-full text-left px-3 py-2 rounded-md text-sm transition-colors duration-150 
                            ${selectedFilingTypes.includes(type.id)
                              ? 'bg-[#C1C1C1]/20 text-[#302d4c] hover:bg-[#C1C1C1]/30'
                              : 'text-[#302d4c] hover:bg-gray-100'
                            }`}
                        >
                          {type.label}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Keywords Filter */}
        <div className="relative">
          <button
            ref={keywordButtonRef}
            onClick={() => setIsKeywordDropdownOpen(!isKeywordDropdownOpen)}
            className="inline-flex items-center px-3 py-1.5 bg-[#C1C1C1]/20 hover:bg-[#C1C1C1]/30 
              text-[#302d4c] rounded-lg border border-transparent hover:border-[#C1C1C1]/40 
              transition-all duration-200"
          >
            <span className="text-sm font-medium">Keywords</span>
            <ChevronDown className={`ml-1.5 h-4 w-4 transition-transform duration-200 
              ${isKeywordDropdownOpen ? 'rotate-180' : ''}`} 
            />
          </button>

          {isKeywordDropdownOpen && (
            <div
              ref={keywordDropdownRef}
              className="absolute left-0 mt-1 w-96 rounded-lg bg-white shadow-lg ring-1 ring-black/5 z-50"
            >
              <div className="max-h-96 overflow-y-auto rounded-lg [scrollbar-width:thin] 
                [scrollbar-color:#E5E7EB_transparent] 
                [&::-webkit-scrollbar]:w-1.5 
                [&::-webkit-scrollbar-track]:bg-transparent
                [&::-webkit-scrollbar-thumb]:rounded-full
                [&::-webkit-scrollbar-thumb]:bg-gray-300"
              >
                {/* User's Keywords Section */}
                {userKeywords.length > 0 && (
                  <div className="px-1 py-1">
                    <div className="px-3 py-2 text-sm font-semibold text-[#302d4c] sticky top-0 bg-white/95 backdrop-blur-sm">
                      Your Keywords
                    </div>
                    <div className="p-2 flex flex-wrap gap-2">
                      {userKeywords.map(keyword => (
                        <button
                          key={`user-${keyword}`}
                          onClick={() => toggleKeyword(keyword)}
                          className={`px-3 py-1 rounded-full text-sm transition-colors duration-150 
                            ${selectedKeywords.includes(keyword)
                              ? 'bg-secondary-accent text-white hover:bg-secondary-accent/90'
                              : 'bg-[#C1C1C1]/20 text-[#302d4c] hover:bg-[#C1C1C1]/30'
                            }`}
                        >
                          {keyword}
                        </button>
                      ))}
                    </div>
                  </div>
                )}

                {/* Suggested Keywords Sections */}
                {Object.entries(suggestedKeywords).map(([category, keywords]) => (
                  <div key={category} className="px-1 py-1">
                    <div className="px-3 py-2 text-sm font-semibold text-[#302d4c] sticky top-0 bg-white/95 backdrop-blur-sm">
                      {category}
                    </div>
                    <div className="p-2 flex flex-wrap gap-2">
                      {keywords.map(keyword => (
                        <button
                          key={`suggested-${keyword}`}
                          onClick={() => toggleKeyword(keyword)}
                          className={`px-3 py-1 rounded-full text-sm transition-colors duration-150 
                            ${selectedKeywords.includes(keyword)
                              ? 'bg-secondary-accent text-white hover:bg-secondary-accent/90'
                              : 'bg-[#C1C1C1]/20 text-[#302d4c] hover:bg-[#C1C1C1]/30'
                            }`}
                        >
                          {keyword}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Active Filters Display */}
      <div className="flex flex-wrap items-center gap-2">
        {/* Exchange Filters - Only show if showExchangeFilter is true */}
        {showExchangeFilter && getSelectedExchangeLabels().map(label => (
          <div
            key={`exchange-${label}`}
            className="inline-flex items-center gap-1 px-2.5 py-1 bg-[#C1C1C1]/20 text-[#302d4c] 
              rounded hover:bg-[#C1C1C1]/30 transition-colors duration-150"
          >
            <span className="text-sm">{label}</span>
            <button
              onClick={() => {
                const exchangeId = Object.values(exchanges)
                  .flat()
                  .find(e => e.label === label)?.id;
                if (exchangeId) {
                  toggleExchange(exchangeId);
                }
              }}
              className="ml-1 p-0.5 hover:bg-[#C1C1C1]/40 rounded-sm 
                transition-colors duration-150"
              aria-label={`Remove ${label} filter`}
            >
              <X className="h-3 w-3" />
            </button>
          </div>
        ))}

        {/* Filing Type Filters */}
        {getSelectedFilingTypeLabels().map(label => (
          <div
            key={`filing-${label}`}
            className="inline-flex items-center gap-1 px-2.5 py-1 bg-[#C1C1C1]/20 text-[#302d4c] 
              rounded hover:bg-[#C1C1C1]/30 transition-colors duration-150"
          >
            <span className="text-sm">{label}</span>
            <button
              onClick={() => {
                const typeId = Object.values(filingTypes)
                  .flat()
                  .find(t => t.label === label)?.id;
                if (typeId) {
                  toggleFilingType(typeId);
                }
              }}
              className="ml-1 p-0.5 hover:bg-[#C1C1C1]/40 rounded-sm 
                transition-colors duration-150"
              aria-label={`Remove ${label} filter`}
            >
              <X className="h-3 w-3" />
            </button>
          </div>
        ))}

        {/* Keyword Filters */}
        {selectedKeywords.map(keyword => (
          <div
            key={`filter-${keyword}`}
            className="inline-flex items-center gap-1 px-2.5 py-1 bg-[#C1C1C1]/20 text-[#302d4c] 
              rounded hover:bg-[#C1C1C1]/30 transition-colors duration-150"
          >
            <span className="text-sm">{keyword}</span>
            <button
              onClick={() => toggleKeyword(keyword)}
              className="ml-1 p-0.5 hover:bg-[#C1C1C1]/40 rounded-sm 
                transition-colors duration-150"
              aria-label={`Remove ${keyword} filter`}
            >
              <X className="h-3 w-3" />
            </button>
          </div>
        ))}

        {/* Clear All Button */}
        {(
          (showExchangeFilter && selectedExchanges.length > 0) || 
          selectedKeywords.length > 0 || 
          selectedFilingTypes.length > 0
        ) && (
          <button
            onClick={() => {
              if (showExchangeFilter) {
                onExchangeChange([]);
              }
              onKeywordChange([]);
              onFilingTypeChange([]);
            }}
            className="text-xs text-[#302d4c] hover:text-[#302d4c]/70 transition-colors duration-150"
          >
            Clear all
          </button>
        )}
      </div>
    </div>
  );
};

export default FilterBar;