import React, { createContext, useContext, useState, useCallback } from 'react';

const FilingContext = createContext();

export function FilingProvider({ children }) {
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  
  const triggerRefresh = useCallback(() => {
    setRefreshTrigger(prev => prev + 1);
  }, []);

  return (
    <FilingContext.Provider value={{ 
      refreshTrigger,
      triggerRefresh
    }}>
      {children}
    </FilingContext.Provider>
  );
}

export function useFilingContext() {
  const context = useContext(FilingContext);
  if (!context) {
    throw new Error('useFilingContext must be used within a FilingProvider');
  }
  return context;
}