import React, { useState } from 'react';
import { Trash2, Mail, X } from 'lucide-react';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg max-w-md w-full m-4 p-6">
        {children}
      </div>
    </div>
  );
};

const DeleteAccountSection = ({ onDeleteAccount }) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [emailSent, setEmailSent] = useState(false);

  const handleDelete = async () => {
    if (!password.trim()) {
      setError('Please enter your password to confirm deletion');
      return;
    }

    setIsDeleting(true);
    setError(null);
    try {
      await onDeleteAccount(password);
      setEmailSent(true);
    } catch (err) {
      setError(err.message);
      setIsDeleting(false);
    }
  };

  if (emailSent) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
        <div className="text-center space-y-4">
          <div className="flex justify-center">
            <Mail className="h-12 w-12 text-blue-500" />
          </div>
          <h2 className="text-lg font-semibold">Check Your Email</h2>
          <p className="text-gray-600">
            We've sent you an email with the final deletion confirmation.
            Please check your inbox and follow the instructions.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
      <div className="space-y-4">
        <h2 className="text-lg font-semibold text-red-600">Delete Account</h2>
        <p className="text-gray-600">
          Once you delete your account, all your data will be permanently removed. 
          This action cannot be undone.
        </p>
        
        {error && (
          <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-lg">
            {error}
          </div>
        )}

        <button
          onClick={() => setShowConfirmDialog(true)}
          className="flex items-center gap-2 px-4 py-2 text-sm text-white bg-red-600 rounded-lg hover:bg-red-700 transition-colors duration-200"
          disabled={isDeleting}
        >
          <Trash2 className="w-4 h-4" />
          {isDeleting ? 'Deleting Account...' : 'Delete Account'}
        </button>
      </div>

      <Modal 
        isOpen={showConfirmDialog} 
        onClose={() => setShowConfirmDialog(false)}
      >
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-semibold">Are you absolutely sure?</h3>
            <button 
              onClick={() => setShowConfirmDialog(false)}
              className="text-gray-500 hover:text-gray-700"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
          
          <p className="text-gray-600">
            This action cannot be undone. This will permanently delete your account
            and remove all your data including:
          </p>
          
          <ul className="list-disc list-inside space-y-1 text-gray-600">
            <li>All your saved filings</li>
            <li>Your watchlists and notifications</li>
            <li>Your profile information</li>
            <li>Your preferences and settings</li>
          </ul>

          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Please enter your password to confirm deletion
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-red-500 focus:border-transparent"
              placeholder="Enter your password"
            />
          </div>

          <div className="flex justify-end gap-3 mt-6">
            <button
              onClick={() => setShowConfirmDialog(false)}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors duration-200"
            >
              Cancel
            </button>
            <button
              onClick={handleDelete}
              className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 transition-colors duration-200"
              disabled={isDeleting}
            >
              {isDeleting ? 'Deleting...' : 'Delete my account'}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteAccountSection;