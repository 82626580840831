// Role-based recommendations
const ROLE_RECOMMENDATIONS = {
  investor: {
    features: ['Set up price alerts', 'Create custom watchlists', 'Enable real-time notifications'],
    suggested_filings: ['Financial reports', 'Dividend announcements', 'Major transactions']
  },
  analyst: {
    features: ['Export data to Excel', 'Compare multiple companies', 'Advanced search filters'],
    suggested_filings: ['Detailed financial statements', 'Industry analysis', 'Regulatory filings']
  },
  researcher: {
    features: ['Full-text search', 'Historical data access', 'Bulk download'],
    suggested_filings: ['Annual reports', 'ESG reports', 'Corporate governance documents']
  },
  journalist: {
    features: ['Latest announcements feed', 'Company news alerts', 'Quick fact sheets'],
    suggested_filings: ['Press releases', 'Material events', 'Corporate announcements']
  },
  student: {
    features: ['Educational resources', 'Basic analysis tools', 'Simplified interface'],
    suggested_filings: ['Basic financial reports', 'Company profiles', 'Industry overviews']
  },
  other: {
    features: ['Customizable dashboard', 'Basic analysis tools', 'Filing notifications'],
    suggested_filings: ['Company announcements', 'Financial reports', 'Regulatory filings']
  }
};

/**
 * Fetches and filters relevant watchlists based on user preferences
 * @param {Object} preferences User preferences from onboarding
 * @param {string[]} preferences.industries Selected industry IDs
 * @param {string[]} preferences.exchanges Selected exchange IDs
 * @param {string} preferences.role Selected role
 * @param {Function} getIdToken Function to get authentication token
 * @returns {Promise<Object>} Personalized recommendations
 */
export const generateRecommendations = async (preferences, getIdToken) => {
  const { industries, exchanges, role } = preferences;
  
  try {
    // Fetch featured watchlists from the API
    const token = await getIdToken();
    const response = await fetch(`${process.env.REACT_APP_API_URL}/featured-watchlists`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch featured watchlists');
    }

    const featuredWatchlists = await response.json();

    // Filter watchlists based on user's selected industries and exchanges
    const suggestedWatchlists = featuredWatchlists.filter(watchlist => {
      // Check if watchlist matches any selected industry
      const matchesIndustry = industries.some(industry => 
        watchlist.tags?.includes(industry) || 
        watchlist.industries?.includes(industry)
      );

      // Check if watchlist contains stocks from selected exchanges
      const matchesExchange = watchlist.stocks?.some(stock =>
        exchanges.some(exchange => stock.startsWith(exchange))
      );

      return matchesIndustry && matchesExchange;
    });

    // Map watchlists to the expected format
    const processedWatchlists = suggestedWatchlists.map(watchlist => ({
      name: watchlist.title,
      description: watchlist.description,
      stocks: watchlist.stocks || [],
      id: watchlist.id
    }));

    // Get role-based recommendations
    const roleRecommendations = ROLE_RECOMMENDATIONS[role] || ROLE_RECOMMENDATIONS.other;

    return {
      watchlists: processedWatchlists,
      features: roleRecommendations.features,
      suggested_filings: roleRecommendations.suggested_filings,
      exchanges: exchanges
    };
  } catch (error) {
    console.error('Error generating recommendations:', error);
    // Return role-based recommendations even if watchlist fetching fails
    return {
      watchlists: [],
      features: ROLE_RECOMMENDATIONS[role]?.features || ROLE_RECOMMENDATIONS.other.features,
      suggested_filings: ROLE_RECOMMENDATIONS[role]?.suggested_filings || ROLE_RECOMMENDATIONS.other.suggested_filings,
      exchanges: exchanges
    };
  }
};