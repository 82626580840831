import React, { useState } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import Sidebar from './components/Sidebar';
import FeedsPanel from './components/FeedsPanel';
import CompanySearchBar from './components/CompanySearchBar';

// Views
import DashboardView from './views/DashboardView';
import FeedsView from './views/FeedsView';
import AlertsView from './views/AlertsView';
import SavedView from './views/SavedView';
import ExchangeHubView from './views/ExchangeHubView';
import TeamView from './views/TeamView';
import SettingsView from './views/SettingsView';
import OnboardingView from './views/OnboardingView';
import FilingDetail from './views/FilingDetail';
import DiscoveryView from './views/DiscoveryView';
import CompanyFilingsView from './views/CompanyFilingsView';
import PlansView from './views/PlansView';
import ProfileView from './views/ProfileView';
import InviteAcceptView from './views/InviteAcceptView';
import AuthModal from './components/auth/AuthModal';

const AppContainer = () => {
  const [isFeedsPanelVisible, setIsFeedsPanelVisible] = useState(true);
  const { isAuthenticated, loading, user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  // If authenticated but hasn't completed onboarding, only show onboarding
  if (isAuthenticated && !user?.has_completed_onboarding) {
    return (
      <Routes>
        <Route path="/onboarding" element={<OnboardingView />} />
        <Route path="*" element={<Navigate to="/onboarding" replace />} />
      </Routes>
    );
  }

  // Special case for invite acceptance
  if (location.pathname.startsWith('/invite/')) {
    return (
      <Routes>
        <Route path="/invite/:id" element={<InviteAcceptView />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  }

  const handleDashboardClick = () => {
    if (!isFeedsPanelVisible) {
      setIsFeedsPanelVisible(true);
    }
    // Navigate to dashboard if not already there
    if (location.pathname !== '/') {
      navigate('/');
    }
  };

  // Calculate main content margin based on sidebar and feeds panel state
  const getContentMargin = () => {
    let margin = 72; // Sidebar width (18rem = 72px)
    if (isFeedsPanelVisible) margin += 256; // FeedsPanel width (64rem = 256px)
    return `${margin}px`;
  };

  return (
    <div className="flex h-screen bg-gray-50">
      {/* Sidebar - always in compact mode */}
      <Sidebar 
        onDashboardClick={handleDashboardClick}
      />

      {/* Feeds Panel */}
      <FeedsPanel 
        isVisible={isFeedsPanelVisible}
        onClose={() => setIsFeedsPanelVisible(false)}
      />

      {/* Main Content */}
      <div 
        className="flex-1 flex flex-col transition-all duration-300 relative z-0" // Add z-0
        style={{ marginLeft: getContentMargin() }}
      >
        {/* Header */}
        <header className="h-16 bg-white border-b border-gray-200 flex items-center px-4 sticky top-0 z-50">
          <div className="flex-1 relative">
            <CompanySearchBar />
          </div>
        </header>

        {/* Main Content Area */}
        <main className="flex-1 overflow-y-auto relative z-0"> 
          <Routes>
            <Route path="/" element={<DashboardView />} />
            <Route path="/feeds/:watchlistId" element={<FeedsView />} />
            <Route path="/alerts" 
              element={<AlertsView isFeedsPanelVisible={isFeedsPanelVisible} />} 
            />
            <Route path="/saved" element={<SavedView />} />
            <Route path="/discover" element={<DiscoveryView />} />
            <Route path="/exchange-hub" element={<ExchangeHubView />} />
            <Route path="/team" element={<TeamView />} />
            <Route path="/settings" element={<SettingsView />} />
            <Route path="/plans" element={<PlansView />} />
            <Route path="/profile" element={<ProfileView />} />
            <Route path="/filing/:filingId" element={<FilingDetail />} />
            <Route 
              path="/company/:stockCode" 
              element={<CompanyFilingsView isFeedsPanelVisible={isFeedsPanelVisible} />} 
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </main>
      </div>

      {/* Auth Modal - Always open when not authenticated */}
      <AuthModal 
        isOpen={!loading && !isAuthenticated}
        onClose={() => {/* Prevent closing */}}
      />
    </div>
  );
};

export default AppContainer;