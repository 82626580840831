import React from 'react';
import { Trash2, ChevronUp, ChevronDown } from 'lucide-react';
import ExchangeAlertTable from './ExchangeAlertTable';

const KeywordCard = ({
  keyword,
  isExpanded,
  onToggleExpand,
  onDelete,
  onEdit,
  onUpdateNotifications,
  onUpdateBatchNotifications,
  onDeleteExchanges,  
  isFeedsPanelVisible
}) => {
  return (
    <div className="p-4">
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-lg font-medium text-gray-900">
            {keyword.keyword}
          </h3>
          <p className="text-sm text-gray-500 mt-0.5">
            {keyword.exchanges?.length || 0} exchanges monitored
          </p>
        </div>
        <div className="flex items-center space-x-6">
          <button
            onClick={() => onToggleExpand(keyword)}
            className="text-secondary hover:text-secondary-accent text-sm font-medium 
                     transition-colors duration-200 flex items-center space-x-2"
          >
            <span>{isExpanded ? 'Hide Details' : 'View Details'}</span>
            {isExpanded ? 
              <ChevronUp className="w-4 h-4" /> : 
              <ChevronDown className="w-4 h-4" />
            }
          </button>
          
          <button
            onClick={() => onEdit(keyword)}
            className="text-secondary hover:text-secondary-accent text-sm font-medium 
                     transition-colors duration-200"
          >
            Edit
          </button>
          
          <button
            onClick={() => onDelete(keyword)}
            className="text-gray-400 hover:text-red-600 text-sm 
                     transition-colors duration-200 flex items-center space-x-2"
          >
            <Trash2 className="w-4 h-4" />
            <span>Delete</span>
          </button>
        </div>
      </div>
      
      {isExpanded && (
        <div className="mt-4">
          <ExchangeAlertTable
            keyword={keyword}
            onUpdateNotifications={onUpdateNotifications}
            onUpdateBatchNotifications={onUpdateBatchNotifications}
            onDeleteExchanges={onDeleteExchanges}  
            isFeedsPanelVisible={isFeedsPanelVisible}
          />
        </div>
      )}
    </div>
  );
};

export default KeywordCard;