import React, { useMemo } from 'react';

// Utility function to split text into segments
const getHighlightedSegments = (text, keywords) => {
  if (!keywords || keywords.length === 0 || !text) {
    return [{ text, highlight: false }];
  }

  // Convert text to lowercase for case-insensitive matching
  const lowerText = text.toLowerCase();
  
  // Sort keywords by length (longest first) to handle overlapping matches
  const sortedKeywords = [...keywords].sort((a, b) => b.length - a.length);
  
  // Find all matches with their positions
  const matches = [];
  sortedKeywords.forEach(keyword => {
    const lowerKeyword = keyword.toLowerCase();
    let pos = lowerText.indexOf(lowerKeyword);
    while (pos !== -1) {
      matches.push({
        start: pos,
        end: pos + keyword.length,
        keyword: text.slice(pos, pos + keyword.length) // Use original case from text
      });
      pos = lowerText.indexOf(lowerKeyword, pos + 1);
    }
  });

  // Sort matches by position
  matches.sort((a, b) => a.start - b.start);

  // Merge overlapping matches
  const mergedMatches = matches.reduce((acc, curr) => {
    if (acc.length === 0) {
      acc.push(curr);
      return acc;
    }

    const last = acc[acc.length - 1];
    if (curr.start <= last.end) {
      last.end = Math.max(last.end, curr.end);
    } else {
      acc.push(curr);
    }
    return acc;
  }, []);

  // Build segments
  const segments = [];
  let lastEnd = 0;

  mergedMatches.forEach(match => {
    if (match.start > lastEnd) {
      segments.push({
        text: text.slice(lastEnd, match.start),
        highlight: false
      });
    }
    segments.push({
      text: text.slice(match.start, match.end),
      highlight: true
    });
    lastEnd = match.end;
  });

  if (lastEnd < text.length) {
    segments.push({
      text: text.slice(lastEnd),
      highlight: false
    });
  }

  return segments;
};

const HighlightedTitle = ({ title, keywords, hoveredTitle, originalTitle, translatedTitle }) => {
  const textToHighlight = hoveredTitle === originalTitle ? title : (translatedTitle || title);
  
  const segments = useMemo(() => 
    getHighlightedSegments(textToHighlight, keywords),
    [textToHighlight, keywords]
  );

  return (
    <span className="inline">
      {segments.map((segment, index) => (
        segment.highlight ? (
          <span 
            key={index} 
            className="bg-secondary-light px-0.5 rounded-sm"
          >
            {segment.text}
          </span>
        ) : (
          <span key={index}>{segment.text}</span>
        )
      ))}
    </span>
  );
};

export default HighlightedTitle;