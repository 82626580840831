import React, { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { X } from 'lucide-react';

const AVAILABLE_EXCHANGES = [
  'Tokyo Stock Exchange',
  'Stock Exchange of Thailand',
  'Hong Kong Stock Exchange',
  'National Stock Exchange of India',
  'Bombay Stock Exchange',
  'Oslo Stock Exchange',
  'Shanghai Stock Exchange',
  'Shenzhen Stock Exchange'
];

const CreateKeywordAlertModal = ({ 
  isOpen, 
  onClose, 
  onSubmit,
  keyword = null
}) => {
  const [keywordText, setKeywordText] = useState('');
  const [selectedExchanges, setSelectedExchanges] = useState(new Set());
  const [error, setError] = useState('');

  const EXCHANGE_MAP = {
    'Shanghai Stock Exchange': 'SSE',
    'Shenzhen Stock Exchange': 'SZSE',
    'Hong Kong Stock Exchange': 'HKEX',
    'Tokyo Stock Exchange': 'TSE',
    'Stock Exchange of Thailand': 'SET',
    'National Stock Exchange of India': 'NSE',
    'Bombay Stock Exchange': 'BSE',
    'Oslo Stock Exchange': 'OSE'
  };

  useEffect(() => {
    if (keyword) {
      setKeywordText(keyword.keyword);
      // Convert exchange codes back to full names for display
      const exchangeNames = keyword.exchanges.map(code => 
        Object.entries(EXCHANGE_MAP).find(([name, value]) => value === code)?.[0]
      ).filter(Boolean);
      setSelectedExchanges(new Set(exchangeNames));
    } else {
      setKeywordText('');
      setSelectedExchanges(new Set());
    }
  }, [keyword, isOpen]);

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (!keywordText.trim()) {
      setError('Keyword is required');
      return;
    }
  
    if (selectedExchanges.size === 0) {
      setError('At least one exchange must be selected');
      return;
    }
  
    // Convert exchange names to codes before sending to API
    const exchangeCodes = Array.from(selectedExchanges)
      .map(exchange => EXCHANGE_MAP[exchange])
      .filter(Boolean);
  
    const data = {
      keyword: keywordText.trim(),
      exchanges: exchangeCodes,
      notifications: {
        realtime_enabled: 1,  // Default values for new exchanges
        daily_enabled: 1,
        weekly_enabled: 1,
        smart_scan_enabled: 1
      }
    };
  
    if (keyword) {
      onSubmit(keyword.id, data);
    } else {
      onSubmit(data.keyword, data.exchanges, data.notifications);
    }
  };

  const toggleExchange = (exchange) => {
    setSelectedExchanges(prev => {
      const newSet = new Set(prev);
      if (newSet.has(exchange)) {
        newSet.delete(exchange);
      } else {
        newSet.add(exchange);
      }
      return newSet;
    });
  };

  const handleSelectAll = () => {
    if (selectedExchanges.size === AVAILABLE_EXCHANGES.length) {
      setSelectedExchanges(new Set());
    } else {
      setSelectedExchanges(new Set(AVAILABLE_EXCHANGES));
    }
  };

  return (
    <Dialog 
      open={isOpen} 
      onClose={onClose}
      className="fixed z-50 inset-0 overflow-y-auto"
    >
      <div className="flex items-center justify-center min-h-screen px-4">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        
        <div className="relative bg-white rounded-lg max-w-xl w-full p-6">
          {/* Header */}
          <div className="flex justify-between items-center mb-6">
            <Dialog.Title className="text-xl font-semibold text-gray-900">
              {keyword ? 'Edit Keyword Alert' : 'Create New Keyword Alert'}
            </Dialog.Title>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 transition-colors"
            >
              <X className="w-6 h-6" />
            </button>
          </div>

          <form onSubmit={handleSubmit}>
            {/* Keyword Input */}
            <div className="mb-6">
              <label htmlFor="keyword" className="block text-sm font-medium text-gray-700 mb-2">
                Keyword
              </label>
              <input
                type="text"
                id="keyword"
                value={keywordText}
                onChange={(e) => setKeywordText(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-secondary focus:border-secondary"
                placeholder="Enter keyword to monitor"
              />
            </div>

            {/* Exchanges Selection */}
            <div className="mb-6">
              <div className="flex justify-between items-center mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  Monitor Exchanges
                </label>
                <button
                  type="button"
                  onClick={handleSelectAll}
                  className="text-sm text-secondary hover:text-secondary-accent"
                >
                  {selectedExchanges.size === AVAILABLE_EXCHANGES.length ? 'Deselect All' : 'Select All'}
                </button>
              </div>
              <div className="grid grid-cols-2 gap-4">
                {AVAILABLE_EXCHANGES.map((exchange) => (
                  <label 
                    key={exchange} 
                    className="flex items-center space-x-2 text-sm text-gray-600"
                  >
                    <input
                      type="checkbox"
                      checked={selectedExchanges.has(exchange)}
                      onChange={() => toggleExchange(exchange)}
                      className="rounded border-gray-300 text-secondary focus:ring-secondary"
                    />
                    <span>{exchange}</span>
                  </label>
                ))}
              </div>
            </div>

            {error && (
              <div className="mb-4 text-sm text-red-600">
                {error}
              </div>
            )}

            {/* Actions */}
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500 transition-colors"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-secondary hover:bg-secondary-accent text-white rounded-md 
                         transition-colors duration-200 text-sm font-medium
                         focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
              >
                {keyword ? 'Update' : 'Create'} Alert
              </button>
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

export default CreateKeywordAlertModal;