// src/hooks/useLatestFilings.js
import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';

const useLatestFilings = (selectedExchanges = [], selectedKeywords = []) => {
  const { user, getIdToken } = useAuth();
  const [filings, setFilings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const limit = 15;

  // Memoize filters to prevent unnecessary renders
  const filtersKey = `${selectedExchanges.join(',')}-${selectedKeywords.join(',')}`;

  const fetchFilings = useCallback(async (requestOffset) => {
    console.log('fetchFilings called with:', { 
      requestOffset, 
      selectedExchanges,
      selectedKeywords,
      currentOffset: offset,
      isAuthenticated: !!user
    });
    
    setLoading(true);
    setError(null);
    
    try {
      const headers = {};
      
      if (user) {
        try {
          const token = await getIdToken();
          if (token) {
            headers['Authorization'] = `Bearer ${token}`;
          }
        } catch (error) {
          console.warn('Failed to get auth token:', error);
        }
      }

      const params = new URLSearchParams({
        page: Math.floor(requestOffset / limit) + 1,
        limit: limit
      });
      
      // Add exchange filters
      if (selectedExchanges.length > 0) {
        selectedExchanges.forEach(exchange => {
          params.append('exchanges[]', exchange);
        });
      }

      // Add keyword filters
      if (selectedKeywords.length > 0) {
        selectedKeywords.forEach(keyword => {
          params.append('keywords[]', keyword);
        });
      }

      const url = `${process.env.REACT_APP_API_URL}/filings/latest?${params.toString()}`;
      const response = await fetch(url, { headers });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      if (!data.data || data.data.length < limit) {
        setHasMore(false);
      }
      
      if (data.data?.length > 0) {
        setFilings(prev => {
          const newState = requestOffset === 0 ? data.data : [...prev, ...data.data];
          return newState;
        });
        setOffset(requestOffset + data.data.length);
      } else if (requestOffset === 0) {
        setFilings([]);
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching latest filings:', error);
      setError(error.message);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  }, [filtersKey, getIdToken, user]);

  // Reset and fetch when filters change
  useEffect(() => {
    setFilings([]);
    setOffset(0);
    setHasMore(true);
    fetchFilings(0);
  }, [fetchFilings, filtersKey]);

  const loadMore = useCallback(() => {
    if (!loading && hasMore) {
      fetchFilings(offset);
    }
  }, [fetchFilings, offset, loading, hasMore]);

  return {
    filings,
    loading,
    error,
    loadMore,
    hasMore
  };
};

export default useLatestFilings;