import React, { useState, useEffect } from 'react';
import { CheckCircle, Star, FileText, Eye, Loader2 } from 'lucide-react';
import { generateRecommendations } from './recommendationsGenerator';
import { useAuth } from '../../contexts/AuthContext';

const OnboardingResults = ({ preferences, onCreateWatchlists, onSkip }) => {
    const [recommendations, setRecommendations] = useState(null);
    const [selectedWatchlists, setSelectedWatchlists] = useState([]);
    const [isCreating, setIsCreating] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { getIdToken } = useAuth();
  
    useEffect(() => {
      const fetchRecommendations = async () => {
        try {
          const recs = await generateRecommendations(preferences, getIdToken);
          setRecommendations(recs);
          // By default, select all watchlists
          setSelectedWatchlists(recs.watchlists.map(w => w.name));
        } catch (error) {
          console.error('Error fetching recommendations:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchRecommendations();
    }, [preferences, getIdToken]);

    const handleWatchlistToggle = (watchlistName) => {
      if (!isCreating) {
        setSelectedWatchlists(prev => 
          prev.includes(watchlistName)
            ? prev.filter(name => name !== watchlistName)
            : [...prev, watchlistName]
        );
      }
    };
  
    const handleCreateSelected = async () => {
      if (!recommendations) return;

      const watchlistsToCreate = recommendations.watchlists
        .filter(w => selectedWatchlists.includes(w.name));
      
      setIsCreating(true);
      try {
        await onCreateWatchlists?.(watchlistsToCreate);
      } catch (error) {
        console.error('Error creating watchlists:', error);
        setIsCreating(false);
      }
    };

    if (isLoading) {
      return (
        <div className="max-w-4xl mx-auto p-6">
          <div className="flex flex-col items-center justify-center min-h-[400px]">
            <Loader2 className="w-8 h-8 text-primary animate-spin" />
            <p className="mt-4 text-light-text-secondary">
              Generating your personalized recommendations...
            </p>
          </div>
        </div>
      );
    }

    if (!recommendations) {
      return (
        <div className="max-w-4xl mx-auto p-6">
          <div className="text-center">
            <p className="text-light-text-secondary">
              Failed to load recommendations. Please try again later.
            </p>
            <button
              onClick={onSkip}
              className="mt-4 px-6 py-2 text-primary hover:text-primary-dark"
            >
              Continue anyway
            </button>
          </div>
        </div>
      );
    }
  
    return (
      <div className="max-w-4xl mx-auto p-6">
        {/* Welcome Header */}
        <div className="text-center mb-8">
          <CheckCircle className="w-12 h-12 text-secondary mx-auto mb-4" />
          <h1 className="text-2xl font-bold mb-2 text-light-text">Welcome to FilingReader!</h1>
          <p className="text-light-text-secondary mb-6">
            We've personalized your experience based on your preferences.
          </p>
        </div>
  
        {/* Action Buttons and Selection Info */}
        <div className="bg-primary/5 rounded-lg p-6 mb-8">
          <h2 className="text-xl font-semibold mb-3 text-light-text">Ready to get started?</h2>
          <p className="text-light-text-secondary mb-4">
            Select the watchlists you'd like to create, or skip for now.
          </p>
          <div className="flex justify-center gap-4">
            <button
              onClick={handleCreateSelected}
              disabled={selectedWatchlists.length === 0 || isCreating}
              className={`px-6 py-2 rounded-lg transition-colors flex items-center justify-center min-w-[180px] ${
                selectedWatchlists.length > 0 && !isCreating
                  ? 'bg-primary text-white hover:bg-primary-light'
                  : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              }`}
            >
              {isCreating ? (
                <>
                  <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                  Creating...
                </>
              ) : (
                `Create Selected (${selectedWatchlists.length})`
              )}
            </button>
            <button
              onClick={onSkip}
              disabled={isCreating}
              className={`px-6 py-2 border border-gray-300 rounded-lg transition-colors ${
                isCreating 
                  ? 'opacity-50 cursor-not-allowed' 
                  : 'hover:bg-gray-50 text-light-text-secondary hover:text-light-text'
              }`}
            >
              Skip for Now
            </button>
          </div>
        </div>
  
        {/* Suggested Watchlists */}
        <div className="mb-8">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center">
              <Star className="w-5 h-5 text-primary mr-2" />
              <h2 className="text-xl font-semibold text-light-text">Suggested Watchlists</h2>
            </div>
            {recommendations.watchlists.length > 0 && !isCreating && (
              <button
                onClick={() => setSelectedWatchlists(
                  selectedWatchlists.length === recommendations.watchlists.length
                    ? []
                    : recommendations.watchlists.map(w => w.name)
                )}
                className="text-sm text-primary hover:text-primary-light"
              >
                {selectedWatchlists.length === recommendations.watchlists.length
                  ? 'Deselect All'
                  : 'Select All'}
              </button>
            )}
          </div>
          {recommendations.watchlists.length === 0 ? (
            <div className="text-center py-8 bg-light-bg-tertiary rounded-lg">
              <p className="text-light-text-secondary">
                No watchlists found matching your preferences.
              </p>
            </div>
          ) : (
            <div className="grid md:grid-cols-2 gap-4">
              {recommendations.watchlists.map((watchlist) => (
                <div 
                  key={watchlist.name}
                  onClick={() => handleWatchlistToggle(watchlist.name)}
                  className={`border rounded-lg p-4 transition-colors ${
                    isCreating 
                      ? 'opacity-75 cursor-not-allowed'
                      : selectedWatchlists.includes(watchlist.name)
                        ? 'border-primary bg-primary/5'
                        : 'hover:border-primary/20 cursor-pointer'
                  }`}
                >
                  <div className="flex items-start justify-between">
                    <div>
                      <h3 className="font-medium mb-2 text-light-text">{watchlist.name}</h3>
                      <p className="text-sm text-light-text-secondary mb-3">{watchlist.description}</p>
                      <div className="text-sm text-light-text-tertiary">
                        {watchlist.stocks.length} companies included
                      </div>
                    </div>
                    <div className={`w-5 h-5 rounded-full border ${
                      selectedWatchlists.includes(watchlist.name)
                        ? 'bg-primary border-primary'
                        : 'border-gray-300'
                    }`}>
                      {selectedWatchlists.includes(watchlist.name) && (
                        <CheckCircle className="w-5 h-5 text-white" />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
  
        {/* Recommended Features */}
        <div className="mb-8">
          <div className="flex items-center mb-4">
            <Eye className="w-5 h-5 text-primary mr-2" />
            <h2 className="text-xl font-semibold text-light-text">Recommended Features</h2>
          </div>
          <div className="bg-light-bg-tertiary rounded-lg p-4">
            <ul className="grid md:grid-cols-2 gap-3">
              {recommendations.features.map((feature, index) => (
                <li key={index} className="flex items-start">
                  <CheckCircle className="w-5 h-5 text-secondary mr-2 shrink-0" />
                  <span className="text-light-text-secondary">{feature}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Filing Types to Follow */}
        <div className="mb-8">
          <div className="flex items-center mb-4">
            <FileText className="w-5 h-5 text-primary mr-2" />
            <h2 className="text-xl font-semibold text-light-text">Filing Types to Follow</h2>
          </div>
          <div className="bg-light-bg-tertiary rounded-lg p-4">
            <ul className="grid md:grid-cols-2 gap-3">
              {recommendations.suggested_filings.map((filing, index) => (
                <li key={index} className="flex items-start">
                  <CheckCircle className="w-5 h-5 text-secondary mr-2 shrink-0" />
                  <span className="text-light-text-secondary">{filing}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
};

export default OnboardingResults;