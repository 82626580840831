// src/views/AlertsView.js
import React, { useState } from 'react';
import { Trash2, Plus, ChevronDown, ChevronUp, Users } from 'lucide-react';
import useWatchlists from '../hooks/useWatchlists';
import { useKeywordAlerts } from '../hooks/useKeywordAlerts';
import CreateWatchlistModal from '../components/watchlists/CreateWatchlistModal';
import WatchlistStocksTable from '../components/watchlists/WatchlistStocksTable';
import DeleteWatchlistDialog from '../components/watchlists/DeleteWatchlistDialog';
import CompanySearchBar from '../components/watchlists/CompanySearchBar';
import CreateKeywordAlertModal from '../components/alerts/CreateKeywordAlertModal';
import DeleteKeywordAlertDialog from '../components/alerts/DeleteKeywordAlertDialog';
import KeywordCard from '../components/alerts/KeywordCard';
import UnfollowWatchlistDialog from '../components/watchlists/UnfollowWatchlistDialog';

const AlertsView = ({ isFeedsPanelVisible }) => {
  const { 
    watchlists, 
    loading: watchlistsLoading, 
    removeStockFromWatchlist, 
    updateStockNotifications,
    updateBatchNotifications,
    refreshWatchlists,
    createWatchlist,
    addStockToWatchlist,
    deleteWatchlist,
    unsubscribeFromWatchlist  // Add this
  } = useWatchlists();
  
  const { 
    keywordAlerts, 
    loading: alertsLoading,
    addKeywordAlert,
    deleteKeywordAlert,
    updateKeywordAlert,
    updateKeywordNotifications,
    updateKeywordBatchNotifications,
    deleteExchanges 
  } = useKeywordAlerts();

  const [activeTab, setActiveTab] = useState('watchlists');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isCreateKeywordModalOpen, setIsCreateKeywordModalOpen] = useState(false);
  const [selectedWatchlist, setSelectedWatchlist] = useState(null);
  const [selectedKeyword, setSelectedKeyword] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleteKeywordDialogOpen, setIsDeleteKeywordDialogOpen] = useState(false);
  const [isUnfollowDialogOpen, setIsUnfollowDialogOpen] = useState(false);

  const handleCreateWatchlist = async (name, description) => {
    try {
      await createWatchlist({ name, description });
      await refreshWatchlists();
      setIsCreateModalOpen(false);
    } catch (error) {
      console.error('Error creating watchlist:', error);
    }
  };

  const handleSelectCompany = async (company) => {
    if (!selectedWatchlist) return;
    
    try {
      const stockCode = company.stockCodes.split(',')[0].trim();
      await addStockToWatchlist(selectedWatchlist.id, stockCode);
      
      const notifications = {
        realtime_enabled: 1,
        daily_enabled: 1,
        weekly_enabled: 1,
        smart_scan_enabled: 1
      };
      
      await updateStockNotifications(selectedWatchlist.id, stockCode, notifications);
      await refreshWatchlists();
    } catch (error) {
      console.error('Error adding company:', error);
    }
  };

  const handleWatchlistSelect = (watchlist) => {
    setSelectedWatchlist(selectedWatchlist?.id === watchlist.id ? null : watchlist);
  };

  const handleDeleteWatchlist = async () => {
    if (!selectedWatchlist) return;
    
    try {
      await deleteWatchlist(selectedWatchlist.id);
      setSelectedWatchlist(null);
      setIsDeleteDialogOpen(false);
      await refreshWatchlists();
    } catch (error) {
      console.error('Error deleting watchlist:', error);
    }
  };

  const handleDeleteClick = (watchlist) => {
    setSelectedWatchlist(watchlist);
    setIsDeleteDialogOpen(true);
  };

  const handleRemoveStock = async (stockCode) => {
    if (!selectedWatchlist) return;
    
    try {
      const disabledNotifications = {
        realtime_enabled: 0,
        daily_enabled: 0,
        weekly_enabled: 0,
        smart_scan_enabled: 0
      };
      
      await updateStockNotifications(selectedWatchlist.id, stockCode, disabledNotifications);
      await removeStockFromWatchlist(selectedWatchlist.id, stockCode);
      await refreshWatchlists();
    } catch (error) {
      console.error('Error removing stock:', error);
    }
  };

  const handleUpdateNotifications = async (stockCode, notifications) => {
    if (!selectedWatchlist) return;
    
    try {
      await updateStockNotifications(selectedWatchlist.id, stockCode, notifications);
    } catch (error) {
      console.error('Error updating notifications:', error);
    }
  };

  const handleMoveStocks = async (stockCode, targetWatchlistId, notifications) => {
    try {
      await addStockToWatchlist(targetWatchlistId, stockCode);
      await updateStockNotifications(targetWatchlistId, stockCode, notifications);
      await removeStockFromWatchlist(selectedWatchlist.id, stockCode);
      await refreshWatchlists();
    } catch (error) {
      console.error('Error moving stocks:', error);
    }
  };

  const handleCopyStocks = async (stockCode, targetWatchlistId, notifications) => {
    try {
      await addStockToWatchlist(targetWatchlistId, stockCode);
      await updateStockNotifications(targetWatchlistId, stockCode, notifications);
      await refreshWatchlists();
    } catch (error) {
      console.error('Error copying stocks:', error);
    }
  };

  const handleDeleteStocks = async (stockCodes) => {
    try {
      for (const stockCode of stockCodes) {
        await removeStockFromWatchlist(selectedWatchlist.id, stockCode);
      }
      await refreshWatchlists();
    } catch (error) {
      console.error('Error deleting stocks:', error);
    }
  };

  const handleCreateKeywordAlert = async (keyword, exchanges) => {
    try {
      await addKeywordAlert(keyword, exchanges, {
        realtime_enabled: 1,
        daily_enabled: 1,
        weekly_enabled: 1,
        smart_scan_enabled: 1
      });
      setIsCreateKeywordModalOpen(false);
    } catch (error) {
      console.error('Error creating keyword alert:', error);
    }
  };

  const handleEditKeyword = (keyword) => {
    setSelectedKeyword(keyword);
    setIsCreateKeywordModalOpen(true);
  };

  const handleUpdateKeyword = async (keywordId, data) => {
    try {
      await updateKeywordAlert(keywordId, data);
      setIsCreateKeywordModalOpen(false);
      setSelectedKeyword(null);
    } catch (error) {
      console.error('Error updating keyword alert:', error);
    }
  };

  const handleDeleteKeywords = async (keywordIds) => {
    try {
      for (const id of keywordIds) {
        await deleteKeywordAlert(id);
      }
      setIsDeleteKeywordDialogOpen(false);
      setSelectedKeyword(null);
    } catch (error) {
      console.error('Error deleting keyword alerts:', error);
    }
  };

  const handleCreateClick = () => {
    if (activeTab === 'watchlists') {
      setIsCreateModalOpen(true);
    } else {
      setIsCreateKeywordModalOpen(true);
    }
  };

  const renderWatchlistActions = (watchlist) => {
    if (watchlist.is_owned) {
      return (
        <>
          <button
            onClick={() => handleDeleteClick(watchlist)}
            className="flex items-center text-gray-400 hover:text-red-600 text-sm 
                     transition-colors duration-200"
          >
            <Trash2 className="w-4 h-4 mr-1" />
            <span>Delete</span>
          </button>
        </>
      );
    } else {
      return (
        <button
          onClick={() => handleUnfollowClick(watchlist)}
          className="flex items-center text-gray-400 hover:text-red-600 text-sm 
                   transition-colors duration-200"
        >
          <Users className="w-4 h-4 mr-1" />
          <span>Unfollow</span>
        </button>
      );
    }
  };

  const handleUnfollowClick = (watchlist) => {
    setSelectedWatchlist(watchlist);
    setIsUnfollowDialogOpen(true);
  };

  const handleUnfollow = async () => {
    if (!selectedWatchlist) return;
    
    try {
      await unsubscribeFromWatchlist(selectedWatchlist.id);
      setSelectedWatchlist(null);
      setIsUnfollowDialogOpen(false);
      await refreshWatchlists();
    } catch (error) {
      console.error('Error unfollowing watchlist:', error);
    }
  };

  return (
    <div className="container mx-auto px-4 py-6">
      {/* Header */}
      <div className="mb-8">
        <div className="flex justify-between items-start mb-6">
          <div className="space-y-1">
            <h1 className="text-2xl font-semibold text-gray-900">Alerts</h1>
            <p className="text-gray-500">Manage your watchlists and keyword alerts</p>
          </div>
          
          <button
            onClick={handleCreateClick}
            className="px-4 py-2 bg-secondary hover:bg-secondary-accent text-white rounded-md 
                     transition-colors duration-200 font-medium flex items-center space-x-2
                     focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
          >
            <Plus className="w-5 h-5" />
            <span>Create New {activeTab === 'watchlists' ? 'Watchlist' : 'Keyword Alert'}</span>
          </button>
        </div>

        {/* Tabs */}
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8">
            {[
              { id: 'watchlists', name: 'Watchlists' },
              { id: 'keywords', name: 'Keyword Alerts' }
            ].map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`
                  whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
                  transition-colors duration-200
                  ${activeTab === tab.id
                    ? 'border-secondary text-secondary'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}
                `}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>

      {/* Content */}
      <div className="mt-6">
        {activeTab === 'watchlists' ? (
          <div className="bg-white rounded-lg shadow divide-y divide-gray-200">
            {watchlistsLoading ? (
              <div className="p-4 text-center text-gray-500">Loading watchlists...</div>
            ) : watchlists.length === 0 ? (
              <div className="p-8 text-center">
                <p className="text-gray-500 mb-4">No watchlists created yet</p>
                <button
                  onClick={() => setIsCreateModalOpen(true)}
                  className="text-secondary hover:text-secondary-accent font-medium transition-colors"
                >
                  Create your first watchlist
                </button>
              </div>
            ) : (
              watchlists.map((watchlist) => (
                <div key={watchlist.id} className="p-4">
                  <div className="flex justify-between items-center">
                    <div>
                      <div className="flex items-center gap-2">
                        <h3 className="text-lg font-medium text-gray-900">
                          {watchlist.name}
                        </h3>
                        {!watchlist.is_owned && (
                          <span className="px-2 py-1 text-xs font-medium bg-gray-100 text-gray-600 rounded-full">
                            Following
                          </span>
                        )}
                      </div>
                      <p className="text-sm text-gray-500 mt-0.5">
                        {watchlist.stocks?.length || 0} stocks tracked
                      </p>
                    </div>
                    <div className="flex items-center space-x-6">
                      <button
                        onClick={() => handleWatchlistSelect(watchlist)}
                        className="text-secondary hover:text-secondary-accent text-sm font-medium 
                                 transition-colors duration-200 flex items-center space-x-2"
                      >
                        <span>{selectedWatchlist?.id === watchlist.id ? 'Hide Details' : 'View Details'}</span>
                        {selectedWatchlist?.id === watchlist.id ? 
                          <ChevronUp className="w-4 h-4" /> : 
                          <ChevronDown className="w-4 h-4" />
                        }
                      </button>
                      {renderWatchlistActions(watchlist)}
                    </div>
                  </div>
                  
                  {selectedWatchlist?.id === watchlist.id && (
                    <div className="mt-4">
                      {watchlist.is_owned && (
                        <div className="mb-4">
                          <CompanySearchBar onSelectCompany={handleSelectCompany} />
                        </div>
                      )}
                      <WatchlistStocksTable
                        stocks={watchlist.stocks || []}
                        watchlistId={watchlist.id}
                        allWatchlists={watchlists}
                        onRemoveStock={handleRemoveStock}
                        onUpdateNotifications={handleUpdateNotifications}
                        onUpdateBatchNotifications={updateBatchNotifications}
                        onMoveStocks={handleMoveStocks}
                        onCopyStocks={handleCopyStocks}
                        onDeleteStocks={handleDeleteStocks}
                        isFeedsPanelVisible={isFeedsPanelVisible}
                        isFollowed={!watchlist.is_owned}
                      />
                    </div>
                  )}
                </div>
              ))
            )}
          </div>
        ) : (
          <div className="bg-white rounded-lg shadow divide-y divide-gray-200">
            {alertsLoading ? (
              <div className="p-4 text-center text-gray-500">Loading keyword alerts...</div>
            ) : keywordAlerts.length === 0 ? (
              <div className="p-8 text-center">
                <p className="text-gray-500 mb-4">No keyword alerts set up</p>
                <button
                  onClick={() => setIsCreateKeywordModalOpen(true)}
                  className="text-secondary hover:text-secondary-accent font-medium transition-colors"
                >
                  Create your first keyword alert
                </button>
              </div>
            ) : (
              keywordAlerts.map((keyword) => (
                <KeywordCard
                  key={keyword.id}
                  keyword={keyword}
                  isExpanded={selectedKeyword?.id === keyword.id}
                  onToggleExpand={(kw) => setSelectedKeyword(selectedKeyword?.id === kw.id ? null : kw)}
                  onDelete={(kw) => {
                    setSelectedKeyword(kw);
                    setIsDeleteKeywordDialogOpen(true);
                  }}
                  onEdit={handleEditKeyword}
                  onUpdateNotifications={updateKeywordNotifications}
                  onUpdateBatchNotifications={updateKeywordBatchNotifications}
                  onDeleteExchanges={deleteExchanges}  
                  isFeedsPanelVisible={isFeedsPanelVisible}
                />
              ))
            )}
          </div>
        )}
      </div>

      {/* Modals */}
      <CreateWatchlistModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onSubmit={handleCreateWatchlist}
      />

      <CreateKeywordAlertModal
        isOpen={isCreateKeywordModalOpen}
        onClose={() => {
          setIsCreateKeywordModalOpen(false);
          setSelectedKeyword(null);
        }}
        onSubmit={selectedKeyword ? handleUpdateKeyword : handleCreateKeywordAlert}
        keyword={selectedKeyword}
      />

      <DeleteWatchlistDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        watchlist={selectedWatchlist}
        onDelete={handleDeleteWatchlist}
      />

      <DeleteKeywordAlertDialog
        isOpen={isDeleteKeywordDialogOpen}
        onClose={() => setIsDeleteKeywordDialogOpen(false)}
        keyword={selectedKeyword}
        onDelete={() => handleDeleteKeywords([selectedKeyword.id])}
      />

      <UnfollowWatchlistDialog
        isOpen={isUnfollowDialogOpen}
        onClose={() => setIsUnfollowDialogOpen(false)}
        watchlist={selectedWatchlist}
        onUnfollow={handleUnfollow}
      />
    </div>
  );
};

export default AlertsView;