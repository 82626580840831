import React, { useState } from 'react';
import { ChevronRight, ChevronLeft } from 'lucide-react';

// Industry options
const INDUSTRIES = [
  { id: 'tech', label: 'Technology' },
  { id: 'finance', label: 'Financial Services' },
  { id: 'healthcare', label: 'Healthcare' },
  { id: 'consumer', label: 'Consumer Goods' },
  { id: 'energy', label: 'Energy' },
  { id: 'materials', label: 'Materials' },
  { id: 'industrial', label: 'Industrial' },
  { id: 'real_estate', label: 'Real Estate' }
];

// Role options
const ROLES = [
  { id: 'investor', label: 'Investor' },
  { id: 'analyst', label: 'Financial Analyst' },
  { id: 'researcher', label: 'Researcher' },
  { id: 'journalist', label: 'Journalist' },
  { id: 'student', label: 'Student' },
  { id: 'other', label: 'Other' }
];

// Exchange options grouped by region
const EXCHANGES = {
  'North America': [
    { id: 'NYSE', label: 'New York Stock Exchange' },
    { id: 'NASDAQ', label: 'NASDAQ' },
    { id: 'TMX', label: 'TMX Group (Canada)' },
    { id: 'BMV', label: 'Bolsa Mexicana de Valores' },
    { id: 'BSX', label: 'Bermuda Stock Exchange' },
    { id: 'NEO', label: 'NEO Exchange (Canada)' }
  ],
  'Asia': [
    { id: 'SSE', label: 'Shanghai Stock Exchange' },
    { id: 'JPX', label: 'Japan Exchange Group' },
    { id: 'HKEX', label: 'Hong Kong Exchanges' },
    { id: 'SZSE', label: 'Shenzhen Stock Exchange' },
    { id: 'NSE', label: 'National Stock Exchange of India' },
    { id: 'BSE', label: 'BSE India Limited' },
    { id: 'KRX', label: 'Korea Exchange' },
    { id: 'TWSE', label: 'Taiwan Stock Exchange' },
    { id: 'SGX', label: 'Singapore Exchange' },
    { id: 'SET', label: 'Stock Exchange of Thailand' },
    { id: 'IDX', label: 'Indonesia Stock Exchange' },
    { id: 'BURSA', label: 'Bursa Malaysia' },
    { id: 'PSE', label: 'Philippine Stock Exchange' },
    { id: 'KASE', label: 'Kazakhstan Stock Exchange' },
    { id: 'HNX', label: 'Hanoi Stock Exchange' },
    { id: 'HOSE', label: 'Ho Chi Minh Stock Exchange' },
    { id: 'CSE', label: 'Colombo Stock Exchange' },
    { id: 'MSE', label: 'Mongolian Stock Exchange' },
    { id: 'DSE', label: 'Dhaka Stock Exchange' },
    { id: 'PSX', label: 'Pakistan Stock Exchange' }
  ],
  'Europe': [
    { id: 'EURONEXT', label: 'Euronext' },
    { id: 'LSE', label: 'London Stock Exchange Group' },
    { id: 'DB', label: 'Deutsche Boerse' },
    { id: 'SIX', label: 'SIX Swiss Exchange' },
    { id: 'NASDAQ_NORDIC', label: 'NASDAQ Nordic & Baltic' },
    { id: 'BME', label: 'BME Spanish Exchanges' },
    { id: 'BORSA_ITALIANA', label: 'Borsa Italia' },
    { id: 'MOEX', label: 'Moscow Exchange' },
    { id: 'WIENER_BORSE', label: 'Vienna Stock Exchange' },
    { id: 'BSE_HUNGARY', label: 'Budapest Stock Exchange' },
    { id: 'LUXSE', label: 'Luxembourg Stock Exchange' },
    { id: 'ATHEX', label: 'Athens Stock Exchange' },
    { id: 'GPW', label: 'Warsaw Stock Exchange' },
    { id: 'PSE_PRAGUE', label: 'Prague Stock Exchange' },
    { id: 'BVB', label: 'Bucharest Stock Exchange' },
    { id: 'BSE_SOFIA', label: 'Bulgarian Stock Exchange' },
    { id: 'ZSE', label: 'Zagreb Stock Exchange' }
  ],
  'Middle East': [
    { id: 'TADAWUL', label: 'Saudi Stock Exchange' },
    { id: 'ADX', label: 'Abu Dhabi Securities Exchange' },
    { id: 'QSE', label: 'Qatar Stock Exchange' },
    { id: 'DFM', label: 'Dubai Financial Market' },
    { id: 'TASE', label: 'Tel Aviv Stock Exchange' },
    { id: 'MSM', label: 'Muscat Securities Market' },
    { id: 'BHB', label: 'Bahrain Bourse' },
    { id: 'ISX', label: 'Iraq Stock Exchange' },
    { id: 'TSE_IRAN', label: 'Tehran Stock Exchange' }
  ],
  'Australia/NZ': [
    { id: 'ASX', label: 'Australian Securities Exchange' },
    { id: 'NZX', label: 'NZX Limited' },
    { id: 'PNGX', label: 'Port Moresby Stock Exchange' },
    { id: 'SPSE', label: 'South Pacific Stock Exchange' }
  ],
  'Africa': [
    { id: 'JSE', label: 'Johannesburg Stock Exchange' },
    { id: 'NSE_NIGERIA', label: 'Nigerian Stock Exchange' },
    { id: 'CASA', label: 'Casablanca Stock Exchange' },
    { id: 'EGX', label: 'Egyptian Exchange' },
    { id: 'NSE_KENYA', label: 'Nairobi Securities Exchange' },
    { id: 'SEM', label: 'Stock Exchange of Mauritius' },
    { id: 'BSE_BOTSWANA', label: 'Botswana Stock Exchange' },
    { id: 'GSE', label: 'Ghana Stock Exchange' },
    { id: 'NSX', label: 'Namibian Stock Exchange' },
    { id: 'RSE', label: 'Rwanda Stock Exchange' },
    { id: 'USE', label: 'Uganda Securities Exchange' },
    { id: 'ZSE_ZIM', label: 'Zimbabwe Stock Exchange' }
  ],
  'South America': [
    { id: 'B3', label: 'B3 (Brazil)' },
    { id: 'SANTIAGO', label: 'Santiago Stock Exchange' },
    { id: 'BVC', label: 'Colombian Securities Exchange' },
    { id: 'BVL', label: 'Lima Stock Exchange' },
    { id: 'BCBA', label: 'Buenos Aires Stock Exchange' },
    { id: 'BVM', label: 'Montevideo Stock Exchange' },
    { id: 'BBV', label: 'Bolivian Stock Exchange' },
    { id: 'BVC_VENEZUELA', label: 'Caracas Stock Exchange' }
  ]
};

const OnboardingWizard = ({ onComplete }) => {
  const [step, setStep] = useState(1);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [selectedExchanges, setSelectedExchanges] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [expandedRegions, setExpandedRegions] = useState(['Asia']); // Default expanded region

  const handleIndustryToggle = (industryId) => {
    setSelectedIndustries(prev => 
      prev.includes(industryId)
        ? prev.filter(id => id !== industryId)
        : [...prev, industryId]
    );
  };

  const handleExchangeToggle = (exchangeId) => {
    setSelectedExchanges(prev => 
      prev.includes(exchangeId)
        ? prev.filter(id => id !== exchangeId)
        : [...prev, exchangeId]
    );
  };

  const toggleRegion = (region) => {
    setExpandedRegions(prev =>
      prev.includes(region)
        ? prev.filter(r => r !== region)
        : [...prev, region]
    );
  };

  const handleNext = () => {
    if (step < 4) setStep(step + 1);
    else if (step === 4) {
      onComplete?.({
        industries: selectedIndustries,
        exchanges: selectedExchanges,
        role: selectedRole
      });
    }
  };

  const handleBack = () => {
    if (step > 1) setStep(step - 1);
  };

  const isStepValid = () => {
    switch (step) {
      case 1: return selectedIndustries.length > 0;
      case 2: return selectedExchanges.length > 0;
      case 3: return selectedRole !== '';
      case 4: return true;
      default: return false;
    }
  };

  const renderExchangeSection = (region, exchanges) => {
    const isExpanded = expandedRegions.includes(region);
    const selectedCount = exchanges.filter(e => selectedExchanges.includes(e.id)).length;

    return (
      <div key={region} className="mb-4">
        <button
          onClick={() => toggleRegion(region)}
          className="w-full flex items-center justify-between p-3 bg-gray-50 hover:bg-gray-100 rounded-lg text-left transition-colors"
        >
          <div>
            <span className="font-medium text-light-text">{region}</span>
            {selectedCount > 0 && (
              <span className="ml-2 text-sm text-primary">
                ({selectedCount} selected)
              </span>
            )}
          </div>
          <span className="transform transition-transform">
            {isExpanded ? '−' : '+'}
          </span>
        </button>
        {isExpanded && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-2 pl-2">
            {exchanges.map(exchange => (
              <button
                key={exchange.id}
                onClick={() => handleExchangeToggle(exchange.id)}
                className={`p-3 rounded-lg border text-left transition-colors ${
                  selectedExchanges.includes(exchange.id)
                    ? 'border-primary bg-primary/5 text-primary'
                    : 'border-gray-200 hover:border-primary/20 text-light-text-secondary'
                }`}
              >
                {exchange.label}
              </button>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      {/* Progress bar */}
      <div className="mb-8">
        <div className="h-2 bg-gray-200 rounded-full">
          <div 
            className="h-2 bg-primary rounded-full transition-all duration-500"
            style={{ width: `${(step / 4) * 100}%` }}
          />
        </div>
        <div className="mt-2 text-sm text-light-text-tertiary text-center">
          Step {step} of 4
        </div>
      </div>

      {/* Step 1: Industries */}
      {step === 1 && (
        <div className="space-y-4">
          <h2 className="text-2xl font-semibold mb-4 text-light-text">Select Industries</h2>
          <p className="text-light-text-secondary mb-4">
            Which industries are you most interested in following?
          </p>
          <div className="grid grid-cols-2 gap-3">
            {INDUSTRIES.map(industry => (
              <button
                key={industry.id}
                onClick={() => handleIndustryToggle(industry.id)}
                className={`p-3 rounded-lg border text-left transition-colors ${
                  selectedIndustries.includes(industry.id)
                    ? 'border-primary bg-primary/5 text-primary'
                    : 'border-gray-200 hover:border-primary/20 text-light-text-secondary'
                }`}
              >
                {industry.label}
              </button>
            ))}
          </div>
        </div>
      )}

      {/* Step 2: Exchanges */}
      {step === 2 && (
        <div className="space-y-4">
          <h2 className="text-2xl font-semibold mb-4 text-light-text">Select Exchanges</h2>
          <p className="text-light-text-secondary mb-4">
            Which exchanges do you primarily follow?
          </p>
          <div className="max-h-[calc(100vh-300px)] overflow-y-auto pr-2">
            {Object.entries(EXCHANGES).map(([region, exchanges]) => 
              renderExchangeSection(region, exchanges)
            )}
          </div>
        </div>
      )}

      {/* Step 3: Role */}
      {step === 3 && (
        <div className="space-y-4">
          <h2 className="text-2xl font-semibold mb-4 text-light-text">Select Your Role</h2>
          <p className="text-light-text-secondary mb-4">
            What best describes your role?
          </p>
          <div className="grid grid-cols-2 gap-3">
            {ROLES.map(role => (
              <button
                key={role.id}
                onClick={() => setSelectedRole(role.id)}
                className={`p-3 rounded-lg border transition-colors ${
                  selectedRole === role.id
                    ? 'border-primary bg-primary/5 text-primary'
                    : 'border-gray-200 hover:border-primary/20 text-light-text-secondary'
                }`}
              >
                {role.label}
              </button>
            ))}
          </div>
        </div>
      )}

      {/* Step 4: Summary */}
      {step === 4 && (
        <div className="space-y-4">
          <h2 className="text-2xl font-semibold mb-4 text-light-text">Summary</h2>
          <div className="space-y-6">
          <div>
              <h3 className="font-medium text-light-text mb-2">Selected Industries:</h3>
              <div className="flex flex-wrap gap-2">
                {selectedIndustries.map(id => (
                  <span key={id} className="px-3 py-1 bg-primary/10 text-primary rounded-full text-sm">
                    {INDUSTRIES.find(i => i.id === id)?.label}
                  </span>
                ))}
              </div>
            </div>
            <div>
              <h3 className="font-medium text-light-text mb-2">Selected Exchanges:</h3>
              <div className="flex flex-wrap gap-2">
                {selectedExchanges.map(id => {
                  const exchange = Object.values(EXCHANGES)
                    .flat()
                    .find(e => e.id === id);
                  return (
                    <span key={id} className="px-3 py-1 bg-primary/10 text-primary rounded-full text-sm">
                      {exchange?.label}
                    </span>
                  );
                })}
              </div>
            </div>
            <div>
              <h3 className="font-medium text-light-text mb-2">Your Role:</h3>
              <span className="px-3 py-1 bg-primary/10 text-primary rounded-full text-sm">
                {ROLES.find(r => r.id === selectedRole)?.label}
              </span>
            </div>
          </div>
        </div>
      )}

      {/* Navigation buttons */}
      <div className="mt-8 flex justify-between">
        <button
          onClick={handleBack}
          className={`flex items-center px-4 py-2 rounded ${
            step === 1
              ? 'text-gray-400 cursor-not-allowed'
              : 'text-light-text-secondary hover:text-light-text'
          }`}
          disabled={step === 1}
        >
          <ChevronLeft className="w-5 h-5 mr-1" />
          Back
        </button>
        <button
          onClick={handleNext}
          disabled={!isStepValid()}
          className={`flex items-center px-6 py-2 rounded-lg ${
            isStepValid()
              ? 'bg-primary text-white hover:bg-primary-light'
              : 'bg-gray-300 text-gray-500 cursor-not-allowed'
          }`}
        >
          {step === 4 ? 'Complete' : 'Next'}
          {step !== 4 && <ChevronRight className="w-5 h-5 ml-1" />}
        </button>
      </div>
    </div>
  );
};

export default OnboardingWizard;