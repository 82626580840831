/**
 * Centralized Firebase initialization
 * 
 * IMPORTANT: This file should be imported first in index.js
 * to ensure Firebase is initialized before any other code runs.
 * 
 * Do NOT initialize Firebase or create new auth instances elsewhere.
 * Instead, import the pre-initialized auth instance from this file:
 * 
 * import { auth } from '../config/firebase-init';
 */

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import firebaseConfig from './firebase';

// Initialize Firebase immediately
console.log('Initializing Firebase app');
const app = initializeApp(firebaseConfig);
console.log('Initializing Firebase auth');
const auth = getAuth(app);

export { auth };