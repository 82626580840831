import React, { useState, useEffect, useRef } from 'react';

const WatchlistActionMenu = ({ 
  isOpen, 
  onClose, 
  position, 
  watchlists, 
  currentWatchlistId,
  onSelectWatchlist,
  actionType 
}) => {
  const menuRef = useRef(null);
  const [menuStyle, setMenuStyle] = useState({});
  
  useEffect(() => {
    if (isOpen && position) {
      // Position the menu relative to the button
      setMenuStyle({
        position: 'fixed',
        top: `${position.y + 30}px`, // 30px below the button
        left: `${position.x}px`,
      });
    }
  }, [isOpen, position]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div 
      ref={menuRef}
      style={menuStyle}
      className="bg-white rounded-lg shadow-lg border border-gray-200 w-64 z-50"
    >
      <div className="p-3 border-b border-gray-200">
        <h3 className="text-sm font-medium text-gray-900">
          {actionType === 'move' ? 'Move to Watchlist' : 'Copy to Watchlist'}
        </h3>
      </div>
      <div className="max-h-64 overflow-y-auto
        [scrollbar-width:thin]
        [scrollbar-color:#E5E7EB_transparent]
        [&::-webkit-scrollbar]:w-1.5
        [&::-webkit-scrollbar-track]:bg-transparent
        [&::-webkit-scrollbar-thumb]:bg-gray-200
        [&::-webkit-scrollbar-thumb]:rounded-full
        [&::-webkit-scrollbar-thumb]:border-4
        [&::-webkit-scrollbar-thumb]:border-solid
        [&::-webkit-scrollbar-thumb]:border-transparent
        hover:[&::-webkit-scrollbar-thumb]:bg-gray-300"
      >
        {watchlists.length <= 1 ? (
          <div className="p-4 text-sm text-gray-500">
            No other watchlists available
          </div>
        ) : (
          watchlists
            .filter(w => w.id !== currentWatchlistId)
            .map(watchlist => (
              <button
                key={watchlist.id}
                onClick={() => onSelectWatchlist(watchlist.id)}
                className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
              >
                {watchlist.name}
              </button>
            ))
        )}
      </div>
    </div>
  );
};

export default WatchlistActionMenu;