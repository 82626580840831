import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Globe, Clock, Building2, Info, DollarSign, Users, Calendar } from 'lucide-react';
import useExchangeAccess from '../hooks/useExchangeAccess';
import { formatNumber } from '../utils/formatters';

const ExchangeCard = ({ exchange, onToggleAccess, isEnabled, isLoading, disableActions = false }) => {
  const formattedMarketCap = formatNumber(exchange.market_cap_usd, {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
    maximumFractionDigits: 1
  });

  return (
    <div className="bg-white rounded-lg border border-gray-200 overflow-hidden hover:shadow-lg transition-shadow duration-200">
      <div className="p-4">
        <div className="flex items-start space-x-3">
          {exchange.flag_url && (
            <img 
              src={exchange.flag_url} 
              alt={`${exchange.country} flag`}
              className="w-10 h-10 rounded-lg object-cover flex-shrink-0"
            />
          )}
          <div className="flex-1 min-w-0">
            <h3 className="text-lg font-semibold text-gray-900 truncate">
              {exchange.name}
            </h3>
            <p className="text-sm text-gray-500">
              {exchange.country} • {exchange.region}
            </p>
          </div>
          <button
            onClick={() => onToggleAccess(exchange.exchange_id)}
            disabled={isLoading || disableActions}
            className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors duration-200 ${
              isEnabled
                ? 'bg-red-50 text-red-600 hover:bg-red-100 disabled:opacity-50'
                : 'bg-secondary text-white hover:bg-secondary-600 disabled:opacity-50'
            }`}
          >
            {isEnabled ? 'Remove' : 'Add'}
          </button>
        </div>

        <p className="mt-3 text-sm text-gray-600 line-clamp-2">
          {exchange.description}
        </p>

        <div className="mt-4 grid grid-cols-2 gap-4">
          <div className="flex items-center space-x-2">
            <Clock className="w-4 h-4 text-gray-400" />
            <span className="text-sm text-gray-600">
              {exchange.opening_time?.slice(0, 5)} - {exchange.closing_time?.slice(0, 5)}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <Calendar className="w-4 h-4 text-gray-400" />
            <span className="text-sm text-gray-600">{exchange.trading_days}</span>
          </div>
          <div className="flex items-center space-x-2">
            <DollarSign className="w-4 h-4 text-gray-400" />
            <span className="text-sm text-gray-600">
              {formattedMarketCap} Market Cap
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <Building2 className="w-4 h-4 text-gray-400" />
            <span className="text-sm text-gray-600">
              {formatNumber(exchange.listed_companies)} Listed Companies
            </span>
          </div>
        </div>

        {exchange.website_url && (
          <div className="mt-4 pt-4 border-t border-gray-100">
            <a
              href={exchange.website_url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-secondary hover:text-secondary-600 flex items-center space-x-1"
            >
              <span>Visit Official Website</span>
              <span aria-hidden="true">→</span>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

const ExchangeHubView = () => {
  const { user } = useAuth();
  const {
    includedExchanges,
    notIncludedExchanges,
    loading,
    error,
    enableExchange,
    disableExchange,
    subscriptionLimits
  } = useExchangeAccess();

  const [actionLoading, setActionLoading] = useState(false);

  const handleToggleAccess = async (exchangeId) => {
    setActionLoading(true);
    try {
      const isEnabled = includedExchanges.some(e => e.exchange_id === exchangeId);
      await (isEnabled ? disableExchange(exchangeId) : enableExchange(exchangeId));
    } catch (error) {
      console.error('Error toggling exchange access:', error);
    } finally {
      setActionLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="max-w-6xl mx-auto px-4 py-8">
        <div className="animate-pulse">
          <div className="h-8 bg-gray-200 rounded w-1/4 mb-8"></div>
          <div className="h-32 bg-gray-200 rounded mb-8"></div>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="space-y-4">
              <div className="h-4 bg-gray-200 rounded w-1/3"></div>
              <div className="h-48 bg-gray-200 rounded"></div>
            </div>
            <div className="space-y-4">
              <div className="h-4 bg-gray-200 rounded w-1/3"></div>
              <div className="h-48 bg-gray-200 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-6xl mx-auto px-4 py-8">
        <div className="bg-red-50 border border-red-200 rounded-lg p-4">
          <div className="flex items-center space-x-2">
            <Info className="w-5 h-5 text-red-500" />
            <h3 className="text-red-800 font-medium">Error loading exchanges</h3>
          </div>
          <p className="text-red-600 mt-1">{error}</p>
        </div>
      </div>
    );
  }

  const atSubscriptionLimit = subscriptionLimits.current >= subscriptionLimits.maximum;

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold text-gray-900 mb-8">Exchange hub</h1>
      
      {/* Info Card */}
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6 mb-8">
        <div className="flex items-start space-x-4">
          <div className="p-2 bg-orange-50 rounded-lg">
            <Globe className="h-6 w-6 text-orange-500" />
          </div>
          <div className="flex-1">
            <h2 className="text-lg font-semibold text-gray-900 mb-1">
              Access exchanges important to your team
            </h2>
            <p className="text-gray-600 mb-2">
              Select the exchanges you want to monitor. Your {user?.subscription_status || 'current'} plan 
              allows access to {subscriptionLimits.maximum} exchanges.
            </p>
            <div className="mt-3 flex items-center">
              <div className="flex-1 bg-gray-200 rounded-full h-2">
                <div 
                  className="bg-secondary rounded-full h-2 transition-all duration-300"
                  style={{ width: `${(subscriptionLimits.current / subscriptionLimits.maximum) * 100}%` }}
                ></div>
              </div>
              <span className="ml-3 text-sm text-gray-600">
                {subscriptionLimits.current} of {subscriptionLimits.maximum} exchanges
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-8">
        {/* Included Exchanges */}
        <div>
          <h3 className="text-sm font-medium text-gray-500 mb-4">
            Included in your {user?.subscription_status || 'current'} plan
          </h3>
          <div className="space-y-4">
            {includedExchanges.map((exchange) => (
              <ExchangeCard
                key={exchange.exchange_id}
                exchange={exchange}
                onToggleAccess={handleToggleAccess}
                isEnabled={true}
                isLoading={actionLoading}
                disableActions={false}
              />
            ))}
            {includedExchanges.length === 0 && (
              <div className="bg-gray-50 rounded-lg border border-dashed border-gray-200 p-8 text-center">
                <p className="text-gray-500">No exchanges selected</p>
              </div>
            )}
          </div>
        </div>

        {/* Not Included Exchanges */}
        <div>
          <h3 className="text-sm font-medium text-gray-500 mb-4">
            Not included in your {user?.subscription_status || 'current'} plan
          </h3>
          <div className="space-y-4">
            {notIncludedExchanges.map((exchange) => (
              <ExchangeCard
                key={exchange.exchange_id}
                exchange={exchange}
                onToggleAccess={handleToggleAccess}
                isEnabled={false}
                isLoading={actionLoading}
                disableActions={atSubscriptionLimit}
              />
            ))}
            {notIncludedExchanges.length === 0 && (
              <div className="bg-gray-50 rounded-lg border border-dashed border-gray-200 p-8 text-center">
                <p className="text-gray-500">No additional exchanges available</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExchangeHubView;