import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';

const CACHE_KEY = 'user_subscription_status';
const CACHE_DURATION = 1000 * 60 * 60; // 1 hour in milliseconds

export const useSubscription = () => {
  const { user } = useAuth();
  const [canAccessProFeatures, setCanAccessProFeatures] = useState(() => {
    // Try to get cached value first
    const cached = localStorage.getItem(CACHE_KEY);
    if (cached) {
      const { value, timestamp } = JSON.parse(cached);
      if (Date.now() - timestamp < CACHE_DURATION) {
        return value;
      }
      localStorage.removeItem(CACHE_KEY); // Clear expired cache
    }
    return false;
  });

  useEffect(() => {
    // Update subscription status when user changes
    if (user) {
      const isProUser = 
        user.subscription_status === 'pro' || 
        user.is_premium || 
        user.is_team;
      
      setCanAccessProFeatures(isProUser);
      
      // Cache the result
      localStorage.setItem(CACHE_KEY, JSON.stringify({
        value: isProUser,
        timestamp: Date.now()
      }));
    } else {
      setCanAccessProFeatures(false);
      localStorage.removeItem(CACHE_KEY);
    }
  }, [user?.subscription_status, user?.is_premium, user?.is_team]);

  return { canAccessProFeatures };
};

export default useSubscription;