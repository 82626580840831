import React, { useState, useCallback, useEffect } from 'react';
import { Rss, Trash2 } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

const EXCHANGE_MAP = {
  'SSE': 'Shanghai Stock Exchange',
  'SZSE': 'Shenzhen Stock Exchange',
  'HKEX': 'Hong Kong Stock Exchange',
  'TSE': 'Tokyo Stock Exchange',
  'SET': 'Stock Exchange of Thailand',
  'NSE': 'National Stock Exchange of India',
  'BSE': 'Bombay Stock Exchange',
  'OSE': 'Oslo Stock Exchange'
};

const ExchangeAlertTable = ({
  keyword,
  onUpdateNotifications,
  onUpdateBatchNotifications,
  isFeedsPanelVisible,
  onDeleteExchanges
}) => {
  const { getIdToken } = useAuth();
  const [pendingUpdates, setPendingUpdates] = useState(new Set());
  const [selectedExchanges, setSelectedExchanges] = useState(new Set());
  const [loadingRss, setLoadingRss] = useState({});
  const [rssIds, setRssIds] = useState({});

  const EMAIL_ALERTS_GRID = "grid grid-cols-4 gap-4";
  const EMAIL_ALERT_CELL = "flex items-center relative overflow-visible whitespace-nowrap min-w-[100px]";
  const checkboxStyle = "w-4 h-4 min-w-[16px] min-h-[16px] flex-shrink-0 appearance-none rounded border border-gray-300 bg-white checked:bg-secondary-accent checked:border-secondary-accent focus:outline-none focus:ring-2 focus:ring-secondary-accent focus:ring-offset-2 transition-all duration-200 ease-in-out cursor-pointer";

  const columnStyles = {
    exchange: { 
      width: '180px', 
      minWidth: '180px',
      padding: '0.5rem 1rem',
      textAlign: 'left',
      overflow: 'visible',
      whiteSpace: 'nowrap'
    },
    emailAlerts: { 
      width: '45%', 
      minWidth: '400px',
      padding: '0.5rem 1rem',
      overflow: 'visible'
    },
    rss: { 
      width: '50px', 
      minWidth: '50px',
      padding: '0.5rem 1rem',
      textAlign: 'center',
      overflow: 'visible',
      whiteSpace: 'nowrap'
    }
  };

    // Fetch existing RSS links when component mounts
    useEffect(() => {
      const fetchExistingRssLinks = async () => {
        if (!keyword?.exchanges?.length) return;
        
        try {
          const token = await getIdToken();
          const response = await fetch(`${process.env.REACT_APP_API_URL}/rss/keyword/existing`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
              keyword: keyword.keyword,
              exchanges: keyword.exchanges
            })
          });
  
          if (!response.ok) {
            throw new Error('Failed to fetch existing RSS links');
          }
  
          const data = await response.json();
          setRssIds(data.rssLinks);
        } catch (error) {
          console.error('Error fetching RSS links:', error);
        }
      };
  
      fetchExistingRssLinks();
    }, [keyword, getIdToken]);
  
    const handleRssClick = async (exchange) => {
      if (loadingRss[exchange]) return;
      
      // If we already have an RSS ID, use it directly
      if (rssIds[exchange]) {
        window.open(`https://feed2.filingreader.com/rss/${rssIds[exchange]}`, '_blank');
        return;
      }
      
      try {
        setLoadingRss(prev => ({ ...prev, [exchange]: true }));
        
        const token = await getIdToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}/rss/keyword`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            keyword: keyword.keyword,
            exchanges: [exchange]
          })
        });
  
        if (!response.ok) {
          throw new Error('Failed to generate RSS link');
        }
  
        const { rss_id } = await response.json();
        
        // Store the new RSS ID
        setRssIds(prev => ({
          ...prev,
          [exchange]: rss_id
        }));
        
        // Open RSS feed in new tab
        window.open(`https://feed2.filingreader.com/rss/${rss_id}`, '_blank');
  
      } catch (error) {
        console.error('Error generating RSS link:', error);
      } finally {
        setLoadingRss(prev => ({ ...prev, [exchange]: false }));
      }
    };  

  const getNotificationValue = useCallback((exchange, type) => {
    const updateKey = `${exchange}-${type}`;
    const isPending = pendingUpdates.has(updateKey);
    const currentValue = keyword.notifications[exchange]?.[type] ?? 0;
    
    // If there's a pending update, return the opposite of current value
    return isPending ? (currentValue === 1 ? 0 : 1) : currentValue;
  }, [pendingUpdates, keyword.notifications]);

  const handleNotificationChange = useCallback(async (exchange, type, currentValue) => {
    const updateKey = `${exchange}-${type}`;
    
    // Update pending state immediately
    setPendingUpdates(prev => new Set([...prev, updateKey]));
    
    const newNotifications = {
      ...keyword.notifications[exchange],
      [type]: currentValue === 1 ? 0 : 1
    };

    try {
      await onUpdateNotifications(keyword.id, exchange, newNotifications);
    } catch (error) {
      console.error('Error updating notifications:', error);
    } finally {
      // Remove pending state after a consistent delay for smooth UI
      setTimeout(() => {
        setPendingUpdates(prev => {
          const newSet = new Set(prev);
          newSet.delete(updateKey);
          return newSet;
        });
      }, 500);
    }
  }, [keyword.id, keyword.notifications, onUpdateNotifications]);

  const handleSelectAll = useCallback(() => {
    setSelectedExchanges(prev => 
      prev.size === keyword.exchanges.length ? new Set() : new Set(keyword.exchanges)
    );
  }, [keyword.exchanges]);

  const handleDeleteSelected = useCallback(async () => {
    try {
      const selectedExchangeArray = Array.from(selectedExchanges);
      await onDeleteExchanges(keyword.id, selectedExchangeArray);
      setSelectedExchanges(new Set());
    } catch (error) {
      console.error('Error deleting exchanges:', error);
    }
  }, [keyword.id, onDeleteExchanges, selectedExchanges]);

  const toggleExchange = useCallback((exchange) => {
    setSelectedExchanges(prev => {
      const newSet = new Set(prev);
      if (newSet.has(exchange)) {
        newSet.delete(exchange);
      } else {
        newSet.add(exchange);
      }
      return newSet;
    });
  }, []);

  const handleBatchNotificationChange = useCallback(async (notificationType) => {
    const exchangesToUpdate = selectedExchanges.size > 0 
      ? Array.from(selectedExchanges)
      : keyword.exchanges;
    
    const someEnabled = exchangesToUpdate.some(
      exchange => getNotificationValue(exchange, notificationType)
    );
    
    try {
      await onUpdateBatchNotifications(
        keyword.id,
        notificationType,
        !someEnabled,
        exchangesToUpdate
      );
    } catch (error) {
      console.error('Error updating batch notifications:', error);
    }
  }, [keyword.id, keyword.exchanges, selectedExchanges, getNotificationValue, onUpdateBatchNotifications]);

  const allNotificationStates = {
    realtime_enabled: {
      someEnabled: keyword.exchanges.some(exchange => getNotificationValue(exchange, 'realtime_enabled') === 1),
      allEnabled: keyword.exchanges.every(exchange => getNotificationValue(exchange, 'realtime_enabled') === 1)
    },
    daily_enabled: {
      someEnabled: keyword.exchanges.some(exchange => getNotificationValue(exchange, 'daily_enabled') === 1),
      allEnabled: keyword.exchanges.every(exchange => getNotificationValue(exchange, 'daily_enabled') === 1)
    },
    weekly_enabled: {
      someEnabled: keyword.exchanges.some(exchange => getNotificationValue(exchange, 'weekly_enabled') === 1),
      allEnabled: keyword.exchanges.every(exchange => getNotificationValue(exchange, 'weekly_enabled') === 1)
    },
    smart_scan_enabled: {
      someEnabled: keyword.exchanges.some(exchange => getNotificationValue(exchange, 'smart_scan_enabled') === 1),
      allEnabled: keyword.exchanges.every(exchange => getNotificationValue(exchange, 'smart_scan_enabled') === 1)
    }
  };

  return (
    <div className="space-y-4">
      <div className="w-full overflow-x-hidden">
        <table className="w-full" style={{ tableLayout: 'fixed' }}>
          <thead>
            <tr>
              <th style={columnStyles.exchange}>
                <span className="text-xs font-medium text-gray-500 uppercase">Exchange</span>
              </th>
              <th style={columnStyles.emailAlerts} colSpan="4">
                <span className="text-xs font-medium text-gray-500 uppercase">Email Alerts</span>
              </th>
              <th style={columnStyles.rss}>
                <span className="text-xs font-medium text-gray-500 uppercase">RSS</span>
              </th>
            </tr>
            <tr>
              <th style={columnStyles.exchange}>
                <div className="flex items-center space-x-4">
                  <input
                    type="checkbox"
                    checked={selectedExchanges.size === keyword.exchanges.length}
                    onChange={handleSelectAll}
                    className={checkboxStyle}
                  />
                  {selectedExchanges.size > 0 && (
                    <>
                      <span className="text-sm text-gray-600 mr-4">
                        {selectedExchanges.size} selected
                      </span>
                      <button
                        onClick={handleDeleteSelected}
                        className="flex items-center text-sm text-red-600 hover:text-red-700"
                      >
                        <Trash2 className="w-4 h-4 mr-1" />
                        Delete
                      </button>
                    </>
                  )}
                </div>
              </th>
              <th style={columnStyles.emailAlerts} colSpan="4">
                <div className={EMAIL_ALERTS_GRID}>
                  <div className={EMAIL_ALERT_CELL}>
                    <input
                      type="checkbox"
                      checked={allNotificationStates.realtime_enabled.someEnabled}
                      className={`${checkboxStyle} ${
                        allNotificationStates.realtime_enabled.someEnabled && 
                        !allNotificationStates.realtime_enabled.allEnabled ? 
                        'bg-indigo-300' : ''
                      }`}
                      onChange={() => handleBatchNotificationChange('realtime_enabled')}
                    />
                    <span className="ml-2 text-xs font-medium text-gray-500 uppercase">
                      Realtime
                    </span>
                  </div>
                  <div className={EMAIL_ALERT_CELL}>
                    <input
                      type="checkbox"
                      checked={allNotificationStates.daily_enabled.someEnabled}
                      className={`${checkboxStyle} ${
                        allNotificationStates.daily_enabled.someEnabled && 
                        !allNotificationStates.daily_enabled.allEnabled ? 
                        'bg-indigo-300' : ''
                      }`}
                      onChange={() => handleBatchNotificationChange('daily_enabled')}
                    />
                    <span className="ml-2 text-xs font-medium text-gray-500 uppercase">
                      Daily
                    </span>
                  </div>
                  <div className={EMAIL_ALERT_CELL}>
                    <input
                      type="checkbox"
                      checked={allNotificationStates.weekly_enabled.someEnabled}
                      className={`${checkboxStyle} ${
                        allNotificationStates.weekly_enabled.someEnabled && 
                        !allNotificationStates.weekly_enabled.allEnabled ? 
                        'bg-indigo-300' : ''
                      }`}
                      onChange={() => handleBatchNotificationChange('weekly_enabled')}
                    />
                    <span className="ml-2 text-xs font-medium text-gray-500 uppercase">
                      Weekly
                    </span>
                  </div>
                  {isFeedsPanelVisible && (
                    <div className={EMAIL_ALERT_CELL}>
                      <input
                        type="checkbox"
                        checked={allNotificationStates.smart_scan_enabled.someEnabled}
                        className={`${checkboxStyle} ${
                          allNotificationStates.smart_scan_enabled.someEnabled && 
                          !allNotificationStates.smart_scan_enabled.allEnabled ? 
                          'bg-indigo-300' : ''
                        }`}
                        onChange={() => handleBatchNotificationChange('smart_scan_enabled')}
                      />
                      <span className="ml-2 text-xs font-medium text-gray-500 uppercase">
                        Smart Scan
                      </span>
                    </div>
                  )}
                </div>
              </th>
              <th style={columnStyles.rss}></th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {keyword.exchanges.map((exchange) => (
              <tr key={exchange} className="hover:bg-gray-50 group">
                <td style={columnStyles.exchange}>
                  <div className="flex items-center">
                    <div className="w-4 mr-4">
                      <input
                        type="checkbox"
                        checked={selectedExchanges.has(exchange)}
                        onChange={() => toggleExchange(exchange)}
                        className={`${selectedExchanges.has(exchange) ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'} ${checkboxStyle}`}
                      />
                    </div>
                    <span className="text-sm font-medium text-gray-900">
                      {EXCHANGE_MAP[exchange] || exchange}
                    </span>
                  </div>
                </td>
                <td style={columnStyles.emailAlerts} colSpan="4">
                  <div className={EMAIL_ALERTS_GRID}>
                    <div className={EMAIL_ALERT_CELL}>
                      <input
                        type="checkbox"
                        checked={getNotificationValue(exchange, 'realtime_enabled') === 1}
                        className={checkboxStyle}
                        onChange={() => handleNotificationChange(
                          exchange, 
                          'realtime_enabled', 
                          getNotificationValue(exchange, 'realtime_enabled')
                        )}
                      />
                    </div>
                    <div className={EMAIL_ALERT_CELL}>
                      <input
                        type="checkbox"
                        checked={getNotificationValue(exchange, 'daily_enabled') === 1}
                        className={checkboxStyle}
                        onChange={() => handleNotificationChange(
                          exchange, 
                          'daily_enabled', 
                          getNotificationValue(exchange, 'daily_enabled')
                        )}
                      />
                    </div>
                    <div className={EMAIL_ALERT_CELL}>
                      <input
                        type="checkbox"
                        checked={getNotificationValue(exchange, 'weekly_enabled') === 1}
                        className={checkboxStyle}
                        onChange={() => handleNotificationChange(
                          exchange, 
                          'weekly_enabled', 
                          getNotificationValue(exchange, 'weekly_enabled')
                        )}
                      />
                    </div>
                    {isFeedsPanelVisible && (
                      <div className={EMAIL_ALERT_CELL}>
                        <input
                          type="checkbox"
                          checked={getNotificationValue(exchange, 'smart_scan_enabled') === 1}
                          className={checkboxStyle}
                          onChange={() => handleNotificationChange(
                            exchange, 
                            'smart_scan_enabled', 
                            getNotificationValue(exchange, 'smart_scan_enabled')
                          )}
                        />
                      </div>
                    )}
                  </div>
                </td>
                <td style={columnStyles.rss} className="text-center">
                  <Rss 
                    className={`w-4 h-4 ${
                      loadingRss[exchange] 
                        ? 'text-gray-300' 
                        : rssIds[exchange]
                          ? 'text-secondary-accent cursor-pointer'
                          : 'text-gray-400 hover:text-secondary-accent cursor-pointer'
                    } inline-block`}
                    onClick={() => handleRssClick(exchange)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExchangeAlertTable;