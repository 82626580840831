import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useFilingContext } from '../contexts/FilingContext';
import FilingsTable from '../components/FilingsTable';

export function SavedView() {
  const { user, getIdToken } = useAuth();
  const { refreshTrigger } = useFilingContext();
  const [filings, setFilings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadSavedFilings = async () => {
    if (!user) {
      setFilings([]);
      return;
    }

    try {
      setLoading(true);
      const token = await getIdToken();
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/saved-filings`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to load saved filings');
      }

      const data = await response.json();
      setFilings(data);
    } catch (err) {
      console.error('Error loading saved filings:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Load saved filings when component mounts or refresh is triggered
  useEffect(() => {
    loadSavedFilings();
  }, [user, refreshTrigger]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="flex flex-col h-full w-full">
      <div className="py-8 px-4 sm:px-6 lg:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">Saved Filings</h1>
      </div>
      <div className="flex-1">
        <FilingsTable 
          filings={filings} 
          loading={loading} 
          error={error}
        />
      </div>
    </div>
  );
}

export default SavedView;