import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';

const useCompanyData = (stockCode) => {
  const { user, getIdToken } = useAuth();
  const [companyData, setCompanyData] = useState({
    chartData: [],
    fundamentals: {},
    profile: {},
    loading: true,
    error: null
  });

  useEffect(() => {
    const fetchCompanyData = async () => {
      if (!stockCode) return;

      try {
        const headers = {
          'Content-Type': 'application/json'
        };

        if (user) {
          const token = await getIdToken();
          if (token) {
            headers['Authorization'] = `Bearer ${token}`;
          }
        }

        const url = `${process.env.REACT_APP_API_URL}/company/${encodeURIComponent(stockCode)}/data`;
        
        const response = await fetch(url, { headers });
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        
        setCompanyData({
          chartData: data.stockPrices || [],
          fundamentals: {
            marketCap: data.marketCap,
            peRatio: data.peRatio,
            eps: data.eps,
            dividend: data.dividendYield,
            volume: data.volume
          },
          profile: {
            description: data.description,
            sector: data.sector,
            industry: data.industry,
            employees: data.employees,
            founded: data.founded
          },
          loading: false,
          error: null
        });
      } catch (error) {
        console.error('Error fetching company data:', error);
        setCompanyData(prev => ({
          ...prev,
          loading: false,
          error: error.message
        }));
      }
    };

    setCompanyData(prev => ({ ...prev, loading: true }));
    fetchCompanyData();
  }, [stockCode, user, getIdToken]);

  return companyData;
};

export default useCompanyData;