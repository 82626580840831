// src/config/firebase.js

const firebaseConfig = {
    apiKey: "AIzaSyCtFWFUKKvqaHogo0OeUd_Reqk2A_8xO6E",
    authDomain: "filingreader-landing-page.firebaseapp.com",
    databaseURL: "https://filingreader-landing-page-default-rtdb.firebaseio.com",
    projectId: "filingreader-landing-page",
    storageBucket: "filingreader-landing-page.firebasestorage.app",
    messagingSenderId: "967854680741",
    appId: "1:967854680741:web:8e4f0b40278b6985223b4c",
    measurementId: "G-YE6CH2MG43"
  };
  
  export default firebaseConfig;