import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { 
  User, 
  Mail, 
  Building2,
  Briefcase,
  Phone,
  MapPin,
  Pencil,
  Save,
  X,
  Upload
} from 'lucide-react';
import DeleteAccountSection from '../components/profile/DeleteAccountSection';

const ProfileSection = ({ title, icon: Icon, children, onEdit, isEditing, onSave, onCancel }) => (
  <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
    <div className="flex justify-between items-center mb-4">
      <div className="flex items-center gap-2">
        {Icon && <Icon className="w-5 h-5 text-gray-400" />}
        <h2 className="text-lg font-semibold">{title}</h2>
      </div>
      <div className="flex gap-2">
        {isEditing ? (
          <>
            <button
              onClick={onSave}
              className="flex items-center gap-2 px-3 py-1 text-sm bg-secondary-DEFAULT text-white rounded-lg hover:bg-secondary-accent transition-colors duration-200"
            >
              <Save className="w-4 h-4" />
              Save
            </button>
            <button
              onClick={onCancel}
              className="flex items-center gap-2 px-3 py-1 text-sm border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              <X className="w-4 h-4" />
              Cancel
            </button>
          </>
        ) : (
          <button
            onClick={onEdit}
            className="flex items-center gap-2 px-3 py-1 text-sm text-gray-600 hover:bg-gray-100 rounded-lg transition-colors duration-200"
          >
            <Pencil className="w-4 h-4" />
            Edit
          </button>
        )}
      </div>
    </div>
    {children}
  </div>
);

const ProfileView = () => {
  const { user, updateUserProfile, deleteAccount, getIdToken } = useAuth();
  const [editingSections, setEditingSections] = useState({
    profile: false
  });
  
  const [formData, setFormData] = useState({
    profile: {
      fullName: user?.fullName || '',
      email: user?.email || '',
      jobTitle: user?.jobTitle || '',
      company: user?.company || '',
      phoneNumber: user?.phoneNumber || '',
      location: user?.location || '',
      bio: user?.bio || ''
    }
  });

  const [profileImage, setProfileImage] = useState(user?.profileImage || null);
  const [originalData, setOriginalData] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    // Keep a copy of the original data for cancellation
    setOriginalData(formData);
  }, []);

  const handleInputChange = (section, field, value) => {
    setFormData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value
      }
    }));
  };

  const handleEdit = (section) => {
    setEditingSections(prev => ({
      ...prev,
      [section]: true
    }));
  };

  const handleCancel = (section) => {
    setFormData(prev => ({
      ...prev,
      [section]: originalData[section]
    }));
    setEditingSections(prev => ({
      ...prev,
      [section]: false
    }));
  };

  const handleProfileImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        // Here you would typically upload the file to your server
        // and get back the URL. This is a placeholder for that logic.
        // Create a FormData object to send the file
      const formData = new FormData();
      formData.append('image', file);

      // Make API call to upload image
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/profile-image`, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${await getIdToken()}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to upload image');
      }

      const { imageUrl } = await response.json();
      setProfileImage(imageUrl);
        
        // Update the user profile with the new image URL
        await updateUserProfile({
          ...user,
          profileImage: imageUrl
        });
      } catch (error) {
        console.error('Error uploading profile image:', error);
      }
    }
  };

  const handleSave = async (section) => {
    setIsSaving(true);
    try {
      await updateUserProfile({
        ...user,
        ...formData[section]
      });
      setEditingSections(prev => ({
        ...prev,
        [section]: false
      }));
      setOriginalData(prev => ({
        ...prev,
        [section]: formData[section]
      }));
    } catch (error) {
      console.error(`Error updating ${section} information:`, error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto py-8 px-4">
      {/* Profile Header */}
      <div className="flex items-center gap-6 mb-8">
        <div className="relative">
          <div className="w-24 h-24 rounded-full bg-gray-200 overflow-hidden">
            {profileImage ? (
              <img 
                src={profileImage} 
                alt={formData.profile.fullName} 
                className="w-full h-full object-cover"
              />
            ) : (
              <User className="w-full h-full p-4 text-gray-400" />
            )}
          </div>
          <label className="absolute bottom-0 right-0 p-1 bg-white rounded-full shadow-lg cursor-pointer">
            <input 
              type="file" 
              accept="image/*"
              onChange={handleProfileImageChange}
              className="hidden"
            />
            <Upload className="w-4 h-4 text-gray-600" />
          </label>
        </div>
        <div>
          <h1 className="text-2xl font-bold">{formData.profile.fullName || 'Your Name'}</h1>
          <p className="text-gray-500">{formData.profile.jobTitle} {formData.profile.company ? `at ${formData.profile.company}` : ''}</p>
        </div>
      </div>

      {/* Profile Information */}
      <ProfileSection 
        title="Profile Information"
        icon={User}
        isEditing={editingSections.profile}
        onEdit={() => handleEdit('profile')}
        onSave={() => handleSave('profile')}
        onCancel={() => handleCancel('profile')}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Full Name
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <User className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                value={formData.profile.fullName}
                onChange={(e) => handleInputChange('profile', 'fullName', e.target.value)}
                disabled={!editingSections.profile}
                className="pl-10 w-full rounded-lg border border-gray-300 focus:ring-2 focus:ring-secondary-DEFAULT focus:border-transparent disabled:bg-gray-50"
              />
            </div>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Email
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Mail className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="email"
                value={formData.profile.email}
                onChange={(e) => handleInputChange('profile', 'email', e.target.value)}
                disabled={!editingSections.profile}
                className="pl-10 w-full rounded-lg border border-gray-300 focus:ring-2 focus:ring-secondary-DEFAULT focus:border-transparent disabled:bg-gray-50"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Job Title
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Briefcase className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                value={formData.profile.jobTitle}
                onChange={(e) => handleInputChange('profile', 'jobTitle', e.target.value)}
                disabled={!editingSections.profile}
                className="pl-10 w-full rounded-lg border border-gray-300 focus:ring-2 focus:ring-secondary-DEFAULT focus:border-transparent disabled:bg-gray-50"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Company
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Building2 className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                value={formData.profile.company}
                onChange={(e) => handleInputChange('profile', 'company', e.target.value)}
                disabled={!editingSections.profile}
                className="pl-10 w-full rounded-lg border border-gray-300 focus:ring-2 focus:ring-secondary-DEFAULT focus:border-transparent disabled:bg-gray-50"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Phone Number
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Phone className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="tel"
                value={formData.profile.phoneNumber}
                onChange={(e) => handleInputChange('profile', 'phoneNumber', e.target.value)}
                disabled={!editingSections.profile}
                className="pl-10 w-full rounded-lg border border-gray-300 focus:ring-2 focus:ring-secondary-DEFAULT focus:border-transparent disabled:bg-gray-50"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Location
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <MapPin className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                value={formData.profile.location}
                onChange={(e) => handleInputChange('profile', 'location', e.target.value)}
                disabled={!editingSections.profile}
                className="pl-10 w-full rounded-lg border border-gray-300 focus:ring-2 focus:ring-secondary-DEFAULT focus:border-transparent disabled:bg-gray-50"
              />
            </div>
          </div>

          <div className="col-span-2">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Bio
            </label>
            <textarea
              value={formData.profile.bio}
              onChange={(e) => handleInputChange('profile', 'bio', e.target.value)}
              disabled={!editingSections.profile}
              rows={4}
              className="w-full rounded-lg border border-gray-300 focus:ring-2 focus:ring-secondary-DEFAULT focus:border-transparent disabled:bg-gray-50"
              placeholder="Tell us about yourself..."
            />
          </div>
        </div>
      </ProfileSection>

      {/* Delete Account Section */}
      <DeleteAccountSection onDeleteAccount={deleteAccount} />
    </div>
  );
};

export default ProfileView;