import React from 'react';
import { X } from 'lucide-react';

const UnfollowWatchlistDialog = ({ isOpen, onClose, watchlist, onUnfollow }) => {
  if (!isOpen || !watchlist) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
        <div className="fixed inset-0 bg-black bg-opacity-25 transition-opacity" onClick={onClose} />
        
        <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
          <div className="bg-white p-6">
            {/* Header */}
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold text-gray-900">
                Unfollow Watchlist
              </h3>
              <button
                onClick={onClose}
                className="text-gray-400 hover:text-gray-500 transition-colors"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            {/* Content */}
            <div className="mt-2">
              <p className="text-gray-600">
                Are you sure you want to unfollow <span className="font-medium text-gray-900">{watchlist.name}</span>? 
                You will no longer receive updates from this watchlist.
              </p>
            </div>
            
            {/* Actions */}
            <div className="mt-6 flex justify-end space-x-3">
              <button
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800 
                         bg-white border border-gray-300 rounded-md shadow-sm 
                         hover:bg-gray-50 focus:outline-none"
              >
                Cancel
              </button>
              <button
                onClick={onUnfollow}
                className="px-4 py-2 text-sm font-medium text-white 
                         bg-red-600 hover:bg-red-700 rounded-md shadow-sm 
                         focus:outline-none"
              >
                Unfollow
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnfollowWatchlistDialog;