// src/views/SettingsView.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Sun, Moon, Monitor } from 'lucide-react';
import DeleteAccountSection from '../components/profile/DeleteAccountSection';

const ThemeOption = ({ icon: Icon, label, value, currentValue, onChange }) => (
  <div
    onClick={() => onChange(value)}
    className={`flex items-center gap-3 p-4 rounded-lg cursor-pointer transition-all duration-200 ${
      currentValue === value 
        ? 'bg-secondary-DEFAULT text-white shadow-lg shadow-secondary-DEFAULT/20' 
        : 'bg-white hover:bg-gray-50'
    }`}
  >
    <Icon className="w-5 h-5" />
    <span className="font-medium">{label}</span>
  </div>
);

const SettingsView = () => {
  const { user, updateUserProfile } = useAuth();
  const [formData, setFormData] = useState({
    theme: 'system', // system, light, or dark
    compactMode: false,
    showTotalBalance: true,
    hideDeletedFilings: false,
    enableKeyboardShortcuts: true
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (user?.preferences) {
      setFormData(prev => ({
        ...prev,
        ...user.preferences
      }));
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      await updateUserProfile({
        ...user,
        preferences: formData
      });
      setMessage('Settings updated successfully');
    } catch (error) {
      setMessage('Error updating settings: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (name, value) => {
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="mb-6">
        <h1 className="text-2xl font-semibold text-gray-900">Settings</h1>
        <p className="text-gray-500 mt-1">Customize your app experience</p>
      </div>

      <div className="space-y-6">
        {/* Appearance Settings */}
        <div className="bg-gray-50 rounded-lg p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Appearance</h3>
          
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-3">
              Theme
            </label>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
              <ThemeOption
                icon={Monitor}
                label="System"
                value="system"
                currentValue={formData.theme}
                onChange={(value) => handleInputChange('theme', value)}
              />
              <ThemeOption
                icon={Sun}
                label="Light"
                value="light"
                currentValue={formData.theme}
                onChange={(value) => handleInputChange('theme', value)}
              />
              <ThemeOption
                icon={Moon}
                label="Dark"
                value="dark"
                currentValue={formData.theme}
                onChange={(value) => handleInputChange('theme', value)}
              />
            </div>
          </div>

          <div className="space-y-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={formData.compactMode}
                onChange={(e) => handleInputChange('compactMode', e.target.checked)}
                className="rounded border-gray-300 text-secondary-DEFAULT focus:ring-secondary-DEFAULT"
              />
              <span className="ml-2 text-gray-700">Compact mode</span>
            </label>
          </div>
        </div>

        {/* Display Settings */}
        <div className="bg-gray-50 rounded-lg p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Display</h3>
          <div className="space-y-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={formData.showTotalBalance}
                onChange={(e) => handleInputChange('showTotalBalance', e.target.checked)}
                className="rounded border-gray-300 text-secondary-DEFAULT focus:ring-secondary-DEFAULT"
              />
              <span className="ml-2 text-gray-700">Show total balance in header</span>
            </label>

            <label className="flex items-center">
              <input
                type="checkbox"
                checked={formData.hideDeletedFilings}
                onChange={(e) => handleInputChange('hideDeletedFilings', e.target.checked)}
                className="rounded border-gray-300 text-secondary-DEFAULT focus:ring-secondary-DEFAULT"
              />
              <span className="ml-2 text-gray-700">Hide deleted filings</span>
            </label>
          </div>
        </div>

        {/* Advanced Settings */}
        <div className="bg-gray-50 rounded-lg p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Advanced</h3>
          <div className="space-y-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={formData.enableKeyboardShortcuts}
                onChange={(e) => handleInputChange('enableKeyboardShortcuts', e.target.checked)}
                className="rounded border-gray-300 text-secondary-DEFAULT focus:ring-secondary-DEFAULT"
              />
              <span className="ml-2 text-gray-700">Enable keyboard shortcuts</span>
            </label>
          </div>
        </div>

        {/* Save Button */}
        <div className="flex justify-end">
          <button
            type="button"
            onClick={handleSubmit}
            disabled={loading}
            className="px-4 py-2 bg-secondary-DEFAULT text-white rounded-lg hover:bg-secondary-accent transition-colors duration-200 disabled:opacity-50"
          >
            {loading ? 'Saving...' : 'Save Changes'}
          </button>
        </div>

        {/* Form Message */}
        {message && (
          <div 
            className={`p-4 rounded-md ${
              message.includes('Error') 
                ? 'bg-red-50 text-red-700' 
                : 'bg-green-50 text-green-700'
            }`}
          >
            {message}
          </div>
        )}

        {/* Delete Account Section */}
        <div className="border-t border-gray-200 pt-6">
          <DeleteAccountSection />
        </div>
      </div>
    </div>
  );
};

export default SettingsView;