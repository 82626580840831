// src/components/FilingsTable.js
import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Loader2, ChevronRight } from 'lucide-react';
import { formatDateTime, formatStockCode } from '../utils/formatters';
import { SaveFilingButton } from './SaveFilingButton';
import { useSubscription } from '../hooks/useSubscription';
import HighlightedTitle from './HighlightedTitle';

const FilingsTable = ({ 
  filings, 
  loading, 
  error, 
  onLoadMore, 
  showCompanyInfo = true,
  hasMore,
  onFilingClick,
  selectedKeywords = []
}) => {
  const navigate = useNavigate();
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredTitle, setHoveredTitle] = useState(null); 
  const [expandedRows, setExpandedRows] = useState(new Set());
  const observerRef = useRef(null);
  const targetRef = useRef(null);
  
  // Only check subscription status once when component mounts
  const { canAccessProFeatures } = useSubscription();

  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }
  
    if (onLoadMore && targetRef.current && hasMore) {
      observerRef.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && !loading) {
            onLoadMore();
          }
        },
        { 
          root: null,
          rootMargin: '100px',
          threshold: 0
        }
      );
  
      observerRef.current.observe(targetRef.current);
    }
  
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [onLoadMore, loading, hasMore]);

  const toggleRowExpansion = (index) => {
    const newExpandedRows = new Set(expandedRows);
    if (expandedRows.has(index)) {
      newExpandedRows.delete(index);
    } else {
      newExpandedRows.add(index);
    }
    setExpandedRows(newExpandedRows);
  };

  const handleFilingClick = (e, filing) => {
    e.preventDefault();
    // Open in new tab regardless of subscription status
    window.open(`/filing/${filing.bulletinIds}/view`, '_blank');
    if (onFilingClick) {
      onFilingClick(filing.bulletinIds);
    }
  };

  if (error) {
    return (
      <div className="bg-red-50 p-4 rounded-lg">
        <div className="flex flex-col items-center text-center">
          <p className="text-red-600 mb-2">Error loading filings: {error}</p>
          <button
            onClick={onLoadMore}
            className="px-4 py-2 bg-red-100 text-red-700 rounded-lg hover:bg-red-200 transition-colors"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-4">
      <div className="bg-white rounded-lg shadow-sm overflow-hidden w-full min-w-0">
        <div className="overflow-x-auto w-full">
          <table className="w-full table-fixed">
            <thead>
              <tr className="bg-white border-b border-gray-200">
                <th className="w-8"></th>
                {showCompanyInfo && (
                  <>
                    <th className="px-2 py-1.5 text-left text-xs font-medium text-light-text-secondary uppercase tracking-wider w-28">
                      Stock Codes
                    </th>
                    <th className="px-2 py-1.5 text-left text-xs font-medium text-light-text-secondary uppercase tracking-wider w-40 sm:w-44 md:w-48 lg:w-52 xl:w-56">
                      Company Name
                    </th>
                  </>
                )}
                <th className="px-2 py-1.5 text-left text-xs font-medium text-light-text-secondary uppercase tracking-wider flex-1">
                  Title
                </th>
                <th className="px-2 py-1.5 text-center text-xs font-medium text-light-text-secondary uppercase tracking-wider w-16">
                  Pages
                </th>
                <th className="px-2 py-1.5 text-left text-xs font-medium text-light-text-secondary uppercase tracking-wider w-40">
                  Published
                </th>
                <th className="w-8"></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100">
              {filings.length === 0 && !loading ? (
                <tr>
                  <td colSpan={showCompanyInfo ? 7 : 4} className="px-2 py-4 text-center text-light-text-tertiary bg-white">
                    No filings found
                  </td>
                </tr>
              ) : (
                filings.map((filing, index) => {
                  const isHovered = hoveredRow === index;
                  const isExpanded = expandedRows.has(index);
                  
                  return (
                    <tr 
                      key={`${filing.stockCodes}-${filing.timeStamps}-${index}`}
                      className={`
                        transition-colors group
                        ${filing.isRead ? 'bg-gray-50 text-gray-500' : 'bg-white hover:bg-gray-50'}
                      `}
                      onMouseEnter={() => setHoveredRow(index)}
                      onMouseLeave={() => setHoveredRow(null)}
                    >
                      <td className="w-8 py-1.5 text-center">
                        <button 
                          onClick={() => toggleRowExpansion(index)}
                          className={`
                            hover:text-secondary-accent transition-colors opacity-50 group-hover:opacity-100
                            ${filing.isRead ? 'text-gray-400' : 'text-light-text-tertiary'}
                          `}
                          aria-label={isExpanded ? "Collapse row" : "Expand row"}
                        >
                          <ChevronRight 
                            className={`w-4 h-4 transition-transform duration-200 ${
                              isExpanded ? 'rotate-90' : ''
                            }`}
                          />
                        </button>
                      </td>
                      {showCompanyInfo && (
                        <>
                          <td className="px-2 py-1.5 whitespace-nowrap">
                            <span className={`
                              inline-flex text-xs font-mono px-1.5 py-0.5 rounded
                              ${filing.isRead ? 'bg-gray-100 text-gray-500' : 'bg-light-bg-tertiary text-light-text-DEFAULT'}
                            `}>
                              {formatStockCode(filing.stockCodes)}
                            </span>
                          </td>
                          <td className="px-2 py-1.5">
                            <div className={isExpanded ? '' : 'truncate'}>
                              <Link 
                                to={`/company/${encodeURIComponent(filing.stockCodes)}`}
                                className={`
                                  text-sm font-medium hover:text-secondary-accent
                                  ${filing.isRead ? 'text-gray-500' : 'text-light-text-DEFAULT'}
                                `}
                              >
                                {filing.longNameEn}
                              </Link>
                            </div>
                          </td>
                        </>
                      )}
                      <td className="px-2 py-1.5">
                        <div className={`group/title relative ${isExpanded ? '' : 'truncate'}`}>
                          <a 
                            href={`/filing/${filing.bulletinIds}/view`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => handleFilingClick(e, filing)}
                            onMouseEnter={() => setHoveredTitle(filing.bulletinIds)}
                            onMouseLeave={() => setHoveredTitle(null)}
                            className={`text-sm font-medium flex items-center hover:text-secondary-accent ${
                              filing.isRead ? 'text-gray-500' : 'text-light-text-DEFAULT'
                            }`}
                          >
                            <HighlightedTitle
                              title={filing.titles || 'No title available'}
                              translatedTitle={filing.titlesTranslated}
                              hoveredTitle={hoveredTitle}
                              originalTitle={filing.bulletinIds}
                              keywords={selectedKeywords}
                            />
                          </a>
                        </div>
                      </td>
                      <td className="px-2 py-1.5 text-center">
                        <span className={`
                          inline-flex items-center justify-center min-w-[24px] text-xs font-medium px-1.5 py-0.5 rounded
                          ${filing.isRead ? 'bg-gray-100 text-gray-500' : 'bg-light-bg-tertiary text-light-text-DEFAULT'}
                        `}>
                          {filing.pageCount || '-'}
                        </span>
                      </td>
                      <td className="px-2 py-1.5 whitespace-nowrap">
                        <span className={`
                          text-sm
                          ${filing.isRead ? 'text-gray-400' : 'text-light-text-tertiary'}
                        `}>
                          {formatDateTime(filing.timeStamps)}
                        </span>
                      </td>
                      <td className="px-2 py-1.5 whitespace-nowrap text-right text-sm font-medium">
                        <SaveFilingButton filing={filing} />
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>

      {(filings.length > 0 || loading) && (
        <div 
          ref={targetRef}
          className="h-16 flex items-center justify-center"
        >
          {loading ? (
            <Loader2 className="w-6 h-6 animate-spin text-secondary-DEFAULT" />
          ) : hasMore ? (
            null
          ) : (
            <span className="text-sm text-light-text-tertiary">
              No more items to load
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default FilingsTable;