import React, { createContext, useState, useContext, useEffect } from 'react';
import { 
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  sendEmailVerification,
  EmailAuthProvider,
  reauthenticateWithCredential,
  onAuthStateChanged
} from 'firebase/auth';
import { auth } from '../config/firebase-init';

const AuthContext = createContext({});

// Firebase error message mapping
const getErrorMessage = (errorCode) => {
  const errorMessages = {
    'auth/email-already-in-use': 'An account already exists with this email address.',
    'auth/invalid-email': 'Please enter a valid email address.',
    'auth/operation-not-allowed': 'Email/password accounts are not enabled. Please contact support.',
    'auth/weak-password': 'Please choose a stronger password. It should be at least 6 characters long.',
    'auth/user-disabled': 'This account has been disabled. Please contact support.',
    'auth/user-not-found': 'No account found with this email address.',
    'auth/wrong-password': 'Incorrect password. Please try again.',
    'auth/too-many-requests': 'Too many unsuccessful login attempts. Please try again later.',
    'auth/popup-closed-by-user': 'The sign in popup was closed before completing the sign in.',
    'auth/cancelled-popup-request': 'The sign in was cancelled.',
    'auth/popup-blocked': 'The sign in popup was blocked by your browser.',
    'auth/network-request-failed': 'A network error occurred. Please check your connection and try again.'
  };

  return errorMessages[errorCode] || 'An error occurred. Please try again.';
};

// Token cache management with expiry prediction
const TOKEN_CACHE = {
  token: null,
  expiryTime: null,
  refreshPromise: null
};

const TOKEN_REFRESH_BUFFER = 5 * 60 * 1000;
const TOKEN_EXPIRY_DURATION = 60 * 60 * 1000;

const getIdToken = async (forceRefresh = false) => {
  const now = Date.now();

  if (!forceRefresh && 
      TOKEN_CACHE.token && 
      TOKEN_CACHE.expiryTime && 
      now < TOKEN_CACHE.expiryTime - TOKEN_REFRESH_BUFFER) {
    return TOKEN_CACHE.token;
  }

  if (TOKEN_CACHE.refreshPromise) {
    return TOKEN_CACHE.refreshPromise;
  }

  TOKEN_CACHE.refreshPromise = (async () => {
    try {
      // Add retry logic for auth initialization
      let attempts = 0;
      while (!auth.currentUser && attempts < 10) {
        await new Promise(resolve => setTimeout(resolve, 500));
        attempts++;
      }

      if (!auth.currentUser) {
        throw new Error('No authenticated user');
      }

      const token = await auth.currentUser.getIdToken(forceRefresh);
      TOKEN_CACHE.token = token;
      TOKEN_CACHE.expiryTime = now + TOKEN_EXPIRY_DURATION;
      return token;
    } finally {
      TOKEN_CACHE.refreshPromise = null;
    }
  })();

  return TOKEN_CACHE.refreshPromise;
};

const clearTokenCache = () => {
  TOKEN_CACHE.token = null;
  TOKEN_CACHE.expiryTime = null;
  TOKEN_CACHE.refreshPromise = null;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [profileCache, setProfileCache] = useState(new Map());

  const refreshUserProfile = async () => {
    if (!auth.currentUser) return;

    const cacheKey = `profile_${auth.currentUser.uid}`;
    const cached = profileCache.get(cacheKey);
    if (cached && Date.now() - cached.timestamp < 60000) {
      setUser(prev => ({
        ...prev,
        ...cached.data
      }));
      return;
    }

    try {
      const token = await getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/profile`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch profile');
      }

      const profile = await response.json();
      setUser(prev => ({
        ...prev,
        ...profile,
        subscription_status: profile.subscription_status || 'free',
        is_premium: profile.is_premium || false,
        is_team: profile.is_team || false,
      }));

      setProfileCache(prev => new Map(prev).set(cacheKey, {
        data: profile,
        timestamp: Date.now()
      }));
    } catch (error) {
      console.error('Error refreshing user profile:', error);
    }
  };

  const createUserInMySQL = async (firebaseUser) => {
    try {
      const token = await getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          firebase_uid: firebaseUser.uid,
          email: firebaseUser.email,
          has_completed_onboarding: false
        })
      });

      if (!response.ok && response.status !== 409) {
        throw new Error('Failed to create user in MySQL');
      }
    } catch (error) {
      console.error('Error creating user in MySQL:', error);
      throw error;
    }
  };

  useEffect(() => {
    let unsubscribe;
    let isMounted = true;

    const initializeAuth = async () => {
      unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
        try {
          if (firebaseUser) {
            // Set basic user info immediately
            const basicUserInfo = {
              uid: firebaseUser.uid,
              email: firebaseUser.email,
              displayName: firebaseUser.displayName,
              photoURL: firebaseUser.photoURL,
              emailVerified: firebaseUser.emailVerified,
              hasCompletedOnboarding: false,
              subscription_status: 'free',
              is_premium: false,
              is_team: false
            };
            
            if (isMounted) {
              setUser(basicUserInfo);
            }

            try {
              const token = await getIdToken();
              const [profileResponse, loginResponse] = await Promise.all([
                fetch(`${process.env.REACT_APP_API_URL}/users/profile`, {
                  headers: {
                    'Authorization': `Bearer ${token}`
                  }
                }),
                fetch(`${process.env.REACT_APP_API_URL}/users/login`, {
                  method: 'POST',
                  headers: {
                    'Authorization': `Bearer ${token}`
                  }
                })
              ]);

              if (!profileResponse.ok) {
                throw new Error('Failed to fetch profile');
              }

              const profile = await profileResponse.json();
              
              if (isMounted) {
                setUser(prev => ({
                  ...prev,
                  ...profile
                }));
              }
            } catch (error) {
              if (error.message.includes('401')) {
                await createUserInMySQL(firebaseUser);
                await refreshUserProfile();
              } else {
                throw error;
              }
            }
          } else {
            if (isMounted) {
              setUser(null);
              clearTokenCache();
            }
          }
        } catch (error) {
          console.error('Error in auth state change:', error);
          if (firebaseUser && isMounted) {
            setUser({
              uid: firebaseUser.uid,
              email: firebaseUser.email,
              displayName: firebaseUser.displayName,
              photoURL: firebaseUser.photoURL,
              emailVerified: firebaseUser.emailVerified,
              hasCompletedOnboarding: false,
              subscription_status: 'free',
              is_premium: false,
              is_team: false
            });
          }
        } finally {
          if (isMounted) {
            setLoading(false);
          }
        }
      });
    };

    initializeAuth();

    return () => {
      isMounted = false;
      if (unsubscribe) unsubscribe();
    };
  }, []);

  // Existing auth methods...
  const signUp = async (email, password) => {
    try {
      setError(null);
      const result = await createUserWithEmailAndPassword(auth, email, password);
      await createUserInMySQL(result.user);
      try {
        await sendEmailVerification(result.user);
      } catch (err) {
        console.error('Error sending verification email:', err);
      }
      return result.user;
    } catch (err) {
      console.error('Sign up error:', err);
      setError(getErrorMessage(err.code));
      throw err;
    }
  };

  const signIn = async (email, password) => {
    try {
      setError(null);
      const result = await signInWithEmailAndPassword(auth, email, password);
      return result.user;
    } catch (err) {
      setError(getErrorMessage(err.code));
      throw err;
    }
  };

  const signInWithGoogle = async () => {
    try {
      setError(null);
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account'
      });
      const result = await signInWithPopup(auth, provider);
      await createUserInMySQL(result.user);
      return result.user;
    } catch (err) {
      setError(getErrorMessage(err.code));
      throw err;
    }
  };

  const logOut = async () => {
    try {
      setError(null);
      await signOut(auth);
      clearTokenCache();
    } catch (err) {
      setError(getErrorMessage(err.code));
      throw err;
    }
  };

  const resetPassword = async (email) => {
    try {
      setError(null);
      await sendPasswordResetEmail(auth, email);
    } catch (err) {
      setError(getErrorMessage(err.code));
      throw err;
    }
  };

  const handleAccountDeletion = async (password) => {
    try {
      setError(null);
      
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        password
      );
      await reauthenticateWithCredential(auth.currentUser, credential);
      
      const token = await getIdToken();
      await fetch(`${process.env.REACT_APP_API_URL}/users/account`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      await auth.currentUser.delete();
      await signOut(auth);
      clearTokenCache();
    } catch (err) {
      setError(getErrorMessage(err.code));
      throw err;
    }
  };

  const value = {
    user,
    loading,
    error,
    signIn,
    signUp,
    logOut,
    signInWithGoogle,
    resetPassword,
    isAuthenticated: !!user,
    isEmailVerified: user?.emailVerified,
    clearError: () => setError(null),
    getIdToken,
    updateOnboardingStatus: refreshUserProfile,
    hasCompletedOnboarding: user?.hasCompletedOnboarding || false,
    refreshUserProfile,
    deleteAccount: handleAccountDeletion,
    isOnTrial: user?.subscription_status === 'trial',
    trialEndDate: user?.free_trial_end_at,
    canAccessProFeatures: user?.subscription_status === 'pro' || 
                         user?.subscription_status === 'trial' ||
                         user?.is_premium || 
                         user?.is_team
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

// Main auth hook
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

// Specialized viewer auth hook
export const useViewerAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useViewerAuth must be used within an AuthProvider');
  }

  // Return only the fields needed for viewer functionality
  return {
    loading: context.loading,
    getIdToken: context.getIdToken,
    canAccessProFeatures: context.canAccessProFeatures,
    user: context.user ? {
      uid: context.user.uid,
      subscription_status: context.user.subscription_status,
      is_premium: context.user.is_premium,
      is_team: context.user.is_team
    } : null
  };
};

export default AuthContext;