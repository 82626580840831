import React, { useState } from 'react';
import { Loader2, AlertCircle, CheckCircle2 } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const SignUpForm = ({ onSuccess, inviteId = null }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { signUp, signInWithGoogle, getIdToken } = useAuth();

  // Password validation
  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*]/.test(password);

    return {
      isValid: 
        password.length >= minLength &&
        hasUpperCase &&
        hasLowerCase &&
        hasNumbers &&
        hasSpecialChar,
      requirements: [
        { met: password.length >= minLength, text: 'At least 8 characters' },
        { met: hasUpperCase, text: 'One uppercase letter' },
        { met: hasLowerCase, text: 'One lowercase letter' },
        { met: hasNumbers, text: 'One number' },
        { met: hasSpecialChar, text: 'One special character (!@#$%^&*)' }
      ]
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    // Validate password
    const { isValid } = validatePassword(password);
    if (!isValid) {
      setError('Password does not meet requirements');
      return;
    }

    // Check terms acceptance
    if (!acceptTerms) {
      setError('Please accept the terms and conditions');
      return;
    }

    setLoading(true);

    try {
      const result = await signUp(email, password);
      
      if (inviteId) {
        try {
          const token = await getIdToken();
          const response = await fetch(`${process.env.REACT_APP_API_URL}/organizations/invites/${inviteId}/accept`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          if (!response.ok) {
            const error = await response.json();
            throw new Error(error.error || 'Failed to accept invitation');
          }

          setSuccessMessage('Account created and team invitation accepted!');
          
          setTimeout(() => {
            navigate('/team');
          }, 1500);
        } catch (err) {
          setError('Account created but failed to accept invitation. Please try again or contact support.');
          return;
        }
      }

      if (!inviteId) {
        setSuccessMessage('Account created successfully! Please check your email for verification.');
      }

      if (onSuccess) onSuccess();
    } catch (err) {
      setError('Failed to create account. Email may already be in use.');
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignUp = async () => {
    setError('');
    setSuccessMessage('');
    setLoading(true);
    
    try {
      const result = await signInWithGoogle();
      
      if (inviteId) {
        try {
          const token = await getIdToken();
          const response = await fetch(`${process.env.REACT_APP_API_URL}/organizations/invites/${inviteId}/accept`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          if (!response.ok) {
            const error = await response.json();
            throw new Error(error.error || 'Failed to accept invitation');
          }

          setSuccessMessage('Signed in with Google and team invitation accepted!');
          
          setTimeout(() => {
            navigate('/team');
          }, 1500);
        } catch (err) {
          setError('Signed in but failed to accept invitation. Please try again or contact support.');
          return;
        }
      }

      if (onSuccess) onSuccess();
    } catch (err) {
      setError('Failed to sign up with Google');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <form onSubmit={handleSubmit} className="space-y-4">
        {successMessage && (
          <div className="flex items-center gap-2 text-sm text-green-600 bg-green-50 p-3 rounded-lg">
            <CheckCircle2 className="w-4 h-4" />
            {successMessage}
          </div>
        )}

        {error && (
          <div className="flex items-center gap-2 text-sm text-red-600 bg-red-50 p-3 rounded-lg">
            <AlertCircle className="w-4 h-4" />
            {error}
          </div>
        )}

        <div>
          <label htmlFor="signup-email" className="block text-sm font-medium text-gray-700">
            Email address
          </label>
          <input
            id="signup-email"
            type="email"
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-secondary-DEFAULT focus:border-secondary-DEFAULT"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div>
          <label htmlFor="signup-password" className="block text-sm font-medium text-gray-700">
            Password
          </label>
          <input
            id="signup-password"
            type="password"
            required
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-secondary-DEFAULT focus:border-secondary-DEFAULT"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {password && (
            <ul className="mt-2 text-xs space-y-1">
              {validatePassword(password).requirements.map((req, index) => (
                <li 
                  key={index}
                  className={`flex items-center ${
                    req.met ? 'text-green-600' : 'text-gray-500'
                  }`}
                >
                  <span className="mr-1">
                    {req.met ? '✓' : '○'}
                  </span>
                  {req.text}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="flex items-center">
          <input
            id="accept-terms"
            type="checkbox"
            required
            className="h-4 w-4 text-secondary-DEFAULT focus:ring-secondary-DEFAULT border-gray-300 rounded"
            checked={acceptTerms}
            onChange={(e) => setAcceptTerms(e.target.checked)}
          />
          <label htmlFor="accept-terms" className="ml-2 block text-sm text-gray-700">
            I accept the terms and conditions
          </label>
        </div>

        <button
          type="submit"
          disabled={loading}
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white !bg-secondary-500 hover:!bg-secondary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-DEFAULT disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {loading ? (
            <Loader2 className="w-5 h-5 animate-spin" />
          ) : (
            'Sign up'
          )}
        </button>
      </form>

      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="px-2 bg-white text-gray-500">Or continue with</span>
        </div>
      </div>

      <button
        type="button"
        onClick={handleGoogleSignUp}
        disabled={loading}
        className="w-full flex justify-center items-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-DEFAULT disabled:opacity-50 disabled:cursor-not-allowed"
      >
        <img 
          src="https://www.google.com/favicon.ico" 
          alt="Google" 
          className="w-5 h-5 mr-2"
        />
        Google
      </button>
    </div>
  );
};

export default SignUpForm;