import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { 
  Users, 
  UserPlus, 
  Mail, 
  Shield, 
  UserMinus,
  Loader2,
  AlertCircle,
  Building2,
  ArrowRight
} from 'lucide-react';
import InviteMemberModal from '../components/team/InviteMemberModal';
import MemberList from '../components/team/MemberList';
import PendingInvites from '../components/team/PendingInvites';

const CreateOrganizationView = ({ onOrganizationCreated }) => {
  const { user, getIdToken } = useAuth();
  const [organizationName, setOrganizationName] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsCreating(true);
    setError(null);

    try {
      const token = await getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/organizations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ name: organizationName })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Failed to create organization');
      }

      const data = await response.json();
      onOrganizationCreated(data);
    } catch (err) {
      console.error('Error creating organization:', err);
      setError(err.message);
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto px-4 py-12">
      <div className="text-center mb-8">
        <div className="w-16 h-16 bg-secondary-100 rounded-full flex items-center justify-center mx-auto mb-4">
          <Building2 className="w-8 h-8 text-secondary-600" />
        </div>
        <h1 className="text-3xl font-bold mb-2">Welcome to Team Management</h1>
        <p className="text-gray-600 text-lg">
          Let's set up your organization to start collaborating with your team.
        </p>
      </div>

      <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow-sm p-6">
        <div className="mb-6">
          <label htmlFor="orgName" className="block text-sm font-medium text-gray-700 mb-1">
            Organization Name
          </label>
          <input
            id="orgName"
            type="text"
            value={organizationName}
            onChange={(e) => setOrganizationName(e.target.value)}
            required
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-secondary-400 focus:border-transparent"
            placeholder="Enter your organization's name"
          />
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-50 text-red-600 rounded-lg flex items-center gap-2">
            <AlertCircle className="w-5 h-5" />
            <span>{error}</span>
          </div>
        )}

        <button
          type="submit"
          disabled={isCreating || !organizationName.trim()}
          className="w-full flex items-center justify-center gap-2 px-6 py-3 bg-secondary-500 text-white rounded-lg hover:bg-secondary-600 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
        >
          {isCreating ? (
            <Loader2 className="w-5 h-5 animate-spin" />
          ) : (
            <>
              Create Organization
              <ArrowRight className="w-5 h-5" />
            </>
          )}
        </button>
      </form>

      <div className="mt-6 text-center text-gray-500 text-sm">
        This will allow you to invite team members and manage their access.
      </div>
    </div>
  );
};

const TeamView = () => {
  const { user, getIdToken } = useAuth();
  const [members, setMembers] = useState([]);
  const [invites, setInvites] = useState([]);
  const [isInviteModalOpen, setInviteModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchTeamData = async () => {
    try {
      setLoading(true);
      setError(null);

      const token = await getIdToken();
      const membersResponse = await fetch(`${process.env.REACT_APP_API_URL}/organizations/members`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!membersResponse.ok) {
        const errorData = await membersResponse.json();
        throw new Error(errorData.error || 'Failed to fetch team members');
      }
      
      const membersData = await membersResponse.json();
      setMembers(membersData);

      if (user.is_administrator) {
        const invitesResponse = await fetch(`${process.env.REACT_APP_API_URL}/organizations/invites`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        if (!invitesResponse.ok) throw new Error('Failed to fetch invites');
        const invitesData = await invitesResponse.json();
        setInvites(invitesData);
      }
    } catch (err) {
      console.error('Error fetching team data:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchTeamData();
    }
  }, [user]);

  const handleInviteSend = async (email, role) => {
    try {
      const token = await getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/organizations/invites`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ email, role })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Failed to send invite');
      }

      await fetchTeamData();
      setInviteModalOpen(false);
    } catch (err) {
      console.error('Error sending invite:', err);
      throw err;
    }
  };

  const handleRoleUpdate = async (memberId, isAdmin) => {
    try {
      const token = await getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/organizations/members/${memberId}/role`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ is_administrator: isAdmin })
      });

      if (!response.ok) throw new Error('Failed to update role');
      await fetchTeamData();
    } catch (err) {
      console.error('Error updating role:', err);
      throw err;
    }
  };

  const handleRemoveMember = async (memberId) => {
    if (!window.confirm('Are you sure you want to remove this team member?')) {
      return;
    }

    try {
      const token = await getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/organizations/members/${memberId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) throw new Error('Failed to remove member');
      await fetchTeamData();
    } catch (err) {
      console.error('Error removing member:', err);
      throw err;
    }
  };

  const handleCancelInvite = async (inviteId) => {
    try {
      const token = await getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/organizations/invites/${inviteId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) throw new Error('Failed to cancel invite');
      await fetchTeamData();
    } catch (err) {
      console.error('Error canceling invite:', err);
      throw err;
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="w-8 h-8 text-secondary-DEFAULT animate-spin" />
      </div>
    );
  }

  // Show organization creation view if user has team plan but no organization
  if (user?.is_team && error === 'User is not part of an organization') {
    return (
      <CreateOrganizationView
        onOrganizationCreated={(orgData) => {
          // After organization is created, refresh team data
          fetchTeamData();
        }}
      />
    );
  }

  if (error && error !== 'User is not part of an organization') {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="flex items-center gap-2 text-red-600">
          <AlertCircle className="w-5 h-5" />
          <span>Error: {error}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-6 max-w-5xl">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900 flex items-center gap-2">
            <Users className="w-6 h-6 text-secondary-DEFAULT" />
            Team Management
          </h1>
          <p className="text-gray-500 mt-1">
            {members.length} member{members.length !== 1 ? 's' : ''} in your organization
          </p>
        </div>
        
        {user.is_administrator && (
          <button
            onClick={() => setInviteModalOpen(true)}
            className="flex items-center gap-2 px-4 py-2 bg-secondary-500 hover:bg-secondary-600 text-white rounded-lg transition-colors duration-200"
          >
            <UserPlus className="w-4 h-4" />
            Invite Member
          </button>
        )}
      </div>

      {/* Member List */}
      <MemberList
        members={members}
        currentUser={members.find(m => m.email === user.email) || members[0]} 
        onRoleUpdate={handleRoleUpdate}
        onRemoveMember={handleRemoveMember}
      />

      {/* Pending Invites Section */}
      {user.is_administrator && (
        <PendingInvites
          invites={invites}
          onCancelInvite={handleCancelInvite}
        />
      )}

      {/* Invite Modal */}
      <InviteMemberModal
        isOpen={isInviteModalOpen}
        onClose={() => setInviteModalOpen(false)}
        onInvite={handleInviteSend}
      />
    </div>
  );
};

export default TeamView;