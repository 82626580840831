import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  LayoutDashboard,
  Bell, 
  Bookmark,
  Globe,
  Users2,
  LogOut,
  Compass,
  User,
  CreditCard,
  Settings,
  X
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import AuthModal from './auth/AuthModal';
import logo from '../assets/favicon.ico';
import useWatchlists from '../hooks/useWatchlists';

// Team badge storage key
const TEAM_BADGE_STORAGE_KEY = 'has_seen_team_badge';

// Logout Confirmation Modal Component
const LogoutConfirmationModal = ({ isOpen, onClose, onConfirm, isLoading }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Backdrop */}
      <div 
        className="absolute inset-0 bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      />
      
      {/* Modal */}
      <div className="relative bg-white rounded-lg w-full max-w-md p-6 shadow-xl">
        <div className="absolute right-4 top-4">
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500 transition-colors duration-200"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <h3 className="text-xl font-semibold mb-2">Confirm Logout</h3>
        <p className="text-gray-600 mb-6">
          Are you sure you want to log out of your account?
        </p>

        <div className="flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors duration-200"
            disabled={isLoading}
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            disabled={isLoading}
            className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors duration-200 disabled:opacity-50"
          >
            {isLoading ? 'Logging out...' : 'Logout'}
          </button>
        </div>
      </div>
    </div>
  );
};

const SidebarItem = ({ icon: Icon, text, to, active, onClick, showBadge }) => (
  <Link
    to={to}
    onClick={(e) => onClick ? onClick(e) : null}
    className={`group flex items-center px-3 py-2.5 rounded-lg transition-all duration-200 relative
      ${active 
        ? 'bg-secondary-DEFAULT text-white shadow-lg shadow-secondary-DEFAULT/20' 
        : 'text-gray-300 hover:bg-white/5 hover:text-white'
      }`}
  >
    <Icon className={`w-5 h-5 transition-transform duration-200 ${active ? 'scale-105' : 'group-hover:scale-105'}`} />
    {showBadge && (
      <span className="absolute -top-1 -right-1 w-3 h-3 bg-secondary-DEFAULT rounded-full ring-2 ring-[#2D2A3F] animate-pulse" />
    )}
  </Link>
);

const ProfileMenu = ({ isOpen, onClose, user, onLogout }) => {
  if (!isOpen) return null;

  return (
    <div 
      className="absolute bottom-full mb-2 left-0 w-48 bg-white rounded-lg shadow-lg py-1 z-50"
      onMouseLeave={onClose}
    >
      <div className="px-4 py-2 border-b border-gray-100">
        <div className="font-medium text-sm text-gray-900">{user?.fullName}</div>
        <div className="text-xs text-gray-500">{user?.email}</div>
      </div>
      
      <Link 
        to="/profile" 
        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
      >
        <User className="w-4 h-4 mr-2" />
        Account
      </Link>
      
      <Link 
        to="/settings" 
        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
      >
        <Settings className="w-4 h-4 mr-2" />
        Settings
      </Link>
      
      <Link 
        to="/plans" 
        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
      >
        <CreditCard className="w-4 h-4 mr-2" />
        Plans & Billing
      </Link>
      
      <button
        onClick={onLogout}
        className="flex items-center w-full px-4 py-2 text-sm text-red-600 hover:bg-gray-50"
      >
        <LogOut className="w-4 h-4 mr-2" />
        Log out
      </button>
    </div>
  );
};

const DefaultAvatar = () => (
  <div className="w-9 h-9 rounded-lg bg-secondary-DEFAULT/20 text-secondary-DEFAULT flex items-center justify-center hover:bg-secondary-DEFAULT hover:text-white transition-all duration-200">
    <User className="w-5 h-5" />
  </div>
);

const Sidebar = ({ onDashboardClick }) => {
  const location = useLocation();
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const { user, logOut } = useAuth();
  const { getDefaultWatchlistId } = useWatchlists();
  
  // Track if user has seen the team badge
  const [hasSeenTeamBadge, setHasSeenTeamBadge] = useState(() => {
    return localStorage.getItem(TEAM_BADGE_STORAGE_KEY) === 'true';
  });

  // Update badge visibility when user gets team access
  useEffect(() => {
    if (user?.is_team && !hasSeenTeamBadge) {
      const handleTeamClick = () => {
        setHasSeenTeamBadge(true);
        localStorage.setItem(TEAM_BADGE_STORAGE_KEY, 'true');
      };
    }
  }, [user?.is_team, hasSeenTeamBadge]);

  const menuItems = [
    { 
      icon: LayoutDashboard, 
      text: 'Dashboard', 
      path: '/',
      onClick: (e) => {
        e.preventDefault();
        onDashboardClick();
      }
    },
    { icon: Bell, text: 'Alerts', path: '/alerts' },
    { icon: Bookmark, text: 'Saved', path: '/saved' },
    { icon: Compass, text: 'Discover', path: '/discover' },
  ];
  
  const bottomItems = [
    { icon: Globe, text: 'Exchange hub', path: '/exchange-hub' },
    ...(user?.is_team ? [{
      icon: Users2,
      text: 'Team',
      path: '/team',
      showBadge: !hasSeenTeamBadge,
      onClick: () => {
        setHasSeenTeamBadge(true);
        localStorage.setItem(TEAM_BADGE_STORAGE_KEY, 'true');
      }
    }] : []),
  ];

  // Get user initials for avatar fallback
  const getInitials = (name) => {
    if (!name) return null;
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  const handleLogoutClick = () => {
    setIsProfileMenuOpen(false);
    setIsLogoutModalOpen(true);
  };

  const handleLogoutConfirm = async () => {
    setIsLoggingOut(true);
    try {
      await logOut();
      setIsLogoutModalOpen(false);
    } catch (error) {
      console.error('Error signing out:', error);
    } finally {
      setIsLoggingOut(false);
    }
  };

  return (
    <>
      <div className="w-18 bg-[#2D2A3F] text-white flex flex-col h-screen fixed left-0 top-0 z-50">
        {/* Header Section */}
        <div className="p-3.5">
          <div className="flex justify-center">
            <div className="bg-white p-0.5 rounded-lg">
              <img src={logo} alt="FilingReader" className="h-7 w-auto" />
            </div>
          </div>
        </div>

        {/* Main Navigation */}
        <nav className="flex-1 px-3.5 py-4 space-y-2">
          <div className="space-y-2 pb-4">
            {menuItems.map((item) => (
              <SidebarItem
                key={item.text}
                icon={item.icon}
                text={item.text}
                to={item.path}
                onClick={item.onClick}
                active={location.pathname === item.path}
              />
            ))}
          </div>
        </nav>

        {/* Bottom Section */}
        <div className="p-3.5 space-y-2 border-t border-white/[0.08] bg-hero-darker/20">
          {bottomItems.map((item) => (
            <SidebarItem
              key={item.text}
              icon={item.icon}
              text={item.text}
              to={item.path}
              active={location.pathname === item.path}
              onClick={item.onClick}
              showBadge={item.showBadge}
            />
          ))}
          
          {/* Profile Section */}
          <div className="relative">
            {user ? (
              <button
                onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
                className="w-full flex items-center justify-center"
              >
                {user.profileImage ? (
                  <img 
                    src={user.profileImage} 
                    alt={user.fullName}
                    className="w-9 h-9 rounded-lg object-cover border-2 border-transparent hover:border-secondary-DEFAULT transition-colors duration-200"
                  />
                ) : getInitials(user.fullName) ? (
                  <div className="w-9 h-9 rounded-lg bg-secondary-DEFAULT/20 text-secondary-DEFAULT flex items-center justify-center text-sm font-medium hover:bg-secondary-DEFAULT hover:text-white transition-all duration-200">
                    {getInitials(user.fullName)}
                  </div>
                ) : (
                  <DefaultAvatar />
                )}
              </button>
            ) : (
              <button
                onClick={() => setIsAuthModalOpen(true)}
                className="w-full flex items-center justify-center p-2 rounded-lg hover:bg-white/5 transition-colors duration-200"
              >
                <User className="w-5 h-5" />
              </button>
            )}

            <ProfileMenu
              isOpen={isProfileMenuOpen}
              onClose={() => setIsProfileMenuOpen(false)}
              user={user}
              onLogout={handleLogoutClick}
            />
          </div>
        </div>
      </div>

      {/* Auth Modal */}
      <AuthModal 
        isOpen={isAuthModalOpen} 
        onClose={() => setIsAuthModalOpen(false)} 
      />

      {/* Logout Confirmation Modal */}
      <LogoutConfirmationModal 
        isOpen={isLogoutModalOpen}
        onClose={() => setIsLogoutModalOpen(false)}
        onConfirm={handleLogoutConfirm}
        isLoading={isLoggingOut}
      />
    </>
  );
};

export default Sidebar;