// src/hooks/useTrending.js
import { useState, useEffect } from 'react';

export const useTrending = () => {
  const [trendingCompanies, setTrendingCompanies] = useState([]);
  const [trendingWatchlists, setTrendingWatchlists] = useState([]);
  const [loading, setLoading] = useState(false); // Set initial loading to false
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTrending = async () => {
      try {
        setLoading(true);
        const [companiesRes, watchlistsRes] = await Promise.all([
          fetch(`${process.env.REACT_APP_API_URL}/trending/companies`),
          fetch(`${process.env.REACT_APP_API_URL}/trending/watchlists`)
        ]);

        if (!companiesRes.ok || !watchlistsRes.ok) {
          throw new Error('Failed to fetch trending data');
        }

        const [companies, watchlists] = await Promise.all([
          companiesRes.json(),
          watchlistsRes.json()
        ]);

        setTrendingCompanies(companies.data || []);  // Ensure we set empty array if null
        setTrendingWatchlists(watchlists.data || []); // Ensure we set empty array if null
      } catch (err) {
        console.error('Error fetching trending:', err);
        setError(err.message);
        // Set empty arrays on error to avoid undefined
        setTrendingCompanies([]);
        setTrendingWatchlists([]);
      } finally {
        setLoading(false);
      }
    };

    fetchTrending();
    // Refresh every 15 minutes to match backend cache
    const interval = setInterval(fetchTrending, 15 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  return {
    trendingCompanies,
    trendingWatchlists,
    loading,
    error
  };
};