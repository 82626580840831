import { useState, useEffect, useCallback, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';

const useCompanyFilings = (stockCode, selectedKeywords = []) => {
  const { user, getIdToken } = useAuth();
  const [filings, setFilings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [offset, setOffset] = useState(0);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const lastRequestTime = useRef(0);
  const MIN_REQUEST_INTERVAL = 1000;
  const limit = 15;

  const fetchCompanyFilings = useCallback(async (requestOffset) => {
    console.log('fetchCompanyFilings called with:', {
      stockCode,
      requestOffset,
      currentOffset: offset,
      loading,
      lastRequestTime: lastRequestTime.current,
      isAuthenticated: !!user,
      hasUser: !!user,
      userEmail: user?.email,
      selectedKeywords
    });
  
    if (!stockCode) {
      console.log('Fetch aborted: No stockCode');
      return;
    }
  
    // Only apply rate limiting for scroll-triggered loads (when offset > 0)
    const now = Date.now();
    if (requestOffset > limit && now - lastRequestTime.current < MIN_REQUEST_INTERVAL) {
      console.log('Fetch aborted: Too soon since last request');
      return;
    }
    lastRequestTime.current = now;
  
    setLoading(true);
    setError(null);
    
    try {
      const headers = {
        'Content-Type': 'application/json'
      };
      
      if (user) {
        try {
          const token = await getIdToken();
          console.log('Got auth token:', !!token, token?.substring(0, 10) + '...');
          if (token) {
            headers['Authorization'] = `Bearer ${token}`;
          }
        } catch (error) {
          console.warn('Failed to get auth token:', error);
        }
      }

      // Build the URL parameters
      const params = new URLSearchParams({
        offset: requestOffset.toString(),
        limit: limit.toString()
      });

      // Add selected keywords to the parameters
      if (selectedKeywords && selectedKeywords.length > 0) {
        selectedKeywords.forEach(keyword => {
          params.append('keywords[]', keyword);
        });
      }

      // Manually encode the stockCode to prevent double encoding
      const url = `${process.env.REACT_APP_API_URL}/filings/company?stockCode=${encodeURIComponent(stockCode)}&${params.toString()}`;
      console.log('Fetching URL:', url, 'with headers:', Object.keys(headers));
  
      const response = await fetch(url, { headers });
      console.log('Response status:', response.status);
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Received data:', data);

      // Set hasMore based on the number of items received
      if (!data.data || data.data.length < limit) {
        setHasMore(false);
      }
  
      if (data.data?.length > 0) {
        setFilings(prev => {
          const newState = requestOffset === 0 ? data.data : [...prev, ...data.data];
          console.log('Updating filings:', {
            previousCount: prev.length,
            newCount: newState.length,
            wasReset: requestOffset === 0
          });
          return newState;
        });
  
        setOffset(requestOffset + limit);
      } else if (requestOffset === 0) {
        // If it's the first fetch and we got no data
        setFilings([]);
        setHasMore(false);
      }

      if (data.companyInfo && requestOffset === 0) {
        setCompanyInfo(data.companyInfo);
      }
    } catch (error) {
      console.error('Error fetching company filings:', error);
      setError(error.message);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  }, [stockCode, user, getIdToken, selectedKeywords]);

  // Handle initial load and reset when stockCode or selectedKeywords change
  useEffect(() => {
    let mounted = true;
    
    console.log('stockCode or selectedKeywords changed:', { stockCode, selectedKeywords });
    if (stockCode) {
      console.log('Resetting state for new stockCode or keywords');
      setFilings([]);
      setOffset(0);
      setError(null);
      setHasMore(true);
      if (mounted) {
        fetchCompanyFilings(0);
      }
    }

    return () => {
      mounted = false;
    };
  }, [stockCode, selectedKeywords, fetchCompanyFilings]);

  // Handle load more
  const loadMore = useCallback(() => {
    if (!loading && hasMore) {
      fetchCompanyFilings(offset);
    }
  }, [fetchCompanyFilings, offset, loading, hasMore]);

  return {
    filings,
    loading,
    error,
    companyInfo,
    loadMore,
    hasMore
  };
};

export default useCompanyFilings;