import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ChevronLeft, Loader2, Download } from 'lucide-react';
import { formatDateTime } from '../utils/formatters';

const FilingDetail = () => {
  const { filingId } = useParams();
  const navigate = useNavigate();
  const [filing, setFiling] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFiling = async () => {
      if (!filingId) return;

      setLoading(true);
      setError(null);
      
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/filings/${encodeURIComponent(filingId)}`
        );
        
        if (!response.ok) {
          const data = await response.json();
          throw new Error(data.error || 'Failed to fetch filing details');
        }

        const data = await response.json();
        
        if (!data.documentUrl) {
          throw new Error('No document URL available');
        }
        
        setFiling(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFiling();
  }, [filingId]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[200px]">
        <Loader2 className="w-6 h-6 animate-spin text-secondary-DEFAULT" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 p-4 rounded-lg">
        <p className="text-red-600">Error loading filing: {error}</p>
        <button
          onClick={() => navigate(-1)}
          className="mt-2 text-red-700 hover:text-red-800"
        >
          Go back
        </button>
      </div>
    );
  }

  if (!filing) return null;

  const handleDownload = () => {
    if (!filing.documentUrl) {
      setError('No document URL available');
      return;
    }
    window.open(filing.documentUrl, '_blank');
  };

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-start justify-between">
        <div>
          <button
            onClick={() => navigate(-1)}
            className="flex items-center text-light-text-secondary hover:text-secondary-DEFAULT"
          >
            <ChevronLeft className="w-4 h-4 mr-1" />
            Back to Filings
          </button>
          <h1 className="mt-2 text-2xl font-semibold text-light-text">
            {filing.longNameEn || 'Company Name Not Available'}
          </h1>
          <div className="mt-1 flex items-center space-x-4 text-sm text-light-text-secondary">
            <span>{filing.stockCodes}</span>
            <span>•</span>
            <span>{formatDateTime(filing.timeStamps)}</span>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={handleDownload}
            className="flex items-center space-x-2 px-4 py-2 bg-secondary-DEFAULT text-light-bg rounded-lg hover:bg-secondary-accent disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={!filing.documentUrl}
          >
            <Download className="w-4 h-4" />
            <span>Download PDF</span>
          </button>
        </div>
      </div>

      {/* Content */}
      <div className="prose max-w-none">
        <h2 className="text-lg font-semibold text-light-text">Filing Details</h2>
        <dl className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
          <div>
            <dt className="text-sm font-medium text-light-text-secondary">Filing Title</dt>
            <dd className="mt-1 text-light-text">{filing.titlesTranslated || filing.titles || 'Title Not Available'}</dd>
          </div>
          <div>
            <dt className="text-sm font-medium text-light-text-secondary">Pages</dt>
            <dd className="mt-1 text-light-text">{filing.pageCount || 'N/A'}</dd>
          </div>
          <div>
            <dt className="text-sm font-medium text-light-text-secondary">Exchange</dt>
            <dd className="mt-1 text-light-text">{filing.exchange || 'N/A'}</dd>
          </div>
        </dl>
      </div>

      {/* PDF Viewer */}
      {filing.documentUrl && (
        <div className="mt-6 border border-gray-200 rounded-lg min-h-[600px] bg-light-bg-secondary">
          <object
            data={filing.documentUrl}
            type="application/pdf"
            className="w-full h-[600px]"
          >
            <p>
              Unable to display PDF file. <a href={filing.documentUrl} target="_blank" rel="noopener noreferrer">Download</a> instead.
            </p>
          </object>
        </div>
      )}
    </div>
  );
};

export default FilingDetail;