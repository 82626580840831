import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Trash2, Copy, ArrowRight, Rss } from 'lucide-react';
import WatchlistActionMenu from './WatchlistActionMenu';
import { useAuth } from '../../contexts/AuthContext';

const WatchlistStocksTable = ({ 
  stocks = [], 
  watchlistId,
  allWatchlists = [],
  onRemoveStock,
  onUpdateNotifications,
  onUpdateBatchNotifications,
  onMoveStocks,
  onCopyStocks,
  onDeleteStocks,
  isFeedsPanelVisible = false,
  isFollowed = false 
}) => {
  const { getIdToken } = useAuth();
  const [pendingUpdates, setPendingUpdates] = useState(new Set());
  const [selectedStocks, setSelectedStocks] = useState(new Set());
  const [actionMenuOpen, setActionMenuOpen] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [menuPosition, setMenuPosition] = useState(null);
  const [loadingRss, setLoadingRss] = useState({});
  const [rssIds, setRssIds] = useState({});

  const EMAIL_ALERTS_GRID = "grid grid-cols-4 gap-4";
  const EMAIL_ALERT_CELL = "flex items-center relative overflow-visible whitespace-nowrap min-w-[100px]";
  const checkboxStyle = "w-4 h-4 min-w-[16px] min-h-[16px] flex-shrink-0 appearance-none rounded border border-gray-300 bg-white checked:bg-secondary-accent checked:border-secondary-accent focus:outline-none focus:ring-2 focus:ring-secondary-accent focus:ring-offset-2 transition-all duration-200 ease-in-out cursor-pointer";

  const getColumnStyles = useCallback((isFeedsPanelVisible) => {
    const baseStyles = {
      stockCode: { 
        width: '180px', 
        minWidth: '180px',
        padding: '0.5rem 1rem',
        textAlign: 'left',
        overflow: 'visible',
        whiteSpace: 'nowrap'
      },
      companyName: { 
        width: '30%', 
        minWidth: '200px',
        padding: '0.5rem 1rem',
        textAlign: 'left',
        overflow: 'visible',
        whiteSpace: 'normal'
      },
      feed: { 
        width: '200px',
        minWidth: '200px',
        padding: '0.5rem 1rem',
        display: isFeedsPanelVisible ? 'none' : 'table-cell',
        textAlign: 'left',
        overflow: 'visible',
        whiteSpace: 'normal'
      },
      emailAlerts: { 
        width: '45%', 
        minWidth: '400px',
        padding: '0.5rem 1rem',
        overflow: 'visible'
      },
      rss: { 
        width: '50px', 
        minWidth: '50px',
        padding: '0.5rem 1rem',
        textAlign: 'center',
        overflow: 'visible',
        whiteSpace: 'nowrap'
      }
    };

    if (window.innerWidth < 1536 && isFeedsPanelVisible) {
      baseStyles.feed.display = 'none';
    } else if (window.innerWidth < 1280 && !isFeedsPanelVisible) {
      baseStyles.feed.display = 'none';
    }

    return baseStyles;
  }, []);

  const [columnStyles, setColumnStyles] = useState(() => getColumnStyles(isFeedsPanelVisible));

  // Fetch existing RSS links when component mounts
  useEffect(() => {
    const fetchExistingRssLinks = async () => {
      if (!stocks.length) return;
      
      try {
        const token = await getIdToken();
        const stockCodes = stocks.map(s => s.stockCode);
        
        const response = await fetch(`${process.env.REACT_APP_API_URL}/rss/stock/existing`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ stockCodes })
        });

        if (!response.ok) {
          throw new Error('Failed to fetch existing RSS links');
        }

        const data = await response.json();
        setRssIds(data.rssLinks);
      } catch (error) {
        console.error('Error fetching RSS links:', error);
      }
    };

    fetchExistingRssLinks();
  }, [stocks, getIdToken]);

  const handleRssClick = async (stockCode) => {
    if (loadingRss[stockCode]) return;
    
    // If we already have an RSS ID, use it directly
    if (rssIds[stockCode]) {
      window.open(`https://feed.filingreader.com/rss/${rssIds[stockCode]}`, '_blank');
      return;
    }
    
    try {
      setLoadingRss(prev => ({ ...prev, [stockCode]: true }));
      
      const token = await getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/rss/stock`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ stockCode })
      });

      if (!response.ok) {
        throw new Error('Failed to generate RSS link');
      }

      const { rss_id } = await response.json();
      
      // Store the new RSS ID
      setRssIds(prev => ({
        ...prev,
        [stockCode]: rss_id
      }));
      
      // Open RSS feed in new tab
      window.open(`https://feed.filingreader.com/rss/${rss_id}`, '_blank');

    } catch (error) {
      console.error('Error generating RSS link:', error);
    } finally {
      setLoadingRss(prev => ({ ...prev, [stockCode]: false }));
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setColumnStyles(getColumnStyles(isFeedsPanelVisible));
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isFeedsPanelVisible, getColumnStyles]);

  useEffect(() => {
    setPendingUpdates(new Set());
    setSelectedStocks(new Set());
  }, [stocks]);

  useEffect(() => {
    setColumnStyles(getColumnStyles(isFeedsPanelVisible));
  }, [isFeedsPanelVisible, getColumnStyles]);

  const handleActionClick = (action, event) => {
    event.preventDefault();
    const buttonRect = event.currentTarget.getBoundingClientRect();
    setMenuPosition({ x: buttonRect.left, y: buttonRect.top });
    setActionType(action);
    setActionMenuOpen(true);
  };

  const handleWatchlistSelect = async (targetWatchlistId) => {
    const selectedStockCodes = Array.from(selectedStocks);
    
    try {
      if (actionType === 'move') {
        // For move: first add to target watchlist, then remove from current watchlist
        for (const stockCode of selectedStockCodes) {
          const stock = stocks.find(s => s.stockCode === stockCode);
          await onMoveStocks(stockCode, targetWatchlistId, stock.notifications);
          await onRemoveStock(stockCode); // Remove from current watchlist
        }
      } else if (actionType === 'copy') {
        // Copy stocks to the target watchlist
        for (const stockCode of selectedStockCodes) {
          const stock = stocks.find(s => s.stockCode === stockCode);
          await onCopyStocks(stockCode, targetWatchlistId, stock.notifications);
        }
      }
      
      setActionMenuOpen(false);
      setSelectedStocks(new Set());
    } catch (error) {
      console.error('Error performing action:', error);
    }
  };

  const handleDeleteStocks = async () => {
    try {
      const selectedStockCodes = Array.from(selectedStocks);
      await onDeleteStocks(selectedStockCodes);
      setSelectedStocks(new Set());
    } catch (error) {
      console.error('Error deleting stocks:', error);
    }
  };

  const handleNotificationChange = async (stockCode, type, currentValue) => {
    const stock = stocks.find(s => s.stockCode === stockCode);
    if (stock) {
      const updateKey = `${stockCode}-${type}`;
      setPendingUpdates(prev => new Set([...prev, updateKey]));
      
      const updatedNotifications = {
        ...stock.notifications,
        [type]: currentValue ? 0 : 1
      };
      
      try {
        await onUpdateNotifications(stockCode, updatedNotifications);
        setTimeout(() => {
          setPendingUpdates(prev => {
            const newSet = new Set(prev);
            newSet.delete(updateKey);
            return newSet;
          });
        }, 500);
      } catch (error) {
        console.error('Error updating notifications:', error);
        setPendingUpdates(prev => {
          const newSet = new Set(prev);
          newSet.delete(updateKey);
          return newSet;
        });
      }
    }
  };

  const handleBatchNotificationChange = async (type, currentState) => {
    const stocksToUpdate = selectedStocks.size > 0 
      ? stocks.filter(s => selectedStocks.has(s.stockCode))
      : stocks;
    
    const stockCodes = stocksToUpdate.map(s => s.stockCode);
    
    try {
      await onUpdateBatchNotifications(
        watchlistId,
        `${type}_enabled`,
        !currentState.someEnabled,
        stockCodes
      );
    } catch (error) {
      console.error('Error updating batch notifications:', error);
    }
  };

  const handleSelectAll = () => {
    if (selectedStocks.size === stocks.length) {
      setSelectedStocks(new Set());
    } else {
      setSelectedStocks(new Set(stocks.map(s => s.stockCode)));
    }
  };

  const handleRowSelect = (stockCode) => {
    setSelectedStocks(prev => {
      const newSet = new Set(prev);
      if (newSet.has(stockCode)) {
        newSet.delete(stockCode);
      } else {
        newSet.add(stockCode);
      }
      return newSet;
    });
  };

  const getNotificationValue = useCallback((stock, type) => {
    const updateKey = `${stock.stockCode}-${type}`;
    const isPending = pendingUpdates.has(updateKey);
    return isPending ? 
      (stock.notifications[type] === 1 ? 0 : 1) : 
      stock.notifications[type];
  }, [pendingUpdates]);

  const getExchangeFromStockCode = (stockCode) => {
    return stockCode.split(':')[0];
  };

  if (!stocks.length) {
    return (
      <div className="p-8 text-center">
        <div className="text-gray-400 mb-4">No stocks in watchlist</div>
        <p className="text-sm text-gray-500">
          Get started by adding a company to your watchlist.
        </p>
      </div>
    );
  }

  const allNotificationStates = {
    realtime: {
      someEnabled: stocks.some(s => s?.notifications?.realtime_enabled === 1) || false,
      allEnabled: stocks.every(s => s?.notifications?.realtime_enabled === 1) || false
    },
    daily: {
      someEnabled: stocks.some(s => s?.notifications?.daily_enabled === 1) || false,
      allEnabled: stocks.every(s => s?.notifications?.daily_enabled === 1) || false
    },
    weekly: {
      someEnabled: stocks.some(s => s?.notifications?.weekly_enabled === 1) || false,
      allEnabled: stocks.every(s => s?.notifications?.weekly_enabled === 1) || false
    },
    smart_scan: {
      someEnabled: stocks.some(s => s?.notifications?.smart_scan_enabled === 1) || false,
      allEnabled: stocks.every(s => s?.notifications?.smart_scan_enabled === 1) || false
    }
  };
  
  return (
    <div className="space-y-4">
      <div className="w-full overflow-x-hidden">
        <table className="w-full" style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th style={columnStyles.stockCode}>
              <span className="text-xs font-medium text-gray-500 uppercase">Stock Code</span>
            </th>
            <th style={columnStyles.companyName}>
              <span className="text-xs font-medium text-gray-500 uppercase">Company Name</span>
            </th>
            <th style={columnStyles.feed}>
              <span className="text-xs font-medium text-gray-500 uppercase">Feed</span>
            </th>
            <th style={columnStyles.emailAlerts} colSpan="4">
              <span className="text-xs font-medium text-gray-500 uppercase">Email Alerts</span>
            </th>
            <th style={columnStyles.rss}>
              <span className="text-xs font-medium text-gray-500 uppercase">RSS</span>
            </th>
          </tr>
          <tr>
          <th style={columnStyles.stockCode}>
                <div className="flex items-center space-x-4 relative overflow-visible">
                  {!isFollowed && (
                    <>
                      <input
                        type="checkbox"
                        checked={selectedStocks.size === stocks.length}
                        onChange={handleSelectAll}
                        className={checkboxStyle}
                      />
                      {selectedStocks.size > 0 && (
                        <>
                          <button
                            onClick={(e) => handleActionClick('move', e)}
                            className="flex items-center text-sm text-gray-600 hover:text-secondary-accent"
                          >
                            <ArrowRight className="w-4 h-4 mr-1" />
                            Move
                          </button>

                    <button
                      onClick={(e) => handleActionClick('copy', e)}
                      className="flex items-center text-sm text-gray-600 hover:text-secondary-accent"
                    >
                      <Copy className="w-4 h-4 mr-1" />
                      Copy
                    </button>
                      <button
                        onClick={handleDeleteStocks}
                        className="flex items-center text-sm text-red-600 hover:text-red-700"
                      >
                        <Trash2 className="w-4 h-4 mr-1" />
                        Delete
                      </button>
                      </>
                      )}
                    </>
                  )}
                </div>
              </th>
            <th style={columnStyles.companyName}></th>
            <th style={columnStyles.feed}></th>
            <th style={columnStyles.emailAlerts} colSpan="4">
              <div className={EMAIL_ALERTS_GRID}>
                <div className={EMAIL_ALERT_CELL}>
                  <input
                    type="checkbox"
                    checked={allNotificationStates.realtime?.someEnabled ?? false}
                    className={`${checkboxStyle} ${
                      allNotificationStates.realtime?.someEnabled && 
                      !allNotificationStates.realtime?.allEnabled ? 
                      'bg-indigo-300' : ''
                    }`}
                    onChange={() => handleBatchNotificationChange(
                      'realtime',
                      allNotificationStates.realtime || { someEnabled: false, allEnabled: false }
                    )}
                  />
                  <span className="ml-2 text-xs font-medium text-gray-500 uppercase whitespace-nowrap">
                    Realtime
                  </span>
                </div>
                <div className={EMAIL_ALERT_CELL}>
                  <input
                    type="checkbox"
                    checked={allNotificationStates.daily?.someEnabled ?? false}
                    className={`${checkboxStyle} ${
                      allNotificationStates.daily?.someEnabled && 
                      !allNotificationStates.daily?.allEnabled ? 
                      'bg-indigo-300' : ''
                    }`}
                    onChange={() => handleBatchNotificationChange(
                      'daily',
                      allNotificationStates.daily || { someEnabled: false, allEnabled: false }
                    )}
                  />
                  <span className="ml-2 text-xs font-medium text-gray-500 uppercase whitespace-nowrap">
                    Daily
                  </span>
                </div>
                <div className={EMAIL_ALERT_CELL}>
                  <input
                    type="checkbox"
                    checked={allNotificationStates.weekly?.someEnabled ?? false}
                    className={`${checkboxStyle} ${
                      allNotificationStates.weekly?.someEnabled && 
                      !allNotificationStates.weekly?.allEnabled ? 
                      'bg-indigo-300' : ''
                    }`}
                    onChange={() => handleBatchNotificationChange(
                      'weekly',
                      allNotificationStates.weekly || { someEnabled: false, allEnabled: false }
                    )}
                  />
                  <span className="ml-2 text-xs font-medium text-gray-500 uppercase whitespace-nowrap">
                    Weekly
                  </span>
                </div>
                <div className={EMAIL_ALERT_CELL}>
                  <input
                    type="checkbox"
                    checked={allNotificationStates.smart_scan?.someEnabled ?? false}
                    className={`${checkboxStyle} ${
                      allNotificationStates.smart_scan?.someEnabled && 
                      !allNotificationStates.smart_scan?.allEnabled ? 
                      'bg-indigo-300' : ''
                    }`}
                    onChange={() => handleBatchNotificationChange(
                      'smart_scan',
                      allNotificationStates.smart_scan || { someEnabled: false, allEnabled: false }
                    )}
                  />
                  <span className="ml-2 text-xs font-medium text-gray-500 uppercase whitespace-nowrap">
                    Smart Scan
                  </span>
                </div>
              </div>
            </th>
            <th style={columnStyles.rss}></th>
          </tr>
        </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {stocks.map((stock) => (
              <tr key={stock.stockCode} className="hover:bg-gray-50 group">
                <td style={columnStyles.stockCode}>
                  <div className="flex items-center">
                    <div className="w-4 mr-4">
                      <input
                        type="checkbox"
                        checked={selectedStocks.has(stock.stockCode)}
                        onChange={() => handleRowSelect(stock.stockCode)}
                        className={`${selectedStocks.has(stock.stockCode) ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'} ${checkboxStyle}`}
                      />
                    </div>
                    <div className="flex items-center">
                      <img 
                        src={stock.flagUrl} 
                        alt={getExchangeFromStockCode(stock.stockCode)} 
                        className="w-4 h-4 mr-2"
                      />
                      <span className="text-sm font-medium text-gray-900">
                        {stock.stockCode}
                      </span>
                    </div>
                  </div>
                </td>
                <td style={columnStyles.companyName}>
                  <Link 
                    to={`/company/${encodeURIComponent(stock.stockCode)}`}
                    className="text-sm font-medium text-gray-900 hover:text-secondary-accent"
                  >
                    {stock.name}
                  </Link>
                </td>
                <td style={columnStyles.feed}>
                  <span className="text-sm text-gray-500">
                    {stock.otherWatchlists?.length > 0 
                      ? stock.otherWatchlists
                          .filter(w => w.id !== watchlistId)
                          .map(w => w.name)
                          .join(', ')
                      : '-'
                    }
                  </span>
                </td>
                <td style={columnStyles.emailAlerts} colSpan="4">
                  <div className={EMAIL_ALERTS_GRID}>
                    <div className={EMAIL_ALERT_CELL}>
                      <input
                        type="checkbox"
                        checked={getNotificationValue(stock, 'realtime_enabled')}
                        className={checkboxStyle}
                        onChange={() => handleNotificationChange(stock.stockCode, 'realtime_enabled', getNotificationValue(stock, 'realtime_enabled'))}
                      />
                    </div>
                    <div className={EMAIL_ALERT_CELL}>
                      <input
                        type="checkbox"
                        checked={getNotificationValue(stock, 'daily_enabled')}
                        className={checkboxStyle}
                        onChange={() => handleNotificationChange(stock.stockCode, 'daily_enabled', getNotificationValue(stock, 'daily_enabled'))}
                      />
                    </div>
                    <div className={EMAIL_ALERT_CELL}>
                      <input
                        type="checkbox"
                        checked={getNotificationValue(stock, 'weekly_enabled')}
                        className={checkboxStyle}
                        onChange={() => handleNotificationChange(stock.stockCode, 'weekly_enabled', getNotificationValue(stock, 'weekly_enabled'))}
                      />
                    </div>
                    <div className={EMAIL_ALERT_CELL}>
                      <input
                        type="checkbox"
                        checked={getNotificationValue(stock, 'smart_scan_enabled')}
                        className={checkboxStyle}
                        onChange={() => handleNotificationChange(stock.stockCode, 'smart_scan_enabled', getNotificationValue(stock, 'smart_scan_enabled'))}
                      />
                    </div>
                  </div>
                </td>
                <td style={columnStyles.rss} className="text-center">
                  <Rss 
                    className={`w-4 h-4 ${
                      loadingRss[stock.stockCode] 
                        ? 'text-gray-300' 
                        : rssIds[stock.stockCode]
                          ? 'text-secondary-accent cursor-pointer'
                          : 'text-gray-400 hover:text-secondary-accent cursor-pointer'
                    } inline-block`}
                    onClick={() => handleRssClick(stock.stockCode)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {actionMenuOpen && (
          <WatchlistActionMenu
            isOpen={actionMenuOpen}
            onClose={() => setActionMenuOpen(false)}
            position={menuPosition}
            watchlists={allWatchlists}
            currentWatchlistId={watchlistId}
            onSelectWatchlist={handleWatchlistSelect}
            actionType={actionType}
          />
        )}
      </div>
    </div>
  );
};

export default WatchlistStocksTable;