import React, { useState, useRef, useEffect } from 'react';
import { Search, X, Users, Building2 } from 'lucide-react';
import * as LucideIcons from 'lucide-react'; // Import all icons
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { useAuth } from '../contexts/AuthContext';
import useWatchlists from '../hooks/useWatchlists';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

const CompanySearchBar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [companyResults, setCompanyResults] = useState([]);
  const [watchlistResults, setWatchlistResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const searchRef = useRef(null);
  const navigate = useNavigate();
  const { isAuthenticated, getIdToken } = useAuth();
  const { watchlists } = useWatchlists();

  // Handle clicks outside search component to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Debounced search function for both companies and watchlists
  const searchAll = debounce(async (query) => {
    if (query.length < 2) {
      setCompanyResults([]);
      setWatchlistResults([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    try {
      // Search companies
      const companyResponse = await fetch(
        `${API_URL}/search?query=${encodeURIComponent(query)}`,
        {
          headers: {
            'Accept': 'application/json',
            'Origin': window.location.origin
          }
        }
      );
            
      // Search watchlists
      const watchlistResponse = await fetch(
        `${API_URL}/search/watchlists?query=${encodeURIComponent(query)}`,
        {
          headers: {
            'Accept': 'application/json',
            'Origin': window.location.origin,
            ...(isAuthenticated && { 'Authorization': `Bearer ${await getIdToken()}` })
          }
        }
      );
      
      if (!companyResponse.ok || !watchlistResponse.ok) {
        throw new Error(`Search request failed`);
      }
      
      const [companies, watchlists] = await Promise.all([
        companyResponse.json(),
        watchlistResponse.json()
      ]);

      setCompanyResults(companies);
      setWatchlistResults(watchlists);
      setIsDropdownOpen(true);
    } catch (error) {
      console.error('Search error:', error);
      setCompanyResults([]);
      setWatchlistResults([]);
    } finally {
      setIsLoading(false);
    }
  }, 300);

  const isCompanyInWatchlists = (stockCode) => {
    return watchlists.some(watchlist => 
      watchlist.stocks?.some(stock => stock.stockCode === stockCode)
    );
  };

  // Dynamic icon component function
  const DynamicIcon = ({ name, ...props }) => {
    const IconComponent = LucideIcons[name] || Building2;
    return <IconComponent {...props} />;
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    if (value) {
      setIsDropdownOpen(true);
      searchAll(value);
    } else {
      setIsDropdownOpen(false);
      setCompanyResults([]);
      setWatchlistResults([]);
    }
  };

  const handleCompanyClick = (company) => {
    navigate(`/company/${company.stockCodes}`);
    setSearchTerm('');
    setIsDropdownOpen(false);
  };

  const handleWatchlistClick = (watchlist) => {
    navigate(`/feeds/${watchlist.id}`);
    setSearchTerm('');
    setIsDropdownOpen(false);
  };

  const handleClear = () => {
    setSearchTerm('');
    setCompanyResults([]);
    setWatchlistResults([]);
    setIsDropdownOpen(false);
  };

  return (
    <div ref={searchRef} className="relative max-w-2xl w-full">
      <div className="relative">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search companies and watchlists..."
          className="w-full h-10 pl-10 pr-10 border border-gray-200 rounded-lg 
                    focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:border-transparent
                    bg-white text-light-text"
        />
        <Search className="w-5 h-5 text-light-text-tertiary absolute left-3 top-1/2 -translate-y-1/2" />
        {searchTerm && (
          <button
            type="button"
            onClick={handleClear}
            className="absolute right-3 top-1/2 -translate-y-1/2 p-1 hover:bg-light-bg-secondary rounded-full"
          >
            <X className="w-4 h-4 text-light-text-tertiary" />
          </button>
        )}
        {isLoading && (
          <div className="absolute right-12 top-1/2 transform -translate-y-1/2">
            <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-secondary-DEFAULT"></div>
          </div>
        )}
      </div>

      {/* Results Dropdown */}
      {isDropdownOpen && (watchlistResults.length > 0 || companyResults.length > 0) && (
        <ul className="absolute z-[100] w-full bg-white mt-1 rounded-lg shadow-lg
                     max-h-96 overflow-y-auto border border-gray-200 divide-y divide-gray-100
                     [scrollbar-width:thin]
                     [scrollbar-color:#E5E7EB_transparent]
                     [&::-webkit-scrollbar]:w-1.5
                     [&::-webkit-scrollbar-track]:bg-transparent
                     [&::-webkit-scrollbar-thumb]:bg-gray-200
                     [&::-webkit-scrollbar-thumb]:rounded-full
                     [&::-webkit-scrollbar-thumb]:border-4
                     [&::-webkit-scrollbar-thumb]:border-solid
                     [&::-webkit-scrollbar-thumb]:border-transparent
                     hover:[&::-webkit-scrollbar-thumb]:bg-gray-300"
                     style={{ backgroundColor: 'white' }}>
          {/* Watchlist Results Section */}
          {watchlistResults.length > 0 && (
            <li className="px-3 py-2">
              <div className="flex items-center space-x-2 mb-2">
                <Users className="w-4 h-4 text-gray-400" />
                <span className="text-sm font-medium text-gray-500">Watchlists</span>
              </div>
              <ul className="space-y-1">
                {watchlistResults.map((watchlist) => (
                  <li
                    key={watchlist.id}
                    className="px-3 py-2 hover:bg-gray-50 cursor-pointer rounded-md transition-all duration-200"
                    onClick={() => handleWatchlistClick(watchlist)}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-3">
                        <DynamicIcon 
                          name={watchlist.icon_name || 'Building2'} 
                          className="w-4 h-4 text-gray-400" 
                        />
                        <div className="flex items-center">
                          <span className="text-gray-900 text-sm font-medium">
                            {watchlist.name}
                          </span>
                          {watchlist.is_subscribed ? (
                            <span className="ml-2 px-2 py-0.5 text-xs bg-secondary-50 text-secondary-700 rounded-full">
                              Following
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <span className="text-gray-500 text-xs">
                        {watchlist.stock_count} stocks
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </li>
          )}
          {/* Company Results Section */}
          {companyResults.length > 0 && (
            <li className="px-3 py-2">
              <div className="flex items-center space-x-2 mb-2">
                <Building2 className="w-4 h-4 text-gray-400" />
                <span className="text-sm font-medium text-gray-500">Companies</span>
              </div>
              <ul className="space-y-1">
                {companyResults.map((result) => (
                  <li
                    key={result.ID}
                    className="px-3 py-2 hover:bg-gray-50 cursor-pointer rounded-md transition-all duration-200"
                    onClick={() => handleCompanyClick(result)}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-2">
                        <div className="w-6 h-6 flex-shrink-0 flex items-center justify-center">
                          {result.flag_url ? (
                            <img src={result.flag_url} alt="Country flag" className="w-4 h-4" />
                          ) : (
                            <div className="w-4 h-4 bg-secondary-DEFAULT rounded-full flex items-center justify-center">
                              <span className="text-white font-medium text-xs">
                                {result.longNameEn.charAt(0)}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="flex flex-col min-w-0">
                          <div className="flex items-center space-x-2">
                            <span className="text-gray-900 text-sm font-medium truncate">
                              {result.longNameEn}
                            </span>
                            <span className="text-gray-500 text-xs">
                              ({result.stockCodes})
                            </span>
                          </div>
                        </div>
                      </div>
                      {isCompanyInWatchlists(result.stockCodes) && (
                        <span className="px-2 py-0.5 text-xs bg-secondary-50 text-secondary-700 rounded-full whitespace-nowrap">
                          Following
                        </span>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default CompanySearchBar;