import { useState, useEffect, useCallback, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useUnread } from '../contexts/UnreadContext';

const useWatchlists = () => {
  const [watchlists, setWatchlists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(Date.now());
  const { getIdToken, isAuthenticated, user } = useAuth();
  const { updateUnreadCount } = useUnread();
  
  const fetchWatchlistsRef = useRef(null);

  fetchWatchlistsRef.current = async () => {
    if (!isAuthenticated) {
      setLoading(false);
      return;
    }

    try {
      let token = null;
      for (let i = 0; i < 3; i++) {
        try {
          token = await getIdToken();
          break;
        } catch (e) {
          if (i === 2) throw e;
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }
      
      if (!token) {
        throw new Error('Failed to get authentication token');
      }
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}/watchlists`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      const processedData = data.map(watchlist => ({
        ...watchlist,
        id: watchlist.id.toString()
      }));
      
      processedData.forEach(watchlist => {
        updateUnreadCount(watchlist.id, watchlist.unread_count || 0);
      });
      
      setWatchlists(processedData);
      setError(null);
      setLastUpdate(Date.now());
    } catch (err) {
      console.error('Error in fetchWatchlists:', err);
      setError(err.message);
      if (err.message.includes('authentication') || err.message.includes('401')) {
        setWatchlists([]);
      }
    } finally {
      setLoading(false);
    }
  };

  const createWatchlist = useCallback(async (watchlistData) => {
    if (!isAuthenticated) {
      throw new Error('Must be authenticated to create watchlist');
    }

    try {
      let token = null;
      for (let i = 0; i < 3; i++) {
        try {
          token = await getIdToken();
          break;
        } catch (e) {
          if (i === 2) throw e;
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }

      const { stocks, ...restData } = watchlistData;
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}/watchlists`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...restData,
          stocks: stocks || []
        })
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }
  
      const newWatchlist = await response.json();
      updateUnreadCount(newWatchlist.id.toString(), 0);
      
      setWatchlists(prev => [...prev, {
        ...newWatchlist,
        id: newWatchlist.id.toString()
      }]);
      
      await fetchWatchlistsRef.current();
      return newWatchlist;
    } catch (err) {
      console.error('Error creating watchlist:', err);
      throw err;
    }
  }, [isAuthenticated, getIdToken, updateUnreadCount]);

  const deleteWatchlist = useCallback(async (watchlistId) => {
    if (!isAuthenticated) {
      throw new Error('Must be authenticated to delete watchlist');
    }

    try {
      let token = null;
      for (let i = 0; i < 3; i++) {
        try {
          token = await getIdToken();
          break;
        } catch (e) {
          if (i === 2) throw e;
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/watchlists/${watchlistId}`,
        {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }

      updateUnreadCount(watchlistId, 0);
      setWatchlists(prev => prev.filter(w => w.id !== watchlistId));
      setLastUpdate(Date.now());
    } catch (err) {
      console.error('Error deleting watchlist:', err);
      throw err;
    }
  }, [isAuthenticated, getIdToken, updateUnreadCount]);

  const updateWatchlist = useCallback(async (watchlistId, updateData) => {
    if (!isAuthenticated) {
      throw new Error('Must be authenticated to update watchlist');
    }

    try {
      let token = null;
      for (let i = 0; i < 3; i++) {
        try {
          token = await getIdToken();
          break;
        } catch (e) {
          if (i === 2) throw e;
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/watchlists/${watchlistId}`,
        {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(updateData)
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }

      const updatedWatchlist = await response.json();
      
      if ('unread_count' in updatedWatchlist) {
        updateUnreadCount(watchlistId, updatedWatchlist.unread_count);
      }
      
      setWatchlists(prev => 
        prev.map(w => w.id === watchlistId ? {
          ...updatedWatchlist,
          id: updatedWatchlist.id.toString()
        } : w)
      );
      setLastUpdate(Date.now());
      return updatedWatchlist;
    } catch (err) {
      console.error('Error updating watchlist:', err);
      throw err;
    }
  }, [isAuthenticated, getIdToken, updateUnreadCount]);

  const addStockToWatchlist = useCallback(async (watchlistId, stockCode) => {
    if (!isAuthenticated) {
      throw new Error('Must be authenticated to add stock to watchlist');
    }

    try {
      let token = null;
      for (let i = 0; i < 3; i++) {
        try {
          token = await getIdToken();
          break;
        } catch (e) {
          if (i === 2) throw e;
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/watchlists/${watchlistId}/stocks`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ stockCode })
        }
      );
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }
  
      await fetchWatchlistsRef.current();
      return await response.json();
    } catch (err) {
      console.error('Error adding stock to watchlist:', err);
      throw err;
    }
  }, [isAuthenticated, getIdToken]);

  const subscribeToWatchlist = useCallback(async (watchlistId) => {
    if (!isAuthenticated) {
      throw new Error('Must be authenticated to subscribe to watchlist');
    }

    try {
      let token = null;
      for (let i = 0; i < 3; i++) {
        try {
          token = await getIdToken();
          break;
        } catch (e) {
          if (i === 2) throw e;
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user/subscriptions/${watchlistId}`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }

      await fetchWatchlistsRef.current();
    } catch (err) {
      console.error('Error subscribing to watchlist:', err);
      throw err;
    }
  }, [isAuthenticated, getIdToken]);

  const unsubscribeFromWatchlist = useCallback(async (watchlistId) => {
    if (!isAuthenticated) {
      throw new Error('Must be authenticated to unsubscribe from watchlist');
    }

    try {
      let token = null;
      for (let i = 0; i < 3; i++) {
        try {
          token = await getIdToken();
          break;
        } catch (e) {
          if (i === 2) throw e;
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user/subscriptions/${watchlistId}`,
        {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }

      updateUnreadCount(watchlistId, 0);
      await fetchWatchlistsRef.current();
    } catch (err) {
      console.error('Error unsubscribing from watchlist:', err);
      throw err;
    }
  }, [isAuthenticated, getIdToken, updateUnreadCount]);

  const updateStockNotifications = useCallback(async (watchlistId, stockCode, notifications) => {
    if (!isAuthenticated) {
      throw new Error('Must be authenticated to update stock notifications');
    }

    try {
      let token = null;
      for (let i = 0; i < 3; i++) {
        try {
          token = await getIdToken();
          break;
        } catch (e) {
          if (i === 2) throw e;
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/watchlists/${watchlistId}/stock-notification`,
        {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ stockCode, notifications })
        }
      );
    
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }
    
      setWatchlists(prev => prev.map(w => {
        if (w.id === watchlistId) {
          return {
            ...w,
            stocks: w.stocks.map(stock => {
              if (stock.stockCode === stockCode) {
                return {
                  ...stock,
                  notifications
                };
              }
              return stock;
            })
          };
        }
        return w;
      }));
    
      return await response.json();
    } catch (err) {
      console.error('Error updating stock notifications:', err);
      throw err;
    }
  }, [isAuthenticated, getIdToken]);

  const updateBatchNotifications = useCallback(async (watchlistId, notificationType, enabled, stockCodes) => {
    if (!isAuthenticated) {
      throw new Error('Must be authenticated to update batch notifications');
    }

    try {
      let token = null;
      for (let i = 0; i < 3; i++) {
        try {
          token = await getIdToken();
          break;
        } catch (e) {
          if (i === 2) throw e;
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/watchlists/${watchlistId}/batch-notifications`,
        {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ notificationType, enabled, stockCodes })
        }
      );
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }
  
      setWatchlists(prev => prev.map(w => {
        if (w.id === watchlistId) {
          return {
            ...w,
            stocks: w.stocks.map(stock => ({
              ...stock,
              notifications: {
                ...stock.notifications,
                [notificationType]: enabled ? 1 : 0
              }
            }))
          };
        }
        return w;
      }));
  
      await fetchWatchlistsRef.current();
      return await response.json();
    } catch (err) {
      console.error('Error updating batch notifications:', err);
      throw err;
    }
  }, [isAuthenticated, getIdToken]);

  const removeStockFromWatchlist = useCallback(async (watchlistId, stockCode) => {
    if (!isAuthenticated) {
      throw new Error('Must be authenticated to remove stock from watchlist');
    }

    try {
      let token = null;
      for (let i = 0; i < 3; i++) {
        try {
          token = await getIdToken();
          break;
        } catch (e) {
          if (i === 2) throw e;
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/watchlists/${watchlistId}/stocks/${stockCode}`,
        {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }

      setWatchlists(prev => prev.map(w => {
        if (w.id === watchlistId) {
          return {
            ...w,
            stocks: w.stocks.filter(s => s.stockCode !== stockCode)
          };
        }
        return w;
      }));
      
      return await response.json();
    } catch (err) {
      console.error('Error removing stock from watchlist:', err);
      throw err;
    }
  }, [isAuthenticated, getIdToken]);

  const getDefaultWatchlistId = useCallback(() => {
    if (!watchlists || watchlists.length === 0) return null;
    
    const lastAccessedId = localStorage.getItem('lastAccessedWatchlistId');
    if (lastAccessedId && watchlists.some(w => w.id === lastAccessedId)) {
      return lastAccessedId;
    }
    
    return watchlists[0].id;
  }, [watchlists]);

  const setLastAccessedWatchlist = useCallback((id) => {
    localStorage.setItem('lastAccessedWatchlistId', id);
  }, []);

  useEffect(() => {
    let mounted = true;

    const initializeWatchlists = async () => {
      if (!mounted) return;
      
      if (isAuthenticated) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        if (mounted) {
          await fetchWatchlistsRef.current();
        }
      } else {
        setLoading(false);
        setWatchlists([]);
      }
    };

    initializeWatchlists();

    return () => {
      mounted = false;
    };
  }, [isAuthenticated]);

  return {
    watchlists,
    loading,
    error,
    createWatchlist,
    deleteWatchlist,
    updateWatchlist,
    addStockToWatchlist,
    removeStockFromWatchlist,
    subscribeToWatchlist,
    unsubscribeFromWatchlist,
    updateStockNotifications,
    updateBatchNotifications, 
    refreshWatchlists: fetchWatchlistsRef.current,
    getDefaultWatchlistId,
    setLastAccessedWatchlist,
    lastUpdate
  };
};

export default useWatchlists;