// src/views/PlansView.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { createCheckoutSession, createBillingPortalSession, getSubscriptionStatus } from '../utils/stripe';

const PlansView = () => {
  const [isYearly, setIsYearly] = useState(false);
  const [loadingPlan, setLoadingPlan] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [searchParams] = useSearchParams();
  const { user, refreshUserProfile, isOnTrial, trialEndDate } = useAuth(); 
  const navigate = useNavigate();

  useEffect(() => {
    loadSubscriptionStatus();
    
    // Check for subscription status in URL
    const status = searchParams.get('subscription');
    if (status === 'success') {
      // Show success message
      alert('Subscription successful! Welcome to FilingReader Premium.');
    } else if (status === 'canceled') {
      // Show canceled message
      alert("Subscription canceled. Feel free to try again when you're ready.");
    }
  }, [searchParams, refreshUserProfile]);

  const loadSubscriptionStatus = async () => {
    try {
      const status = await getSubscriptionStatus();
      setSubscriptionStatus(status);
    } catch (error) {
      console.error('Error loading subscription status:', error);
    }
  };

  const handleSubscribe = async (planType) => {
    setLoadingPlan(planType);
    try {
      const successUrl = `${window.location.origin}/plans?subscription=success`;
      const cancelUrl = `${window.location.origin}/plans?subscription=canceled`;
      
      await createCheckoutSession(
        planType,
        isYearly ? 'yearly' : 'monthly',
        successUrl,
        cancelUrl
      );
    } catch (error) {
      console.error('Subscription error:', error);
      alert('Failed to initiate subscription. Please try again.');
      setLoadingPlan(null);
    }
  };

  const TrialBanner = () => {
    if (!isOnTrial) return null;
    
    const daysLeft = Math.ceil((new Date(trialEndDate) - new Date()) / (1000 * 60 * 60 * 24));
    
    return (
      <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 mb-8">
        <p className="text-blue-700">
          You're currently on a free trial! {daysLeft} days remaining.
          Upgrade now to continue accessing premium features after your trial ends.
        </p>
      </div>
    );
  };

  const handleManageSubscription = async () => {
    try {
      await createBillingPortalSession(`${window.location.origin}/plans`);
    } catch (error) {
      console.error('Error opening billing portal:', error);
      alert('Failed to open billing portal. Please try again.');
    }
  };

  const plans = [
    {
      name: 'Free',
      priceMonthly: 0,
      priceYearly: 0,
      features: [
        'Basic filing search',
        'Limited filing downloads',
        'Basic analytics',
        'Single user'
      ],
      isCurrentPlan: !subscriptionStatus || subscriptionStatus.status === 'none',
      action: null
    },
    {
      name: 'Pro',
      priceMonthly: 15,
      priceYearly: 168,
      features: [
        'Advanced filing search',
        'Unlimited filing downloads',
        'Advanced analytics',
        'Custom alerts',
        'Priority email support',
        'Single user'
      ],
      isCurrentPlan: subscriptionStatus?.status === 'active' && subscriptionStatus?.plan_type === 'pro',
      action: () => handleSubscribe('pro')
    },
    {
      name: 'Team',
      priceMonthly: 24,
      priceYearly: 264,
      features: [
        'Everything in Pro',
        'Team collaboration',
        '5 team members',
        'Team analytics',
        'Admin controls',
        'Priority support'
      ],
      isCurrentPlan: subscriptionStatus?.status === 'active' && subscriptionStatus?.plan_type === 'team',
      action: () => handleSubscribe('team')
    }
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
            <TrialBanner />
      <div className="sm:flex sm:flex-col sm:align-center">
        <h1 className="text-5xl font-bold text-gray-900 sm:text-center">Pricing Plans</h1>
        <p className="mt-5 text-xl text-gray-500 sm:text-center">
          Choose the perfect plan for your filing research needs
        </p>
        
        {/* Billing interval toggle */}
        <div className="relative self-center mt-6 rounded-lg p-0.5 flex sm:mt-8 bg-gray-200">
          <button
            type="button"
            className={`relative rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none px-8 ${
              !isYearly 
                ? 'bg-white border-gray-200 text-gray-900 shadow-sm' 
                : 'border border-transparent text-gray-700'
            }`}
            onClick={() => setIsYearly(false)}
          >
            Monthly
          </button>
          <button
            type="button"
            className={`relative rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none px-8 ${
              isYearly 
                ? 'bg-white border-gray-200 text-gray-900 shadow-sm' 
                : 'border border-transparent text-gray-700'
            }`}
            onClick={() => setIsYearly(true)}
          >
            Yearly
            <span className="absolute -top-3 -right-3 bg-green-500 text-white text-xs px-2 py-0.5 rounded-full">
              Save ~15%
            </span>
          </button>
        </div>
      </div>

      <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0">
        {plans.map((plan) => (
          <div
            key={plan.name}
            className={`rounded-lg shadow-sm divide-y divide-gray-200 ${
              plan.isCurrentPlan ? 'border-2 border-blue-500' : 'border border-gray-200'
            }`}
          >
            <div className="p-6">
              <h2 className="text-2xl font-semibold text-gray-900">{plan.name}</h2>
              <p className="mt-4">
                <span className="text-4xl font-extrabold text-gray-900">
                  ${isYearly ? plan.priceYearly : plan.priceMonthly}
                </span>
                <span className="text-base font-medium text-gray-500">
                  {plan.priceMonthly === 0 ? '' : isYearly ? '/year' : '/month'}
                </span>
              </p>
              
              {isYearly && plan.priceMonthly > 0 && (
                <p className="text-sm text-gray-500 mt-1">
                  ${plan.priceMonthly}/month when billed monthly
                </p>
              )}

              <ul className="mt-6 space-y-4">
                {plan.features.map((feature) => (
                  <li key={feature} className="flex">
                    <svg
                      className="flex-shrink-0 w-6 h-6 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span className="ml-3 text-base text-gray-500">{feature}</span>
                  </li>
                ))}
              </ul>

              {plan.isCurrentPlan ? (
                plan.priceMonthly > 0 ? (
                  <button
                    type="button"
                    className="mt-8 block w-full py-3 px-6 border border-transparent text-center font-medium rounded-md text-blue-700 bg-blue-50 hover:bg-blue-100 cursor-pointer"
                    onClick={handleManageSubscription}
                  >
                    Manage subscription
                  </button>
                ) : (
                  <button
                    type="button"
                    className="mt-8 block w-full py-3 px-6 border border-transparent text-center font-medium rounded-md text-gray-500 bg-gray-50 cursor-default"
                    disabled
                  >
                    Current Plan
                  </button>
                )
              ) : plan.action ? (
                <button
                  type="button"
                  className="mt-8 block w-full py-3 px-6 border border-transparent text-center font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
                  onClick={plan.action}
                  disabled={loadingPlan === plan.name.toLowerCase()}
                >
                  {loadingPlan === plan.name.toLowerCase() ? 'Processing...' : `Upgrade to ${plan.name}`}
                </button>
              ) : null}
            </div>
          </div>
        ))}
      </div>

      <div className="mt-16 max-w-2xl mx-auto">
        <h2 className="text-3xl font-bold text-gray-900 text-center mb-8">
          Frequently asked questions
        </h2>
        <dl className="space-y-8">
          {[
            {
              question: "What payment methods do you accept?",
              answer: "We accept all major credit cards including Visa, Mastercard, and American Express."
            },
            {
              question: "Can I switch between plans?",
              answer: "Yes, you can upgrade or downgrade your plan at any time. When upgrading, the new price will be prorated for the remainder of your billing period."
            },
            {
              question: "How does the team plan work?",
              answer: "The team plan includes 5 seats, meaning you can have up to 5 team members accessing FilingReader with advanced features. Each member gets their own login and personalized dashboard."
            },
            {
              question: "Can I cancel my subscription?",
              answer: "Yes, you can cancel your subscription at any time from your account settings. You'll continue to have access until the end of your current billing period."
            }
          ].map((faq) => (
            <div key={faq.question} className="space-y-2">
              <dt className="text-lg font-semibold text-gray-900">{faq.question}</dt>
              <dd className="text-base text-gray-500">{faq.answer}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default PlansView;