// src/utils/stripe/stripe-utils.js
import { loadStripe } from '@stripe/stripe-js';
import { auth } from '../../config/firebase-init';

let stripePromise = null;

const getStripePromise = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  }
  return stripePromise;
};

export const PLANS = {
  PRO: {
    MONTHLY: {
      name: 'Pro Monthly',
      priceId: process.env.REACT_APP_STRIPE_PRO_MONTHLY_PRICE_ID,
      type: 'pro',
      interval: 'monthly'
    },
    YEARLY: {
      name: 'Pro Yearly',
      priceId: process.env.REACT_APP_STRIPE_PRO_YEARLY_PRICE_ID,
      type: 'pro',
      interval: 'yearly'
    }
  },
  TEAM: {
    MONTHLY: {
      name: 'Team Monthly',
      priceId: process.env.REACT_APP_STRIPE_TEAM_MONTHLY_PRICE_ID,
      type: 'team',
      interval: 'monthly'
    },
    YEARLY: {
      name: 'Team Yearly',
      priceId: process.env.REACT_APP_STRIPE_TEAM_YEARLY_PRICE_ID,
      type: 'team',
      interval: 'yearly'
    }
  }
};

// Request deduplication
const pendingRequests = new Map();

const dedupeRequest = async (key, makeRequest) => {
  if (pendingRequests.has(key)) {
    return pendingRequests.get(key);
  }

  const request = makeRequest();
  pendingRequests.set(key, request);

  try {
    const response = await request;
    pendingRequests.delete(key);
    return response;
  } catch (error) {
    pendingRequests.delete(key);
    throw error;
  }
};

async function getIdToken() {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('No authenticated user');
  }
  return await user.getIdToken();
}

export const createCheckoutSession = async (planType, interval, successUrl, cancelUrl) => {
  const stripe = await getStripePromise();
  
  return dedupeRequest('create-checkout', async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/stripe/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await getIdToken()}`
        },
        body: JSON.stringify({
          planType,
          interval,
          successUrl,
          cancelUrl
        })
      });

      if (!response.ok) {
        throw new Error('Failed to create checkout session');
      }

      const session = await response.json();
      
      const result = await stripe.redirectToCheckout({
        sessionId: session.id
      });

      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error('Checkout error:', error);
      throw error;
    }
  });
};

export const createBillingPortalSession = async (returnUrl) => {
  return dedupeRequest('create-portal', async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/stripe/create-portal-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await getIdToken()}`
        },
        body: JSON.stringify({ returnUrl })
      });

      if (!response.ok) {
        throw new Error('Failed to create billing portal session');
      }

      const session = await response.json();
      window.location.href = session.url;
    } catch (error) {
      console.error('Billing portal error:', error);
      throw error;
    }
  });
};

export const getSubscriptionStatus = async () => {
  return dedupeRequest('subscription-status', async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/stripe/subscription-status`, {
        headers: {
          'Authorization': `Bearer ${await getIdToken()}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch subscription status');
      }

      return await response.json();
    } catch (error) {
      console.error('Subscription status error:', error);
      throw error;
    }
  });
};