import './config/firebase-init';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { FilingProvider } from './contexts/FilingContext';
import { UnreadProvider } from './contexts/UnreadContext';
import './index.css';
import AppContainer from './AppContainer';
import FilingViewer from './views/FilingViewer';
import InviteAcceptView from './views/InviteAcceptView';

const Root = () => {
  const location = useLocation();
  const isStandaloneViewer = location.pathname.includes('/filing/') && 
                            location.pathname.includes('/view');
  const isInvitePage = location.pathname.startsWith('/invite/');

  // Standalone viewer routes
  if (isStandaloneViewer) {
    return (
      <Routes>
        <Route path="/filing/:filingId/view" element={<FilingViewer />} />
      </Routes>
    );
  }

  // Invite acceptance page
  if (isInvitePage) {
    return (
      <Routes>
        <Route path="/invite/:id" element={<InviteAcceptView />} />
      </Routes>
    );
  }

  // Main app with providers
  return (
    <FilingProvider>
      <UnreadProvider>
        <AppContainer />
      </UnreadProvider>
    </FilingProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Root />
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);