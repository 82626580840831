import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import FilingsTable from '../components/FilingsTable';
import NewsTable from '../components/NewsTable';
import ReportsTable from '../components/ReportsTable';
import FilterBar from '../components/filters/FilterBar';
import useLatestFilings from '../hooks/useLatestFilings';
import useLatestNews from '../hooks/useLatestNews';
import useReports from '../hooks/useReports';

const DashboardView = () => {
  const location = useLocation();
  
  // Tab state
  const [activeTab, setActiveTab] = useState(() => {
    const stored = localStorage.getItem('dashboardActiveTab');
    return stored || 'filings';
  });

  // Initialize from localStorage if available
  const [selectedExchanges, setSelectedExchanges] = useState(() => {
    const stored = localStorage.getItem('selectedExchanges');
    if (stored) {
      try {
        return JSON.parse(stored);
      } catch (e) {
        return [];
      }
    }
    return [];
  });

  // Initialize selectedKeywords from location state if available
  const [selectedKeywords, setSelectedKeywords] = useState(() => {
    if (location.state?.selectedKeywords) {
      // Save to localStorage and return the keywords
      localStorage.setItem('selectedKeywords', JSON.stringify(location.state.selectedKeywords));
      return location.state.selectedKeywords;
    }
    const stored = localStorage.getItem('selectedKeywords');
    if (stored) {
      try {
        return JSON.parse(stored);
      } catch (e) {
        return [];
      }
    }
    return [];
  });

  const [selectedFilingTypes, setSelectedFilingTypes] = useState(() => {
    const stored = localStorage.getItem('selectedFilingTypes');
    if (stored) {
      try {
        return JSON.parse(stored);
      } catch (e) {
        return [];
      }
    }
    return [];
  });

  // Initialize read items from localStorage
  const [readItems, setReadItems] = useState(() => {
    const stored = localStorage.getItem('readItems');
    if (stored) {
      try {
        return JSON.parse(stored);
      } catch (e) {
        return {};
      }
    }
    return {};
  });

  const {
    filings,
    loading: filingsLoading,
    error: filingsError,
    loadMore: loadMoreFilings,
    hasMore: hasMoreFilings
  } = useLatestFilings(selectedExchanges, selectedKeywords);

  const {
    news,
    loading: newsLoading,
    error: newsError,
    loadMore: loadMoreNews,
    hasMore: hasMoreNews
  } = useLatestNews(selectedExchanges);

  const {
    reports,
    loading: reportsLoading,
    error: reportsError,
    loadMore: loadMoreReports,
    hasMore: hasMoreReports
  } = useReports(selectedExchanges);

  // Add read status to filings
  const filingsWithReadStatus = filings.map(filing => ({
    ...filing,
    isRead: readItems[`filing-${filing.bulletinIds}`] || false
  }));

  // Add read status to news
  const newsWithReadStatus = news.map(story => ({
    ...story,
    isRead: readItems[`news-${story.id}`] || false
  }));

  useEffect(() => {
    if (location.state?.selectedKeywords) {
      setSelectedKeywords(location.state.selectedKeywords);
      localStorage.setItem('selectedKeywords', JSON.stringify(location.state.selectedKeywords));
      // Clear the location state after using it
      window.history.replaceState({}, document.title);
    }
  }, [location.state]);

  const handleFilingClick = (filingId) => {
    const newReadItems = {
      ...readItems,
      [`filing-${filingId}`]: true
    };
    setReadItems(newReadItems);
    localStorage.setItem('readItems', JSON.stringify(newReadItems));
  };

  const handleNewsClick = (newsId) => {
    const newReadItems = {
      ...readItems,
      [`news-${newsId}`]: true
    };
    setReadItems(newReadItems);
    localStorage.setItem('readItems', JSON.stringify(newReadItems));
  };

  const handleExchangeChange = (newSelectedExchanges) => {
    console.log('Exchange selection changed:', newSelectedExchanges);
    setSelectedExchanges(newSelectedExchanges);
    localStorage.setItem('selectedExchanges', JSON.stringify(newSelectedExchanges));
  };

  const handleKeywordChange = (newSelectedKeywords) => {
    setSelectedKeywords(newSelectedKeywords);
    localStorage.setItem('selectedKeywords', JSON.stringify(newSelectedKeywords));
  };

  const handleFilingTypeChange = (newSelectedFilingTypes) => {
    setSelectedFilingTypes(newSelectedFilingTypes);
    localStorage.setItem('selectedFilingTypes', JSON.stringify(newSelectedFilingTypes));
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    localStorage.setItem('dashboardActiveTab', tab);
  };

  return (
    <div className="flex flex-col h-full w-full">
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8 px-4" aria-label="Dashboard tabs">
          <button
            onClick={() => handleTabChange('filings')}
            className={`
              border-b-2 py-4 px-1 text-sm font-medium ${
                activeTab === 'filings'
                  ? 'border-secondary-DEFAULT text-secondary-DEFAULT'
                  : 'border-transparent text-light-text-secondary hover:border-gray-300 hover:text-light-text-DEFAULT'
              }
            `}
          >
            Filings
          </button>
          <button
            onClick={() => handleTabChange('reports')}
            className={`
              border-b-2 py-4 px-1 text-sm font-medium ${
                activeTab === 'reports'
                  ? 'border-secondary-DEFAULT text-secondary-DEFAULT'
                  : 'border-transparent text-light-text-secondary hover:border-gray-300 hover:text-light-text-DEFAULT'
              }
            `}
          >
            Reports
          </button>
          <button
            onClick={() => handleTabChange('news')}
            className={`
              border-b-2 py-4 px-1 text-sm font-medium ${
                activeTab === 'news'
                  ? 'border-secondary-DEFAULT text-secondary-DEFAULT'
                  : 'border-transparent text-light-text-secondary hover:border-gray-300 hover:text-light-text-DEFAULT'
              }
            `}
          >
            News
          </button>
        </nav>
      </div>

      <div className="py-4">
        <FilterBar 
          selectedExchanges={selectedExchanges}
          onExchangeChange={setSelectedExchanges}
          selectedKeywords={selectedKeywords}
          onKeywordChange={handleKeywordChange}
          selectedFilingTypes={selectedFilingTypes}
          onFilingTypeChange={handleFilingTypeChange}
        />
      </div>
      
      <div className="flex-1">
        {activeTab === 'filings' ? (
          <FilingsTable 
            filings={filingsWithReadStatus}
            loading={filingsLoading}
            error={filingsError}
            onLoadMore={loadMoreFilings}
            showCompanyInfo={true}
            hasMore={hasMoreFilings}
            onFilingClick={handleFilingClick}
            selectedKeywords={selectedKeywords}
          />
        ) : activeTab === 'reports' ? (
          <ReportsTable
            reports={reports}
            loading={reportsLoading}
            error={reportsError}
            onLoadMore={loadMoreReports}
            hasMore={hasMoreReports}
          />
        ) : (
          <NewsTable 
            news={newsWithReadStatus}
            loading={newsLoading}
            error={newsError}
            onLoadMore={loadMoreNews}
            showCompanyInfo={true}
            hasMore={hasMoreNews}
            onNewsClick={handleNewsClick}
          />
        )}
      </div>
    </div>
  );
};

export default DashboardView;