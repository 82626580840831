// NewsTable.js
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Loader2, ExternalLink } from 'lucide-react';
import { formatDateTime } from '../utils/formatters';
import NewsStoryModal from './news/NewsStoryModal';

const categoryColors = {
  earnings: 'bg-blue-100 text-blue-800',
  deals: 'bg-purple-100 text-purple-800',
  regulatory: 'bg-red-100 text-red-800',
  management: 'bg-green-100 text-green-800',
  operations: 'bg-yellow-100 text-yellow-800',
  markets: 'bg-indigo-100 text-indigo-800'
};

const importanceColors = {
  1: 'bg-gray-100',
  2: 'bg-blue-50',
  3: 'bg-yellow-50'
};

const NewsTable = ({ 
  news, 
  loading, 
  error, 
  onLoadMore, 
  showCompanyInfo = true,
  hasMore,
  onNewsClick
}) => {
  const [hoveredRow, setHoveredRow] = useState(null);
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [selectedStory, setSelectedStory] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const observerRef = useRef(null);
  const targetRef = useRef(null);

  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }
  
    if (onLoadMore && targetRef.current && hasMore) {
      observerRef.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && !loading) {
            onLoadMore();
          }
        },
        { 
          root: null,
          rootMargin: '100px',
          threshold: 0
        }
      );
  
      observerRef.current.observe(targetRef.current);
    }
  
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [onLoadMore, loading, hasMore]);

  const toggleRowExpansion = (index) => {
    const newExpandedRows = new Set(expandedRows);
    if (expandedRows.has(index)) {
      newExpandedRows.delete(index);
    } else {
      newExpandedRows.add(index);
    }
    setExpandedRows(newExpandedRows);
  };

  if (error) {
    return (
      <div className="bg-red-50 p-4 rounded-lg">
        <div className="flex flex-col items-center text-center">
          <p className="text-red-600 mb-2">Error loading news: {error}</p>
          <button
            onClick={onLoadMore}
            className="px-4 py-2 bg-red-100 text-red-700 rounded-lg hover:bg-red-200 transition-colors"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-4">
      <div className="bg-white rounded-lg shadow-sm overflow-hidden w-full min-w-0">
        <div className="w-full">
          <table className="w-full table-fixed">
            <thead>
              <tr className="bg-white border-b border-gray-200">
                {showCompanyInfo && (
                  <>
                    <th className="px-2 py-1.5 text-left text-xs font-medium text-light-text-secondary uppercase tracking-wider w-28">
                      Stock Code
                    </th>
                    <th className="px-2 py-1.5 text-left text-xs font-medium text-light-text-secondary uppercase tracking-wider w-40 sm:w-44 md:w-48 lg:w-52 xl:w-56">
                      Company
                    </th>
                  </>
                )}
                <th className="px-2 py-1.5 text-left text-xs font-medium text-light-text-secondary uppercase tracking-wider flex-1">
                  Story
                </th>
                <th className="px-2 py-1.5 text-center text-xs font-medium text-light-text-secondary uppercase tracking-wider w-24">
                  Category
                </th>
                <th className="px-2 py-1.5 text-left text-xs font-medium text-light-text-secondary uppercase tracking-wider w-40">
                  Published
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100">
              {news.length === 0 && !loading ? (
                <tr>
                  <td colSpan={showCompanyInfo ? 5 : 3} className="px-2 py-4 text-center text-light-text-tertiary bg-white">
                    No news found
                  </td>
                </tr>
              ) : (
                news.map((story, index) => {
                  const isHovered = hoveredRow === index;
                  const isExpanded = expandedRows.has(index);
                  const baseColor = importanceColors[story.importance] || '';
                  
                  return (
                    <tr 
                      key={story.id}
                      className={`
                        transition-colors group
                        ${story.isRead ? 'bg-gray-50 text-gray-500' : `hover:bg-gray-50 ${baseColor}`}
                      `}
                      onMouseEnter={() => setHoveredRow(index)}
                      onMouseLeave={() => setHoveredRow(null)}
                    >
                      {showCompanyInfo && (
                        <>
                          <td className="px-2 py-1.5 whitespace-nowrap">
                            <span className={`
                              inline-flex text-xs font-mono px-1.5 py-0.5 rounded
                              ${story.isRead ? 'bg-gray-100 text-gray-500' : 'bg-light-bg-tertiary text-light-text-DEFAULT'}
                            `}>
                              {story.stock_code}
                            </span>
                          </td>
                          <td className="px-2 py-1.5">
                            <div className={isExpanded ? '' : 'truncate'}>
                              <Link 
                                to={`/company/${encodeURIComponent(story.stock_code)}`}
                                className={`
                                  text-sm font-medium hover:text-secondary-accent
                                  ${story.isRead ? 'text-gray-500' : 'text-light-text-DEFAULT'}
                                `}
                              >
                                {story.company_name}
                              </Link>
                            </div>
                          </td>
                        </>
                      )}
                      <td className="px-2 py-1.5">
                        <div className={`${isExpanded ? '' : 'truncate'}`}>
                          <a 
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedStory(story);
                              setIsModalOpen(true);
                              onNewsClick && onNewsClick(story.id);
                            }}
                            className={`
                              text-sm font-medium flex items-center hover:text-secondary-accent cursor-pointer
                              ${story.isRead ? 'text-gray-500' : 'text-light-text-DEFAULT'}
                            `}
                          >
                            {story.title}
                            <ExternalLink className="w-3 h-3 ml-1 inline opacity-50" />
                          </a>
                          {isExpanded && (
                            <div className={`
                              mt-2 text-sm
                              ${story.isRead ? 'text-gray-400' : 'text-light-text-secondary'}
                            `}>
                              {story.summary}
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="px-2 py-1.5 text-center">
                        <span className={`
                          inline-flex items-center px-2 py-0.5 rounded text-xs font-medium
                          ${story.isRead 
                            ? 'bg-gray-100 text-gray-500' 
                            : (categoryColors[story.category] || 'bg-gray-100 text-gray-800')}
                        `}>
                          {story.category}
                        </span>
                      </td>
                      <td className="px-2 py-1.5 whitespace-nowrap">
                        <span className={`
                          text-sm flex items-center gap-1
                          ${story.isRead ? 'text-gray-400' : 'text-light-text-tertiary'}
                        `}>
                          {formatDateTime(story.created_at)}
                          {story.read_time && (
                            <span className={`
                              text-xs px-1.5 py-0.5 rounded
                              ${story.isRead ? 'bg-gray-100 text-gray-500' : 'bg-gray-100'}
                            `}>
                              {story.read_time}
                            </span>
                          )}
                        </span>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>

      {(news.length > 0 || loading) && (
        <div 
          ref={targetRef}
          className="h-16 flex items-center justify-center"
        >
          {loading ? (
            <Loader2 className="w-6 h-6 animate-spin text-secondary-DEFAULT" />
          ) : hasMore ? (
            null
          ) : (
            <span className="text-sm text-light-text-tertiary">
              No more items to load
            </span>
          )}
        </div>
      )}

      {selectedStory && (
        <NewsStoryModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedStory(null);
          }}
          exchange={selectedStory.exchange_code.toLowerCase()}
          date={selectedStory.story_date.split('T')[0]}
          slug={selectedStory.slug}
        />
      )}
    </div>
  );
};

export default NewsTable;